import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import OfficerEntry from "../components/ET/OfficerEntry";
export default function ET_Page(){



    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center">

                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Dashboard"></Tab>
                    <Tab eventKey="CaseEntry" title="Officer Entry"><OfficerEntry/></Tab>


                </Tabs>
            </div>
        </Fragment>
    )
}