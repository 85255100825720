import {Fragment} from "react";

import Login from "../components/Login/Login";

export default function LoginPage(){


    return(
        <Fragment>
            <Login/>

        </Fragment>
    );
}