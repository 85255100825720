import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";

export default function CaseReportInternal(){

    const[BtnMamlaReport,setBtnMamlaReport]=useState("primary")
    let state=useSelector((state)=> state);
    const ref=useRef();
    const value1Ref=useRef(false);
    const value2Ref=useRef(false);
    const [Data, setData]=useState([]);
    const [FoundMsg, setFoundMsg]=useState(0);
    const [Column, setColumn]=useState([{}]);
    const [CompValue1,setCompValue1]=useState(false)
    const [CompValue2,setCompValue2]=useState(false)

    const [DataUpdate, setDataUpdate]=useState();
    const [BinName, setBinName]=useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setBinName(row["Name"])

            RestClient.PostRequest(AppUrl.getData,{"sql":"Select Mamla_Update from Mamla_Update where Mamla_No='"+row['Mamla_No']+"' "}).then(result=>{
                if(result==""){cogoToast.error("No Data found")}else{

                    setDataUpdate(result[0]["Mamla_Update"])
                    handleShow()}

            }).catch(error=>{ cogoToast.error(error)});


        }
    }
    const getData=()=>{
        setBtnMamlaReport("secondary")

        const MamlaValueIndex=document.getElementById("cmbMamlaValueCompared").selectedIndex;
        const MamlaValue1=document.getElementById("txtMamlaValue1").value;
        const MamlaValue2=document.getElementById("txtMamlaValue2").value;



        const MamlaSector=document.getElementById("cmbMamlaSector");
        const MamlaSectorValue=MamlaSector.options[MamlaSector.selectedIndex].text;

        const MamlaBinName=document.getElementById("cmbMamlaBinName");
        const MamlaBinNameValue=MamlaBinName.options[MamlaBinName.selectedIndex].text;

        const MamlaReportCircle=document.getElementById("cmbMamlaReportCircle");
        const MamlaCircleValue=MamlaReportCircle.options[MamlaReportCircle.selectedIndex].text;

        const MamlaTypeReport=document.getElementById("cmbMamlaTypeReport");
        const MamlaTypeReportValue=MamlaTypeReport.options[MamlaTypeReport.selectedIndex].text;

        const CourtNameIndex=document.getElementById("cmbCourtName");
        const CourtName=CourtNameIndex.options[CourtNameIndex.selectedIndex].text;

        const MamlaYearIndex=document.getElementById("cmbMamlaYear");
        const MamlaYear=MamlaYearIndex.options[MamlaYearIndex.selectedIndex].text;

        const ReportStatus=document.getElementById("cmbReportStatus");
        const ReportStatusValue=ReportStatus.options[ReportStatus.selectedIndex].text;

        let getCourtName="";
        let getMamlaYear="";
        let getMamlaType="";
        let getMamlaBin="";
        let getMamlaCircle="";
        let getMamlaSector="";
        let getStatus="";
        let getMamlaValue="";


       if(CourtNameIndex.selectedIndex !=0){getCourtName=" And Court='"+CourtName+"'"}
       if(MamlaYearIndex.selectedIndex !=0){getMamlaYear=" And Mamla_Year='"+MamlaYear+"'"}
       if(MamlaTypeReport.selectedIndex !=0){getMamlaType=" And Mamla_Type='"+MamlaTypeReportValue+"'"}

       if(MamlaBinName.selectedIndex !=0){getMamlaBin=" And Name='"+MamlaBinNameValue+"'"}
       if(MamlaReportCircle.selectedIndex !=0){getMamlaCircle=" And Circle='"+MamlaCircleValue+"'"}
       if(MamlaSector.selectedIndex !=0){getMamlaSector=" And Items='"+MamlaSectorValue+"'"}
       if(ReportStatus.selectedIndex !=0){getStatus=" And Status='"+ReportStatusValue+"'"}

        if(MamlaValueIndex==1){getMamlaValue=" And Amount < '"+MamlaValue1+"' "}else{
            if(MamlaValueIndex==2){getMamlaValue=" And Amount >'"+MamlaValue1+"' "}else{
                if(MamlaValueIndex==3){getMamlaValue=" And Amount Between '"+MamlaValue1+"' and '"+MamlaValue2+"' "}
            }
        }


        const jsonObject={"sql":"Select * from Mamla_Internal where 1=1  Order by Name ASC"}
        setData([])

        if(MamlaValueIndex==1 ){
            if(MamlaValue1=="" ){cogoToast.error("Please insert Value-1")}else{
                RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

                    await setColumn(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    await setData(result);
                    cogoToast.success("Data found " + result.length);
                    setFoundMsg(result.length)
                    setBtnMamlaReport("primary")

                }).catch(error => { setBtnMamlaReport("primary")
                });
            }
        }else{
            if(MamlaValueIndex==2 ){
                if(MamlaValue1=="" ){cogoToast.error("Please insert Value-1")}else{
                    RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

                        await setColumn(
                            Object.keys(result[0]).map((k) => {
                                return {dataField: k, text: k, sort: true}
                            })
                        )
                        await setData(result);
                        cogoToast.success("Data found " + result.length);
                        setFoundMsg(result.length)
                        setBtnMamlaReport("primary")
                    }).catch(error => { setBtnMamlaReport("primary")
                    });
                }
            }else{
                if(MamlaValueIndex==3 ){
                    if(MamlaValue1=="" || MamlaValue2=="" ){cogoToast.error("Please insert both Value")}else{
                        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

                            await setColumn(
                                Object.keys(result[0]).map((k) => {
                                    return {dataField: k, text: k, sort: true}
                                })
                            )
                            await setData(result);
                            cogoToast.success("Data found " + result.length);
                            setFoundMsg(result.length)
                            setBtnMamlaReport("primary")
                        }).catch(error => { setBtnMamlaReport("primary")
                        });
                    }
                }else{ RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

                    await setColumn(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    await setData(result);
                    cogoToast.success("Data found " + result.length);
                    setFoundMsg(result.length)
                    setBtnMamlaReport("primary")
                }).catch(error => { setBtnMamlaReport("primary")
                });}
            }
        }



    }


    return(
        <Fragment>
            <Container className="animated zoomIn">
                <Row>
                    <Col lg={3}>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Court</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbCourtName"  >
                                    <option value="0">All Court</option>
                                    <option value="1">Appeal</option>
                                    <option value="2">Tribunal</option>
                                    <option value="3">High Court</option>
                                    <option value="4">Supreme Court</option>



                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Type</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbMamlaTypeReport"  >
                                    <option>All Type</option>
                                    <option>Rebate</option>
                                    <option>Audit</option>
                                    <option>4.3</option>
                                    <option>Other</option>
                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Year</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbMamlaYear"  >
                                    <option>All Year</option>
                                    <option>2022</option>
                                    <option>2023</option>
                                    <option>2024</option>
                                    <option>2025</option>
                                    <option>2026</option>


                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2" >
                            <Col lg={3}><label className="text-danger fw-bold">Circle</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbMamlaReportCircle"  >
                                    <option value="0">All Circle</option>
                                    <option value="1">Circle-1</option>
                                    <option value="2">Circle-2</option>
                                    <option value="3">Circle-3</option>
                                    <option value="4">Circle-4</option>
                                    <option value="5">Circle-5</option>
                                    <option value="6">Circle-6</option>

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbMamlaBinName"  >
                                    <option >All BIN</option>
                                    {


                                        state.bin.map((ctr,index)=>{
                                            return (
                                                <option  >{ctr.Name}</option>
                                            )
                                        })
                                    }


                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Sector</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize text-primary" id="cmbMamlaSector"  >
                                    <option value="0">All Sector</option>
                                    <option>Advertiseing</option>
                                    <option>Bank</option>
                                    <option>Beverage</option>
                                    <option>Biscuit</option>
                                    <option>BTRC</option>
                                    <option>Cement</option>
                                    <option>Cigeratte</option>
                                    <option>Cosmetics</option>
                                    <option>Coconut Oil</option>
                                    <option>Dairy</option>
                                    <option>Edibal Oil</option>
                                    <option>Electical Goods</option>
                                    <option>Electricity Distributor</option>
                                    <option>Footware</option>
                                    <option>Glassware</option>
                                    <option>Hotel</option>
                                    <option>Insurance</option>
                                    <option>Izarader</option>
                                    <option>Medicine</option>
                                    <option>Mobile Phone</option>
                                    <option>NG</option>
                                    <option>Oxygen</option>
                                    <option>Other Food</option>
                                    <option>Paper</option>
                                    <option>Packing materials</option>
                                    <option>Paints</option>
                                    <option>Security</option>
                                    <option>Shoes</option>D
                                    <option>Shoes</option>D
                                    <option>Telephone</option>
                                    <option>Telecom Equipment</option>
                                    <option>Ceramic Tiles</option>
                                    <option>Vegetable Oil</option>
                                    <option>Water Supply</option>
                                    <option>Warehouse</option>
                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Status</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbReportStatus"  >
                                    <option value="0">All Status</option>
                                    <option value="0">Stay</option>
                                    <option value="0">ADR</option>
                                    <option value="0">Judgement</option>

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Amount</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbMamlaValueCompared" onChange={(event)=>{

                                    if( event.target.options.selectedIndex==0){ setCompValue1(true) ; setCompValue2(true);value1Ref.current.value="";value2Ref.current.value=""
                                    }else{ setCompValue1(false);  setCompValue2(true)}

                                    if( event.target.options.selectedIndex==3){ setCompValue1(false) ; setCompValue2(false);}

                                }
                                }  >
                                    <option value="0">No Amount</option>
                                    <option value="0">Less than</option>
                                    <option value="0">Greater than</option>
                                    <option value="0">Between</option>
                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger w-100 fw-bold">Value-1</label></Col>
                            <Col lg={3}>
                                <input className="w-100" type="text" id="txtMamlaValue1" ref={value1Ref} disabled={CompValue1}/>
                            </Col>
                            <Col lg={3}><label className="text-danger w-100 fw-bold">Value-2</label></Col>
                            <Col lg={3}>
                                <input type="text" className="w-100" id="txtMamlaValue2" ref={value2Ref} disabled={CompValue2}/>
                            </Col>

                        </Row>
                        <Row className="mt-2"> <Button onClick={getData} variant={BtnMamlaReport} className=" m-2 w-100"> Search</Button></Row>
                        <Row className="mt-2">
                            <Col lg={8}>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="tblSearch"
                                    filename="Order Report"
                                    sheet="tablexls"
                                    buttonText="Download as XLS"/>
                            </Col>
                            <Col lg={4}>
                                <ReactToPrint   trigger={()=><Button className="w-100 " variant="danger">Print</Button>} content={()=>ref.current}  />
                            </Col>

                        </Row>

                    </Col>
                    <Col lg={9}>
                        <div className="tableDiv tableDivBorder">
                            <BootstrapTable ref={ref} id="tblSearch" keyField='Mamla_No' data={Data} columns={Column}  selectRow={selectRow} > </BootstrapTable>

                        </div>
                    </Col>

                </Row>
                <Row> <h3 className="text-danger">Data found: {FoundMsg}</h3></Row>


            </Container>
            <Modal animation={false} className="animated zoomIn" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{BinName}</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label>{DataUpdate}</label>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}
