import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";

export default function BudgetEntry(){
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);

    return(
        <Fragment>
            <Container className="purchaseDiv">
                <Row>
                    <Col lg={4}>
                        <Row className="mt-2">
                            <Col lg={4}><label className="text-danger fw-bold">Main Code</label></Col>
                            <Col lg={8}>
                                <select className="float-start  m-1 w-100 " id="cmbRevenueTable"  >
                                    <option>নির্বাচন করুন</option>
                                    <option>নগদ মন্জুরি ও বেতন</option>
                                    <option>প্রশাসনিক ব্যায়</option>
                                    <option>ফি চার্জ ও কমিশন</option>
                                    <option>প্রশিক্ষন</option>
                                    <option>পেট্রোল, ওয়েল ও লুব্রিকেন্ট</option>
                                    <option>ভ্রমন ও বদলি</option>
                                    <option>মুদ্রন ও মনিহারি</option>
                                    <option>সাধারন সরবরাহ ও কাচামাল সামগ্রী</option>
                                    <option>মেরামত ও সংরক্ষন</option>
                                    <option>যন্ত্রপাতি ও সরন্জামাদি</option>


                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label className="text-danger fw-bold">Sub-Code</label></Col>
                            <Col lg={8}>
                                <select className="float-start  m-1 w-100 " id="cmbRevenueTable"  >

                                    <option>Select</option>

                                </select>
                            </Col>
                        </Row>


                        <Row className="mt-2">
                            <Col lg={4}><label className="text-danger fw-bold">বাজেট</label></Col>
                            <Col lg={8}>
                                <input type="text" id="txtCompareValue1" />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label className="text-danger fw-bold">সংশোধিত</label></Col>
                            <Col lg={8}>
                                <input type="text" id="txtCompareValue2" />
                            </Col>
                        </Row>


                        <Row> <Button  className="mt-2 w-100">Save</Button></Row>

                    </Col>
                    <Col lg={8}>
                        <div className="tableDiv tableDivBorder">
                            <BootstrapTable  keyField='ID' data={Data} columns={Column}  > </BootstrapTable>
                        </div>
                    </Col>

                </Row>


            </Container>
        </Fragment>
    )
}