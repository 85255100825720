import {Fragment, useEffect, useState} from "react";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Container} from "react-bootstrap";

export default function UnitProfileAudit(props){
    const [DataAudit, setDataAudit]=useState([]);
    const [ColumnAudit, setColumnAudit]=useState([{}]);
    useEffect(()=>{
        setDataAudit([])

    },[props.bin]);

const getAudit=()=>{
    if(props.bin=="All BIN"){cogoToast.error("Please select BIn")}else{

        let jsonObjectPrevious={"sql":"Select *  from Audit_Data where Name='"+props.bin+"' "}
        RestClient.PostRequest(AppUrl.getData,jsonObjectPrevious).then(async result=>{
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumnAudit(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataAudit(result);

            }
        }).catch(error=>{});
    }
}
    return(
        <Fragment>
            <Container className="animated zoomIn ">
            <Button className="float-start circular-btn" onClick={getAudit}><i className="fa fa-plus"/></Button>
            <div className="overflow-scroll purchaseMediumDiv"><BootstrapTable   keyField='id' data={DataAudit} columns={ColumnAudit}   > </BootstrapTable></div>
            </Container>
        </Fragment>


    );
}