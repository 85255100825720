import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import BudgetEntry from "../components/GL/BudgetEntry";

export default function GlPage(){



    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center">

                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Budget Entry"><BudgetEntry/></Tab>


                </Tabs>
            </div>
        </Fragment>
    )
}