import {Fragment, useEffect, useState} from "react";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Col, Container, Row} from "react-bootstrap";

export default function UnitProfileLP(props){
    const [DataLP, setDataLP]=useState([]);
    const [ColumnLP, setColumnLP]=useState([{}]);
    const[LpBinName,setLpBinName]=useState()
    const[TotalInvoice,setTotalInvoice]=useState()
    const[TotalAmount,setTotalAmount]=useState()
    useEffect(()=>{
        setDataLP([])
        setLpBinName(props.bin)

    },[props.bin]);
    const getLocalPurchase=()=>{

        if(LpBinName=="All BIN"){cogoToast.error("Please select BIN")}else{

            const AdditionParcen=document.getElementById("txtAdditionParcen").value;
            const AddCompare=document.getElementById("cmbAddCompare");
            let getAddParcent=""
            if(AddCompare.selectedIndex == 1){getAddParcent =" And Addition_Parcentage >"+AdditionParcen+" ";
                if(AdditionParcen==""){cogoToast.error("Please insert value")}
            }
            if(AddCompare.selectedIndex == 2){getAddParcent =" And Addition_Parcentage <"+AdditionParcen+" ";
                if(AdditionParcen==""){cogoToast.error("Please insert value")}
            }

            let jsonObject={"sql":"SELECT Id,Seller_Bin,Seller_Name,VAT_Amount As VAT,Invoice_No,Entry_Date As Sales_Date FROM `Local_Purchase` "}
            setColumnLP([{}]);setDataLP([])
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
                if(result==""){cogoToast.error("Data not found")}else {
                    await setColumnLP(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    await setDataLP(result);
                    let sum=0
                    for (let i = 0; i < result.length; i++) {
                        sum +=parseInt( result[i]["VAT"])
                    }
                    setTotalAmount(sum);
                    setTotalInvoice(result.length)

                }
            }).catch(error=>{});
        }
    }

    return(
        <Fragment>
            <Container className="">
                <Row>
                    <Col lg={7}></Col>
                    <Col lg={2}>
                        <select  className="form-control form-control-sm form-select" id="cmbAddCompare">
                            <option>All Addition</option>
                            <option>More than</option>
                            <option>Less than</option>

                        </select>

                    </Col>
                    <Col lg={1}><input type="text" className="w-100 h-100" id="txtAdditionParcen"/></Col>
                    <Col lg={2}>

                        <Button variant="success" onClick={getLocalPurchase}>Load Data</Button>
                    </Col>

                </Row>

                <div className="overflow-scroll animated zoomIn purchaseMediumDiv"><BootstrapTable   keyField='Id' data={DataLP} columns={ColumnLP}   > </BootstrapTable></div>
                <Row>
                    <Col lg={3}><label className="text-danger">Total Invoice :{TotalInvoice}</label></Col>
                    <Col lg={3}> <label className="text-success">Total VAT Amount :{TotalAmount}</label></Col>
                </Row>


            </Container>

        </Fragment>


    );
}
