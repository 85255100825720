import {HashRouter, NavLink, Route, Routes} from "react-router-dom";
import HomePage from "../Pages/HomePage";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import UserProfilePage from "../Pages/UserProfilePage";
import EmployeePage from "../Pages/EmployeePage";
import RevenuePage from "../Pages/RevenuePage";
import GlPage from "../Pages/GlPage";
import SettingPage from "../Pages/SettingPage";
import LoginPage from "../Pages/LoginPage";
import LawPage from "../Pages/LawPage";
import AuditPage from "../Pages/AuditPage";
import ET_Page from "../Pages/ET_Page";
import UnitProfilePage from "../Pages/UnitProfilePage";
import CigaretteePage from "../Pages/CigaretteePage";



export default function AppRoute(){

    let state=useSelector((state)=> state);
    const [UserRole, setUserRole] = useState(state);



    const isAdmin = () => state.access === 'admin';
    const isADC = () => state.access === 'ADC';
    const isDivision = () => state.access === 'Division';
    const isCircle = () => state.access === 'Circle';
    const isSection = () => state.access === 'Section';
    const isAP = () => state.access === 'AP';
    const isGL = () => state.access === 'GL';
    const isET = () => state.access === 'ET';
    const isLaw = () => state.access === 'Law';
    const isOther = () => state.access === 'Other';


console.log()
    return(

        <HashRouter >



            <Routes>

                <Route path="/" element={<LoginPage/>}></Route>
                <Route path="/home" element={<HomePage/>}></Route>
                {
                    isAdmin() &&(
                    <>

                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                        <Route path="/revenue" element={<RevenuePage/>}></Route>
                        <Route path="/gl" element={<GlPage/>}></Route>
                        <Route path="/hr" element={<EmployeePage/>}></Route>
                        <Route path="/setting" element={<SettingPage/>}></Route>
                        <Route path="/law" element={<LawPage/>}></Route>
                        <Route path="/audit" element={<AuditPage/>}></Route>
                        <Route path="/et" element={<ET_Page/>}></Route>
                        <Route path="/up" element={<UnitProfilePage/>}></Route>
                        <Route path="/cigarettee" element={<CigaretteePage/>}></Route>
                        <Route path="/files/Adjudication/Advanced" ></Route>


                    </>
                    )

                }
                { isADC() &&(
                    <>
                        <Route path="/revenue" element={<RevenuePage/>}></Route>
                        <Route path="/gl" element={<GlPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </>
                ) }
                { isDivision() &&(
                        <>
                            <Route path="/revenue" element={<RevenuePage/>}></Route>
                            <Route path="/profile" element={<UserProfilePage/>}></Route>
                        </>
                    ) }
                { isCircle() &&(
                    <>
                        <Route path="/revenue" element={<RevenuePage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </>
                ) }
                { isAP() &&(
                    <>
                        <Route path="/hr" element={<EmployeePage/>}></Route>
                        <Route path="/setting" element={<SettingPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </>
                ) }
                { isGL() &&(
                    <>
                        <Route path="/gl" element={<GlPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </>
                ) }
                { isET() &&(
                    <>
                        <Route path="/et" element={<ET_Page/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </>
                ) }
                { isLaw() &&(
                    <>
                        <Route path="/law" element={<LawPage/>}></Route>
                        <Route path="/profile" element={<UserProfilePage/>}></Route>
                    </>
                ) }





                <Route path="/*" element={<LoginPage/>}></Route>

            </Routes>
        </HashRouter>


    );
}


