import {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {useSelector} from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";

export default function CoEfficientEntry(){
    let state=useSelector((state)=> state);
const[Data,setData]=useState([]);
const [Column, setColumn]=useState([{}]);
const[Show,setShow]=useState();
const[ShowProduct,setShowProduct]=useState();
const[BtnLoad,setBtnLoad]=useState("primary");
const[BtnSave,setBtnSave]=useState("primary");
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const[ProductID,setProductID]=useState();
    const[CoEfficientID,setCoEfficientID]=useState();
    const[CoEfficientName,setCoEfficientName]=useState();
    const[Category,setCategory]=useState();
    const[Hscode,setHscode]=useState();
    const[Description,setDescripton]=useState();
    const [DateSubmit, setDateSubmit] = useState(new Date());
    const [inputFields, setInputFields] = useState([]);
    const [inputFieldsAddition, setInputFieldsAddition] = useState([]);
    const addInputField = () => {
        setInputFields([...inputFields, ['', '', '','','']]);
    };
    const addInputFieldAddition = () => {
        setInputFieldsAddition([...inputFieldsAddition, ['', '']]);
    };

    const handleInputChange = (index, subIndex, value) => {
        const updatedFields = [...inputFields];
        updatedFields[index][subIndex] = value;
        setInputFields(updatedFields);
    };
    const handleInputChangeAddition = (index, subIndex, value) => {
        const updatedFieldsAddition = [...inputFieldsAddition];
        updatedFieldsAddition[index][subIndex] = value;
        setInputFieldsAddition(updatedFieldsAddition);
    };

    const removeInputField = (index) => {
        const updatedFields = [...inputFields];
        updatedFields.splice(index, 1);
        setInputFields(updatedFields);
    };
    const removeInputFieldAddition = (index) => {
        const updatedFieldsAddition = [...inputFieldsAddition];
        updatedFieldsAddition.splice(index, 1);
        setInputFieldsAddition(updatedFieldsAddition);
    };

    const handleSubmit = () => {
        let SqlValueRm=""
        inputFields.map((field,index)=>{

            SqlValueRm += "(NULL,"+CoEfficientID+",'"+field[0]+"','"+field[1]+"','"+field[2]+"','"+field[3]+"','"+field[4]+"'),"



        })


        let newValueRm=SqlValueRm.slice(0,SqlValueRm.length-1)


        const jsonObject={"sql":"INSERT INTO `coefficient_rm` (`Id`, `Coefficient_Id`, `Item_Name`, `Withwastage_Qty`, `Value`, `Wastage`, `Wastage_Parcentage`) " +
                "VALUES "+newValueRm+" "}
        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(async result=>{
            if(result==""){cogoToast.error("Data not found")}else {
                cogoToast.success("Data saved")

            }
        }).catch(error=>{});
        console.log(jsonObject)
    };
    const handleSubmitAddition = () => {
        let SqlValueAdd=""
        inputFieldsAddition.map((field,index)=>{

            SqlValueAdd += "(NULL,"+CoEfficientID+",'"+field[0]+"','"+field[1]+"'),"

        })



        let newValueAdd=SqlValueAdd.slice(0,SqlValueAdd.length-1)


        const jsonObject={"sql":"INSERT INTO `coefficient_addition` (`Id`, `Coefficient_Id`, `Addition_Desc`, `Value`) VALUES "+newValueAdd+" "}
        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(async result=>{
            if(result==""){cogoToast.error("Data not found")}else {
                cogoToast.success("Data saved")

            }
        }).catch(error=>{});

    };


   const handleClose=()=>{

       setShow(false)
    }
    const handleOpen=()=>{
        setShow(true)
    }

    const handleCloseProduct=()=>{
        setShowProduct(false)
    }
    const handleOpenProduct=()=>{
        setShowProduct(true)
    }



    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setProductID(row["Id"])
            setCategory(row["Category"])
            setDescripton(row["Description"])
            setHscode(row["Hscode"])
            handleOpen()
        }
    }
    const selectRowItem={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setCoEfficientID(row["ID"])
            setCoEfficientName(row["Description_Of_Goods"])


        }
    }

    const saveCoEfficient=()=>{
       setBtnSave("secondary")
            const BINName=document.getElementById("cmbCoEfficientBIN");
            const BinNameValue=BINName.options[BINName.selectedIndex].text;

             const SaleCategory=document.getElementById("cmbSaleCategory");
            const SaleCategoryValue=SaleCategory.options[SaleCategory.selectedIndex].text;

            const Description=document.getElementById("txtDescription").value;
            const SupplyQty=document.getElementById("txtSupplyQty").value;
            const SupplyUnit=document.getElementById("cmbSUnit");
            const SupplyUnitValue=SupplyUnit.options[SupplyUnit.selectedIndex].text;
            const RmValue=document.getElementById("txtRmValue").value;
            const AdditionValue=document.getElementById("txtAddValue").value;
            const Comments=document.getElementById("txtComments").value;

            const AddParcentage=((AdditionValue*100)/RmValue).toFixed(2);
            const TotalPrice=(parseFloat(RmValue)+parseFloat(AdditionValue)).toFixed(2);

            if(BINName.selectedIndex==0){cogoToast.error("Please select BIN Name")}else{
                if(Category==""){cogoToast.error("Please insert Category")}else{
                    if(Hscode==""){cogoToast.error("Please insert Hscode")}else{
                        if(Description==""){cogoToast.error("Please insert Description")}else{
                            if(SupplyUnit.selectedIndex==0){cogoToast.error("Please insert Supply Unit")}else{
                                if(RmValue==""){cogoToast.error("Please insert RM Value")}else{
                                    if(AdditionValue==""){cogoToast.error("Please insert Addition value")}else{

                                        if(SaleCategory.selectedIndex==0){cogoToast.error("Please select Sale Category")}else{
                                            if(Comments==""){cogoToast.error("Please insert Comments")}else{

                                                let jsonObjectPrevious={"sql":"INSERT INTO `Co_Efficient` (`ID`,`Product_Id`, `Product_Category`, `Hscode`, `Description`,`Supply_Qty`, `Supply_Unit`, `Rm_Price`, `Addition_Price`, `Total_Price`, `Addition_Parcentage`,`Status`,`Sale_Category`, `Comments`, `Entry_Date`, `BIN`) " +
                                                        "VALUES (NULL,"+ProductID+", '"+Category+"', '"+Hscode+"', '"+Description+"',"+SupplyQty+", '"+SupplyUnitValue+"', '"+RmValue+"', '"+AdditionValue+"', '"+TotalPrice+"',"+AddParcentage+",'Active','"+SaleCategoryValue+"', '"+Comments+"','"+AppUtility.getDate(DateSubmit)+"', '"+BinNameValue+"')"}
                                                console.log(jsonObjectPrevious)
                                                RestClient.PostRequest(AppUrl.saveData,jsonObjectPrevious).then(async result=>{
                                                    if(result==""){cogoToast.error("Data not found");setBtnSave("primary")}else {
                                                        cogoToast.success("Data saved");setBtnSave("primary")
                                                    }
                                                }).catch(error=>{setBtnSave("primary")});
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


    }
    const saveCoProduct=()=>{

       const CeBIN=document.getElementById("cmbCeBIN");
        const CeBINValue=CeBIN.options[CeBIN.selectedIndex].text;
       const CoCategory=document.getElementById("cmbCeCategory");
       const CategoryValue=CoCategory.options[CoCategory.selectedIndex].text;
       const CoHscode=document.getElementById("txtCeHscode").value;
       const CoDescription=document.getElementById("txtCeDescription").value;

        let jsonObject={"sql":"INSERT INTO `Co_Efficient_Product` (`Id`, `Hscode`, `Description`, `Category`,`Bin_Name`)" +
                " VALUES (NULL, '"+CoHscode+"', '"+CoDescription+"', '"+CategoryValue+"','"+CeBINValue+"')"}

            if(CeBIN.selectedIndex==0){cogoToast.error("Please select BIN")}else{
            if(CoCategory.selectedIndex==0){cogoToast.error("Please select Category")}else{
                if(CoHscode==""){cogoToast.error("Please insert Hscode")}else{
                    if(CoDescription==""){cogoToast.error("Please insert Description")}else{
                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(async result=>{
                            if(result==""){cogoToast.error("Data not found");}else {
                                cogoToast.success("Data saved");
                            }
                        }).catch(error=>{});
                    }
                }}
            }






    }

    const getData=()=>{

       const HscodeSearch=document.getElementById("txtHscodeSearch").value;
       const CeSearchCategory=document.getElementById("cmbCeSearchCategory");
       const CeSearchCategoryValue=CeSearchCategory.options[CeSearchCategory.selectedIndex].text;
       const CeSearchBIN=document.getElementById("cmbCeSearchBIN");
        const CeSearchBINValue=CeSearchBIN.options[CeSearchBIN.selectedIndex].text;

        let CoEffCategory="";
        let CoEffBIN="";
        let CoHscode="";
        if(CeSearchCategory.selectedIndex !=0){CoEffCategory=" And Category='"+CeSearchCategoryValue+"'"}
        if(CeSearchBIN.selectedIndex !=0){CoEffBIN=" And Bin_Name='"+CeSearchBINValue+"'"}
        if(HscodeSearch !=""){CoHscode=" And Hscode Like'%"+HscodeSearch+"%' "}

       let jsonObject={"sql":"SELECT * FROM `Co_Efficient_Product` where 1=1 "+CoEffCategory+" "+CoEffBIN+" "+CoHscode+" LIMIT 100 "}

        setColumn([{}]);setData([])
        setBtnLoad("secondary")
        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

                await setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setData(result);
                setBtnLoad("primary")




        }).catch(error => {   setBtnLoad("primary")
        });
    }
    const get4_3=()=>{


            let jsonObject={"sql":"SELECT ID,Description As Description_Of_Goods,Supply_Qty AS Qty,Supply_Unit As Unit,Rm_Price,Addition_Price,Addition_Parcentage AS 'Add_%',Total_Price,Entry_Date As 'Effect_Date',DATEDIFF(CURDATE(),Entry_Date ) AS Total_Days,Status,Sale_Category,Comments As Comments_for_goods " +
                    "FROM `Co_Efficient` Where Id=(SELECT max(Id) FROM Co_Efficient) "}
            setColumnCoEfficient([{}]);setDataCoEffeciant([])
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
                if(result==""){cogoToast.error("Data not found")}else {
                    await setColumnCoEfficient(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    await setDataCoEffeciant(result);

                }
            }).catch(error=>{});

    }
    return (
        <Fragment>
            <Container className="animated zoomIn  ">


                <Row className="mt-2">
                    <Col sm={12} xs={12} md={12} lg={12}>
                        <Card>
                            <Card.Body>
                                <Container fluid={true}>
                                    <Row>
                                        <Col><button onClick={handleOpenProduct} className="float-start circular-btn">Product Entry <i className="fa fa-plus"/></button></Col>
                                        <Col><label>Hscode</label><input id="txtHscodeSearch" className="w-50" type="text"/></Col>
                                        <Col> <select  className="form-control form-control-sm form-select" id="cmbCeSearchCategory">
                                            <option value="0">All Sector</option>
                                            <option>Advertiseing</option>
                                            <option>Bank</option>
                                            <option>Beverage</option>
                                            <option>Biscuit</option>
                                            <option>BTRC</option>
                                            <option>Chips</option>
                                            <option>Cement</option>
                                            <option>Cigeratte</option>
                                            <option>Cosmetics</option>
                                            <option>Coconut Oil</option>
                                            <option>Dairy</option>
                                            <option>Edibal Oil</option>
                                            <option>Electical Goods</option>
                                            <option>Electricity Distributor</option>
                                            <option>Footware</option>
                                            <option>Glassware</option>
                                            <option>Hotel</option>
                                            <option>Insurance</option>
                                            <option>Izarader</option>
                                            <option>Medicine</option>
                                            <option>Mobile Phone</option>
                                            <option>NG</option>
                                            <option>Oxygen</option>
                                            <option>Other Food</option>
                                            <option>Paper</option>
                                            <option>Packing materials</option>
                                            <option>Paints</option>
                                            <option>Security</option>
                                            <option>Shoes</option>D
                                            <option>Shoes</option>D
                                            <option>Telephone</option>
                                            <option>Telecom Equipment</option>
                                            <option>Ceramic Tiles</option>
                                            <option>Vegetable Oil</option>
                                            <option>Water Supply</option>
                                            <option>Warehouse</option>
                                        </select>
                                        </Col>
                                        <Col> <select  className="form-control form-control-sm form-select" id="cmbCeSearchBIN">
                                            <option >All BIN</option>
                                            {

                                                state.bin.map((ctr,index)=>{
                                                    return (
                                                        <option  >{ctr.Name}</option>
                                                    )
                                                })
                                            }
                                        </select></Col>
                                        <Col><Button variant={BtnLoad} onClick={getData}>Load Product</Button></Col>
                                           </Row>
                                </Container>
                                <hr className="bg-secondary"/>
                                <Container fluid={true}>
                                    <Row>
                                        <Col sm={12} xs={12} md={12} lg={12}>
                                            <div className="purchaseSmallDiv">
                                            <BootstrapTable  keyField='Id' data={Data} columns={Column}  selectRow={selectRow} > </BootstrapTable>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="">
                    <Button onClick={get4_3}>Search</Button>
                    <div className="overflow-scroll animated zoomIn purchaseSmallDiv "><BootstrapTable   keyField='ID' data={DataCoEffeciant} columns={ColumnCoEfficient}  selectRow={selectRowItem} > </BootstrapTable></div>

                </Row>
                <Row className="purchaseMediumDiv">
                    <Col lg={12}>
                        <div>

                            <input type="text" className="w-100" disabled={true} value={CoEfficientName}/>
                            <Button variant="danger" className="m-3" onClick={addInputField}>Add Raw materials Items</Button>
                            <Row className="text-center">
                                <Col lg={2}><div className="text-center">RM Name</div></Col>
                                <Col lg={2}><div className="text-center">Qty with Wastage</div></Col>
                                <Col lg={2}><div className="text-center">Value</div></Col>
                                <Col lg={2}><div className="text-center">Wastage Qty</div></Col>
                                <Col lg={2}><div className="text-center">Parcentage</div></Col>
                                <Col lg={2}></Col>

                            </Row>
                            <Row>

                                    {inputFields.map((fields, index) => (
                                    <div key={index} >
                                        <Row>

                                            {fields.map((value, subIndex) => (
                                                <Col lg="2" >
                                                    <input className="mr-1 w-100"
                                                        key={subIndex}
                                                        type="text"
                                                        value={value}
                                                        onChange={(e) => handleInputChange(index, subIndex, e.target.value)}
                                                    />
                                                </Col>
                                                    ))

                                                }
                                                <Col lg={2}>
                                                    <button className="w-100" onClick={() => removeInputField(index)}>Remove</button>
                                                </Col>




                                           </Row>

                                    </div>

                                ))}


                            </Row>
                            <Button className="m-3" variant="success" onClick={handleSubmit}>Save Items</Button>
                        </div>
                        <div className="mt-4">
                            <Row>
                                <Col lg={6}> <Button className="w-100 m-3" variant="danger" onClick={addInputFieldAddition}>Add Addition Item</Button></Col>
                                <Col lg={6}></Col>
                            </Row>
                            <Row className="text-center">

                                <Col lg={3}><div>RM Name</div></Col>
                                <Col lg={2}><div>Value</div></Col>



                            </Row>

                            <Row>
                                <Col lg={6}>
                                    {inputFieldsAddition.map((fields, index) => (
                                        <div key={index} >
                                            <label>SL-</label>
                                            <label>{index+1}</label>
                                            {fields.map((value, subIndex) => (


                                                <input className="m-1"
                                                       key={subIndex}
                                                       type="text"
                                                       value={value}
                                                       onChange={(e) => handleInputChangeAddition(index, subIndex, e.target.value)}
                                                />

                                            ))}
                                            <button onClick={() => removeInputFieldAddition(index)}>Remove</button>
                                        </div>
                                    ))}
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Button className="w-100 m-3" onClick={handleSubmitAddition}>Save Items</Button>
                                </Col>
                            </Row>

                        </div>
                    </Col>


                </Row>
            </Container>

            <Modal animation={false} className="animated zoomIn" show={Show} onHide={handleClose}>
                <Modal.Header>
                    <select  className="form-control form-control-sm form-select" id="cmbCoEfficientBIN">
                        <option >All BIN</option>
                        {

                            state.bin.map((ctr,index)=>{
                                return (
                                    <option  >{ctr.Name}</option>
                                )
                            })
                        }
                    </select>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label">Description</label>
                    <input className="form-control form-control-sm" disabled={true} Value={Description} type="text" id="txtDescription"/>
                    <label className="form-label">Supply Qty</label>
                    <input className="form-control form-control-sm" type="text" id="txtSupplyQty"/>
                    <label className="form-label">Supply Unit</label>
                    <select  className="form-control form-control-sm form-select" id="cmbSUnit">
                        <option>Select</option>
                        <option>Pack</option>
                        <option>Gram</option>
                        <option>Ltr</option>
                        <option>ML</option>
                        <option>Pair</option>
                        <option>Pcs</option>
                        <option>Kg</option>
                        <option>MT</option>
                        <option>Carton</option>
                    </select>
                    <label className="form-label">RM Value</label>
                    <input className="form-control form-control-sm" type="text" id="txtRmValue"/>
                    <label className="form-label">Addition Value</label>
                    <input className="form-control form-control-sm" type="text" id="txtAddValue"/>
                       <label className="form-label">Sale Category</label>
                    <select  className="form-control form-control-sm form-select" id="cmbSaleCategory">
                        <option >Select Category</option>
                        <option >Normal Sale</option>
                        <option >Special Sale</option>
                    </select>
                    <label className="form-label">Submit Date</label>
                    <DatePicker selected={DateSubmit} onChange={(date) => setDateSubmit(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />
                    <label className="form-label">Comments</label>
                    <textarea className="form-control form-control-sm" id="txtComments" ></textarea>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-sm btn-site" variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <button className="" variant={BtnSave} onClick={saveCoEfficient}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>


            <Modal animation={false} className="animated zoomIn" show={ShowProduct} onHide={handleCloseProduct}>
                <Modal.Header>
                    <select  className="form-control form-control-sm form-select" id="cmbCeBIN">
                        <option >All BIN</option>
                        {

                            state.bin.map((ctr,index)=>{
                                return (
                                    <option  >{ctr.Name}</option>
                                )
                            })
                        }
                    </select>

                </Modal.Header>
                <Modal.Body>
                    <label className="form-label ">Category</label>
                    <select  className="form-control form-control-sm form-select" id="cmbCeCategory">
                        <option value="0">All Sector</option>
                        <option>Advertiseing</option>
                        <option>Bank</option>
                        <option>Beverage</option>
                        <option>Biscuit</option>
                        <option>BTRC</option>
                        <option>Chips</option>
                        <option>Cement</option>
                        <option>Cigeratte</option>
                        <option>Cosmetics</option>
                        <option>Coconut Oil</option>
                        <option>Dairy</option>
                        <option>Edibal Oil</option>
                        <option>Electical Goods</option>
                        <option>Electricity Distributor</option>
                        <option>Footware</option>
                        <option>Glassware</option>
                        <option>Hotel</option>
                        <option>Insurance</option>
                        <option>Izarader</option>
                        <option>Medicine</option>
                        <option>Mobile Phone</option>
                        <option>NG</option>
                        <option>Oxygen</option>
                        <option>Other Food</option>
                        <option>Paper</option>
                        <option>Packing materials</option>
                        <option>Paints</option>
                        <option>Security</option>
                        <option>Shoes</option>
                        <option>Shoes</option>
                        <option>Telephone</option>
                        <option>Telecom Equipment</option>
                        <option>Ceramic Tiles</option>
                        <option>Vegetable Oil</option>
                        <option>Water Supply</option>
                        <option>Warehouse</option>
                    </select>
                    <label className="form-label">Hscode</label>
                    <input className="form-control form-control-sm" type="text" id="txtCeHscode"/>
                    <label className="form-label ">Description</label>
                    <input className="form-control form-control-sm" type="text" id="txtCeDescription"/>


                </Modal.Body>
                <Modal.Footer>
                    <Button className="" variant="danger" onClick={handleCloseProduct}>
                        Close
                    </Button>
                    <Button  className="" variant="primary" onClick={saveCoProduct}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}