import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";

export default function InternalCaseEntry(){
    let state=useSelector((state)=> state);
    const ref=useRef();


    const [ScnDate, setScnDate] = useState(new Date());

    const [Data, setData]=useState([]);

    const [Column, setColumn]=useState([{}]);
    const [Name, setName]=useState();
    const [BIN, setBIN]=useState();
    const [Circle, setCircle]=useState();
    const [Items, setItems]=useState();


    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row['Name']);
            setBIN(row['BIN']);
            setCircle(row['Circle']);
            setItems(row['Items']);



        }
    }



    const saveData=()=>{

        let Nothi_No=document.getElementById("txtNothiNo").value;
        let Mamla_Year=document.getElementById("txtMamlaYear").value;
        let Mamla_No=document.getElementById("txtMamlaNo").value;
        let Mamla_Amount=document.getElementById("txtAmount").value;
        let MamlaComments=document.getElementById("txtComments").value;


        let SCNDate=AppUtility.getDate(ScnDate);
        const jsonObject={"sql":"INSERT INTO `Mamla_Internal` (`Id`, `Name`, `BIN`, `Circle`, `Items`,`Nothi_No`, `Mamla_No`, `Mamla_Year`, `Amount`, `Status`,`Reply_Date`, `Hearing_Date`, `Comments`)" +
                " VALUES (NULL, '"+Name+"', '"+BIN+"', '"+Circle+"', '"+Items+"', '"+Nothi_No+"', '"+Mamla_No+"', '"+Mamla_Year+"', '"+Mamla_Amount+"', '0', '"+SCNDate+"',NULL, '"+MamlaComments+"')"}

        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
        }).catch(error=>{ cogoToast.error(error)});


    }

    const getData=()=>{

        const BinNameIndex=document.getElementById("cmbBinName");
        const BinName=BinNameIndex.options[BinNameIndex.selectedIndex].text;
        const MamlaCircle=document.getElementById("cmbMamlaCircle");
        const Circle=MamlaCircle.options[MamlaCircle.selectedIndex].text;

        let getCircle=""
        let getBin=""

        if(MamlaCircle.selectedIndex !=0){getCircle=" and Circle='"+Circle+"' "}
        if(BinNameIndex.selectedIndex !=0){getBin=" and Name='"+BinName+"' "}

        const jsonObject={"sql":"select Name,BIN,Circle,Items from BIN where 1=1 "+getCircle+" "+getBin+" Order by Name ASC"}
        setData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)

        }).catch(error=>{ });


    }


    return(

        <Fragment>
            <Container className="animated zoomIn" >
                <Row>
                    <Col lg={2}>
                        <Row className="mt-2" >
                            <Col lg={3}><label className="text-danger fw-bold">Circle</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbMamlaCircle"  >
                                    <option value="0">All Circle</option>
                                    <option value="1">Circle-1</option>
                                    <option value="2">Circle-2</option>
                                    <option value="3">Circle-3</option>
                                    <option value="4">Circle-4</option>
                                    <option value="5">Circle-5</option>
                                    <option value="6">Circle-6</option>

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbBinName"  >
                                    <option >All BIN</option>
                                    {


                                        state.bin.map((ctr,index)=>{
                                            return (
                                                <option  >{ctr.Name}</option>
                                            )
                                        })
                                    }


                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2"> <Button onClick={getData}>Search</Button></Row>

                    </Col>
                    <Col lg={10}>
                        <Row>
                            <Col lg={6}>
                                <label>Case entry</label>
                                <div className="tableDiv tableDivBorder">
                                    <BootstrapTable ref={ref} id="tblSearch" keyField='BIN' data={Data} columns={Column} selectRow={selectRow} > </BootstrapTable>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <label>Entry New Case</label>
                                <div className="tableDiv tableDivBorder">
                                   <Row className="fs-4 text-danger">
                                       <Col lg={2}>Name:</Col>
                                       <Col lg={10}><label>{Name}</label></Col>
                                   </Row>
                                    <Row className="fs-4 text-danger">
                                        <Col lg={2}>BIN:</Col>
                                        <Col lg={10}><label>{BIN}</label></Col>
                                    </Row>
                                    <Row className="fs-4 text-danger">
                                        <Col lg={2}>Circle:</Col>
                                        <Col lg={2}><label>{Circle}</label></Col>
                                        <Col lg={3}>Items:</Col>
                                        <Col lg={5}><label>{Items}</label></Col>
                                    </Row>
                                    <Row className="fs-4 text-danger mt-3">
                                        <Col lg={3}>Nothi No:</Col>
                                        <Col lg={9}><input id="txtNothiNo"/></Col>
                                    </Row>
                                    <Row className="fs-4 text-danger mt-3">
                                        <Col lg={4}>Mamla No:</Col>
                                        <Col lg={2}><input className="w-100" id="txtMamlaNo"/></Col>
                                        <Col lg={4}>Mamla Year:</Col>
                                        <Col lg={2}><input className="w-100" id="txtMamlaYear"/></Col>
                                    </Row>
                                    <Row className="fs-4 text-danger mt-3">
                                        <Col lg={3}>Amount:</Col>
                                        <Col lg={2}><input className="w-100" id="txtAmount"/></Col>
                                        <Col lg={3}>SCN Date:</Col>
                                        <Col lg={4} >  <DatePicker selected={ScnDate} onChange={(date) => setScnDate(date)} dateFormat='dd-MM-yyyy' seletable  isClearable showYearDropdown scrollableMonthYearDropdown  />
                                        </Col>
                                    </Row>
                                    <Row className="fs-4 text-danger mt-3">
                                        <Col lg={12}>Comments</Col>
                                        <Col lg={12}><textarea className="w-100" id="txtComments"/></Col>

                                    </Row>
                                    <Row className="fs-4 text-danger">
                                        <Col lg={6}><Button className="w-100 ml-2" onClick={saveData}>Save</Button></Col>
                                        <Col lg={6}><Button className="w-100 mr-2">Cancel</Button></Col>


                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>

        </Fragment>
    );
}
