import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";

export default function CigaretteeEntry(){
    let state=useSelector((state)=> state);
    const ref=useRef();

    const [StatusDate, setStatusDate] = useState(new Date());


    const [DataMamla, setDataMamla]=useState([]);
    const [Data, setData]=useState([]);
    const [ColumnMamla, setColumnMamla]=useState([{}]);
    const [Column, setColumn]=useState([{}]);
    const [Name, setName]=useState();
    const [BIN, setBIN]=useState();
    const [Circle, setCircle]=useState();
    const [Items, setItems]=useState();
    const [MamlaNo, setMamlaNo]=useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row['Name']);
            setBIN(row['BIN']);
            setCircle(row['Circle']);
            setItems(row['Items']);
            handleShow()


        }
    }

    const [showStatus, setShowStatus] = useState(false);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);
    const selectRowStatus={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
                setName(row['Name']);
                setMamlaNo(row['Mamla_No']);

                handleShowStatus()


        }
    }
const updateStatus = () => {


        const txtAreaComments=document.getElementById("txtAreaComments").value;
        const MamlaStatus=document.getElementById("cmbMamlaStatus");
        const MamlaStatusValue=MamlaStatus.options[MamlaStatus.selectedIndex].text;

        let jsonObject={"sql":"Update Mamla set Status='"+MamlaStatusValue+"', Status_Date='"+AppUtility.getDate(StatusDate)+"',Comments='"+txtAreaComments+"' where Mamla_No='"+MamlaNo+"' "}

        if(MamlaStatus.selectedIndex==0){cogoToast.error("Please insert Mamla Status")}else{
        if(txtAreaComments==""){cogoToast.error("Please insert Comments")}else{
            if(StatusDate==null){cogoToast.error("Please insert Date")}else{
                RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{
                    if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
                }).catch(error=>{ cogoToast.error(error)});
            }}
        }



}
    const saveData=()=>{
        const CourtIndex=document.getElementById("cmbCourt");
        const CourtName=CourtIndex.options[CourtIndex.selectedIndex].text;
        const MamlaNo=document.getElementById("textMamlaNo").value
        const MamlaYear=document.getElementById("txtMamlaYear").value;
        const MamlaAmount=document.getElementById("txtMamlaAmount").value;
        const MamlaType=document.getElementById("cmbMamlaType");
        const MamlaTypeValue=MamlaType.options[MamlaType.selectedIndex].text;

        const jsonObject={"sql":"INSERT INTO `Mamla` (`Id`, `Name`, `BIN`, `Circle`, `Items`,`Court`, `Mamla_No`, `Mamla_Year`, `Mamla_Type`,`Amount`, `Status`, `Last_Hearing`, `Status_Date`, `Comments`,`Mamla_Title`,`Analogous`)" +
                " VALUES (NULL, '"+Name+"','"+BIN+"', '"+Circle+"', '"+Items+"', '"+CourtName+"', '"+MamlaNo+"', "+MamlaYear+", '"+MamlaTypeValue+"', '"+MamlaAmount+"','Active', NULL,NULL,'','','')"}

        console.log(jsonObject)
        if(MamlaNo==""){cogoToast.error("Please Insert mamla No")}else{
        if(CourtIndex.selectedIndex==0){cogoToast.error("Please select Court")}else{
        if(MamlaType.selectedIndex==0){cogoToast.error("Please select mamla type")}else{
                if(MamlaYear==""){cogoToast.error("Please Insert mamla Year")}else{
                if(MamlaAmount==""){cogoToast.error("Please Insert mamla Amount")}else{
                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                        if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
                    }).catch(error=>{ cogoToast.error(error)});
                }}}
            }
        }





    }
    const saveStick=()=>{
      
        const cmbMonth=document.getElementById("cmbMonth");
        const MonthName=cmbMonth.options[cmbMonth.selectedIndex].text;
        const cmbYear=document.getElementById("cmbYear");
        const Year=cmbYear.options[cmbYear.selectedIndex].text;

        const Premium=document.getElementById("txtPremium").value;
        const High=document.getElementById("txtHigh").value;
        const Medium=document.getElementById("txtMedium").value;
        const Low=document.getElementById("txtLow").value;
        const TotalStick=document.getElementById("txtTotalStick").value;
        const cmbName=document.getElementById("cmbName");
        const Name=cmbName.options[cmbName.selectedIndex].text;

        const jsonObject={"sql":"INSERT INTO `C_Stick` (`Id`, `Name`, `Stick_Month`, `Stick_Year`, `Pre_Quantity`, `High_Quantity`, `Medium_Quantity`, `Low_Quantity`, `Total_Stick`)VALUES (NULL, '"+Name+"', '"+MonthName+"', '"+Year+"', '"+Premium+"', '"+High+"', '"+Medium+"', '"+Low+"', '"+TotalStick+"')"}

            if(Premium==""){cogoToast.error("Please Insert Premium")}else{
                if(High==""){cogoToast.error("Please Insert High")}else{
                    if(Medium==""){cogoToast.error("Please Insert Medium ")}else{
                        if(Low==""){cogoToast.error("Please Insert Low")}else{
                            if(TotalStick==""){cogoToast.error("Please Insert Total Stick")}else{
                                if(cmbMonth.selectedIndex==0){cogoToast.error("Please Insert Month")}else{
                                    if(cmbYear.selectedIndex==0){cogoToast.error("Please Insert Year")}else{
                                        if(cmbName.selectedIndex==0){cogoToast.error("Please Insert Name")}else{
                                            RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                                if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
                                            }).catch(error=>{ cogoToast.error(error)});

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
                    
                
            





    }

    const saveAllStick=()=>{
      
        const July=document.getElementById("txtJuly").value
        const August=document.getElementById("txtAugust").value
        const September=document.getElementById("txtSeptember").value
        const October=document.getElementById("txtOctober").value
        const November=document.getElementById("txtNovember").value
        const December=document.getElementById("txtDecember").value
        const January=document.getElementById("txtJanuary").value
        const February=document.getElementById("txtFebruary").value
        const March=document.getElementById("txtMarch").value
        const April=document.getElementById("txtApril").value
        const May=document.getElementById("txtMay").value
        const June=document.getElementById("txtJune").value
        const AllTotal=document.getElementById("txtAllTotal").value
        

        const AllSegment=document.getElementById("cmbAllSegment")
        const AllSegmentValue=AllSegment.options[AllSegment.selectedIndex].text;

        const AllYear=document.getElementById("cmbAllYear")
        const AllYearValue=AllYear.options[AllYear.selectedIndex].text;

        const AllName=document.getElementById("cmbAllName")
         const AllNameValue=AllName.options[AllName.selectedIndex].text;
        

        const jsonObject={"sql":"INSERT INTO `Cigarettee_Stick` (`Id`, `Name`, `Segment`, `Stick_Year`, `January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`, `Total`) VALUES (NULL, '"+AllNameValue+"', '"+AllSegmentValue+"', '"+AllYearValue+"', '"+January+"', '"+February+"', '"+March+"', '"+April+"', '"+May+"', '"+June+"', '"+July+"', '"+August+"', '"+September+"', '"+October+"', '"+November+"', '"+December+"', '"+AllTotal+"')"}


        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
        }).catch(error=>{ cogoToast.error(error)});
                    
                
            





    }

    const getData=()=>{

const BinNameIndex=document.getElementById("cmbBinName");
const BinName=BinNameIndex.options[BinNameIndex.selectedIndex].text;
const MamlaCircle=document.getElementById("cmbMamlaCircle");
const Circle=MamlaCircle.options[MamlaCircle.selectedIndex].text;

        let getCircle=""
        let getBin=""

        if(MamlaCircle.selectedIndex !=0){getCircle=" and Circle='"+Circle+"' "}
        if(BinNameIndex.selectedIndex !=0){getBin=" and Name='"+BinName+"' "}

        const jsonObject={"sql":"select Name,BIN,Circle,Items from BIN where 1=1 "+getCircle+" "+getBin+" Order by Name ASC"}
        setData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)

        }).catch(error=>{ });


    }
    const getMamla=()=>{

        const BinNameIndex=document.getElementById("cmbBinName");
        const BinName=BinNameIndex.options[BinNameIndex.selectedIndex].text;
        const MamlaCircle=document.getElementById("cmbMamlaCircle");
        const Circle=MamlaCircle.options[MamlaCircle.selectedIndex].text;

        let getCircle=""
        let getBin=""

        if(MamlaCircle.selectedIndex !=0){getCircle=" and Circle='"+Circle+"' "}
        if(BinNameIndex.selectedIndex !=0){getBin=" and Name='"+BinName+"' "}

        const jsonObject={"sql":"select Id AS 'SL', Name,Mamla_No,Mamla_Year,Mamla_Type As Type,Court  from Mamla where 1=1 "+getCircle+" "+getBin+" Order by Name ASC"}
        setDataMamla([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumnMamla(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setDataMamla(result)

        }).catch(error=>{ });


    }

    return(

        <Fragment>
            <Container className="animated zoomIn" >
                <Row>
                   
                    <Col lg={12}>
                        <Row>
                            <Col lg={6}>
                                <label>Case entry</label>
                                <div className="tableDiv tableDivBorder">
                                   <Row>
                                    <Col>Premium:</Col>
                                    <Col>High:</Col>
                                    <Col>Medium:</Col>
                                    <Col>Low:</Col>

                                   </Row>
                                   <Row>
                                   <Col lg={3}><input id="txtPremium" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtHigh" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtMedium" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtLow" className="w-100"/></Col>

                                    
                                   </Row>
                                   <Row className="mt-4"></Row>
                                   <Row>
                                   <Col lg={3}>Total</Col>
                                    <Col lg={3}>Month</Col>
                                    <Col lg={3}>Year</Col>
                                    <Col lg={3}>Name</Col>
                                   

                                   </Row>
                                   <Row>
                                   <Col lg={3}><input id="txtTotalStick" className="float-start mt-2 w-100"/></Col>
                                   <Col lg={3}>
                                   <select className="float-start mt-2 w-100  " id="cmbMonth"  >
                                    <option>Month</option>
                                     <option>January</option>
                                     <option>February</option>
                                    <option>March</option>
                                     <option>May</option>
                                     <option>June</option>
                                     <option>July</option>
                                     <option>August</option>
                                     <option>September</option>
                                     <option>October</option>
                                     <option>November</option>
                                     <option>December</option>
                            
                                     </select>
                                   </Col>
                                   <Col lg={3}>
                                   <select className="float-start mt-2 w-100  " id="cmbYear"  >
                                    <option>Year</option>
                                     <option>2019</option>
                                     <option>2020</option>
                                    <option>2021</option>
                                     <option>2022</option>
                                     <option>2023</option>
                                     <option>2024</option>
                            
                                     </select>
                                   </Col>
                                   <Col>
                                   <select className="float-start mt-2 w-100  " id="cmbName"  >
                                        <option>Select</option>
                                     <option>BATB Mohakhali</option>
                                     <option>BATB Savar</option>
                                    <option>JTI</option>
                                     
                            
                                     </select></Col>

                                    
                                   </Row>
                                   <Row className="mt-2"><Button onClick={saveStick}>Save</Button></Row>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <label>Stick insert as Month </label>
                                <div className="tableDiv tableDivBorder">
                                   <Row>
                                    <Col>July:</Col>
                                    <Col>August:</Col>
                                    <Col>September:</Col>
                                    <Col>October</Col>

                                   </Row>
                                   <Row>
                                   <Col lg={3}><input id="txtJuly" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtAugust" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtSeptember" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtOctober" className="w-100"/></Col>

                                    
                                   </Row>
                                   <Row>
                                    <Col>November:</Col>
                                    <Col>December</Col>
                                    <Col>January</Col>
                                    <Col>February</Col>

                                   </Row>
                                   <Row>
                                   <Col lg={3}><input id="txtNovember" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtDecember" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtJanuary" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtFebruary" className="w-100"/></Col>

                                    
                                   </Row>
                                   <Row>
                                    <Col>March</Col>
                                    <Col>April</Col>
                                    <Col>May</Col>
                                    <Col>June</Col>

                                   </Row>
                                   <Row>
                                   <Col lg={3}><input id="txtMarch" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtApril" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtMay" className="w-100"/></Col>
                                   <Col lg={3}><input id="txtJune" className="w-100"/></Col>

                                    
                                   </Row>
                                 
                                   <Row className="mt-4"></Row>
                                   <Row>
                                   <Col lg={3}>Total</Col>
                                    <Col lg={3}>Segment</Col>
                                    <Col lg={3}>Year</Col>
                                    <Col lg={3}>Name</Col>
                                   

                                   </Row>
                                   <Row>
                                   <Col lg={3}><input id="txtAllTotal" className="float-start mt-2 w-100"/></Col>
                                   <Col lg={3}>
                                   <select className="float-start mt-2 w-100  " id="cmbAllSegment"  >
                                    <option>All</option>
                                     <option>Premium</option>
                                     <option>High</option>
                                     <option>Medium</option>
                                     <option>Low</option>
                                                                
                                     </select>
                                   </Col>
                                   <Col lg={3}>
                                   <select className="float-start mt-2 w-100  " id="cmbAllYear"  >
                                    <option>Year</option>
                                     <option>2019_20</option>
                                     <option>2020_21</option>
                                     <option>2021_22</option>
                                     <option>2022_23</option>
                                     <option>2023_24</option>
                                     
                            
                                     </select>
                                   </Col>
                                   <Col>
                                   <select className="float-start mt-2 w-100  " id="cmbAllName"  >
                                        <option>Select</option>
                                     <option>BATB Mohakhali</option>
                                     <option>BATB Savar</option>
                                    <option>JTI</option>
                                     
                            
                                     </select></Col>

                                    
                                   </Row>
                                   <Row className="mt-2"><Button onClick={saveAllStick}>Save</Button></Row>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
            <Modal  show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <label className="w-25">Mamla No</label><input id="textMamlaNo" type="text" className="w-75"/>

                    <select className="float-start w-50 mt-2  " id="cmbCourt"  >
                        <option>Select Court Name</option>
                        <option>Appeal</option>
                        <option>Tribunal</option>
                        <option>High Court</option>
                        <option>Supreme Court</option>
                        <option>Other</option>
                    </select>

                    <select className="float-start mt-2 w-50  " id="cmbMamlaType"  >
                        <option>Select Mamla Type</option>
                        <option>Vat appeal</option>
                        <option>Writ</option>
                        <option>Civil Rules</option>
                        <option>CP_CMP</option>
                        <option>Vat Revision</option>
                        <option>Other</option>
                    </select>

                    <h3 className="mt-3 text-danger">Mamla Year</h3>
                    <input  type="text" id="txtMamlaYear" className=" w-50"/>
                    <h3 className="mt-3 text-danger">Total Amount</h3>
                    <input  type="text" id="txtMamlaAmount" className=" w-50"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={saveData} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal  show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <label className="w-25">Mamla No</label>
                    <input id="textMamlaNo" value={MamlaNo} type="text" disabled={true} className="w-75"/>
                    <label className="w-25 mt-2">Status</label>
                    <select className="w-75 mt-2" id="cmbMamlaStatus"  >
                        <option>Select</option>
                        <option>ADR</option>
                        <option>Stay</option>
                        <option>Judgement</option>
                    </select>
                    <label className=" w-25">Comments</label>
                    <textarea className="mt-2 w-75" id="txtAreaComments"></textarea>
                    <h3 className="w-50">Date</h3>
                    <DatePicker selected={StatusDate}
                                onChange={(date) => setStatusDate(date)}
                                dateFormat='dd-MM-yyyy'
                                seletable
                                isClearable
                                showYearDropdown
                                scrollableMonthYearDropdown
                    />


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseStatus}>
                        Close
                    </Button>
                    <Button variant="success" onClick={updateStatus} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}