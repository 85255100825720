import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import {useSelector} from "react-redux";
export default function PreviousYear(){
    const [BtnSearch,setBtnSearch]=useState("primary");
    let state=useSelector((state)=> state);
    const ref=useRef();
    const value1Ref=useRef(false);
    const value2Ref=useRef(false);
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);

    const [CompValue1,setCompValue1]=useState(false)
    const [CompValue2,setCompValue2]=useState(false)

    const Name=" Name As 'Name_Of_The_Industries'";
    const Circle=" Circle As 'Circle_Name'";

    const Total4_5="Total_04_05 As 2004_05";
    const Total5_6="Total_05_06 As 2005_06";
    const Total6_7="Total_06_07 As 2006_07";
    const Total7_8="Total_07_08 As 2007_08";
    const Total8_9="Total_08_09 As 2008_09";
    const Total9_10="Total_09_10 As 2009_10";
    const Total10_11="Total_10_11 As 2010_11";
    const Total11_12="Total_11_12 As 2011_12";
    const Total12_13="Total_12_13 As 2012_13";
    const Total13_14="Total_13_14 As 2013_14";
    const Total14_15="Total_14_15 As 2014_15";
    const Total15_16="Total_15_16 As 2015_16";
    const Total16_17="Total_16_17 As 2016_17";
    const Total17_18="Total_17_18 As 2017_18";
    const Total18_19="Total_18_19 As 2018_19";
    const Total19_20="Total_19_20 As 2019_20";
    const Total20_21="Total_20_21 As 2020_21";
    const Total21_22="Total_21_22 As 2021_22";
    const Total22_23="Total_22_23 As 2022_23";
    const Total23_24="Total_23_24 As 2023_24";
    const Total24_25="Total_24_25 As 2024_25";
    const Total25_26="Total_25_26 As 2025_26";

    const Growth_5_6="Growth_05_06 As 'Growth 05_06'";
    const Growth_6_7="Growth_06_07 As 'Growth 06_07'";
    const Growth_7_8="Growth_07_08 As 'Growth 07_08'";
    const Growth_8_9="Growth_08_09 As 'Growth 08_09'";
    const Growth_9_10="Growth_09_10 As 'Growth 09_10'";
    const Growth_10_11="Growth_10_11 As 'Growth 10_11'";
    const Growth_11_12="Growth_11_12 As 'Growth 11_12'";
    const Growth_12_13="Growth_12_13 As 'Growth 12_13'";
    const Growth_13_14="Growth_13_14 As 'Growth 13_14'";
    const Growth_14_15="Growth_14_15 As 'Growth 14_15'";
    const Growth_15_16="Growth_15_16 As 'Growth 15_16'";
    const Growth_16_17="Growth_16_17 As 'Growth 16_17'";
    const Growth_17_18="Growth_17_18 As 'Growth 17_18'";
    const Growth_18_19="Growth_18_19 As 'Growth 18_19'";
    const Growth_19_20="Growth_19_20 As 'Growth 19_20'";
    const Growth_20_21="Growth_20_21 As 'Growth 20_21'";
    const Growth_21_22="Growth_21_22 As 'Growth 21_22'";
    const Growth_22_23="Growth_22_23 As 'Growth 22_23'";
    const Growth_23_24="Growth_23_24 As 'Growth 23_24'";
    const Growth_24_25="Growth_24_25 As 'Growth 24_25'";
    const Growth_25_26="Growth_25_26 As 'Growth 25_26'";

    const Parcent5_6="Growth_05_06_P As 'Growth% 05_06'";
    const Parcent6_7="Growth_06_07_P As 'Growth% 06_07'";
    const Parcent7_8="Growth_07_08_P As 'Growth% 07_08'";
    const Parcent8_9="Growth_08_09_P As 'Growth5 08_09'";
    const Parcent9_10="Growth_09_10_P As 'Growth% 09_10'";
    const Parcent10_11="Growth_10_11_P As 'Growth% 10_11'";
    const Parcent11_12="Growth_11_12_P As 'Growth% 11_12'";
    const Parcent12_13="Growth_12_13_P As 'Growth% 12_13'";
    const Parcent13_14="Growth_13_14_P As 'Growth% 13_14'";
    const Parcent14_15="Growth_14_15_P As 'Growth% 14_15'";
    const Parcent15_16="Growth_15_16_P As 'Growth% 15_16'";
    const Parcent16_17="Growth_16_17_P As 'Growth% 16_17'";
    const Parcent17_18="Growth_17_18_P As 'Growth% 17_18'";
    const Parcent18_19="Growth_18_19_P As 'Growth% 18_19'";
    const Parcent19_20="Growth_19_20_P As 'Growth% 19_20'";
    const Parcent20_21="Growth_20_21_P As 'Growth% 20_21'";
    const Parcent21_22="Growth_21_22_P As 'Growth% 21_22'";
    const Parcent22_23="Growth_22_23_P As 'Growth% 22_23'";
    const Parcent23_24="Growth_23_24_P As 'Growth% 23_24_%'";
    const Parcent24_25="Growth_24_25_P As 'Growth% 24_25_%'";
    const Parcent25_26="Growth_25_26_P As 'Growth% 25_26_%'";


    const get_Total_Growth_Parcentage=()=>{
        setBtnSearch("secondary")
        const UptoYearIndex=document.getElementById("cmbUptoYear").selectedIndex;

        const PreviousBINName=document.getElementById("cmbPreviousBINName");
        const PreviousBINNameValue=PreviousBINName.options[PreviousBINName.selectedIndex].text


        const PreviousItemIndex=document.getElementById("cmbPreviousItem");
        const PreviousItemIndexValue=PreviousItemIndex.options[PreviousItemIndex.selectedIndex].text

        const CircleIndexPreviousYear=document.getElementById("cmbCirclePreviousYear");
        const CirclePreviousYearValue=CircleIndexPreviousYear.options[CircleIndexPreviousYear.selectedIndex].text;




        let CirclePreviouseYear="";

        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CirclePreviouseYear="and circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndexPreviousYear.selectedIndex!=0){CirclePreviouseYear="and circle='"+CirclePreviousYearValue+"'";}

            }
        }

        let PreviousItems="";
        if(PreviousItemIndex.selectedIndex!=0){PreviousItems=" and Items='"+PreviousItemIndexValue+"' "}

        let BIN_Name="";
        if(PreviousBINName.selectedIndex!=0){BIN_Name=" and name='"+PreviousBINNameValue+"' "}




        const ComparedYear=document.getElementById("cmbComparedYear");
        const ComparedYearValue=ComparedYear.options[ComparedYear.selectedIndex].text;

        const ComparedTypeIndex=document.getElementById("cmbComparedType").selectedIndex;
        let ComparedColumn="";
        if(ComparedTypeIndex==1){ComparedColumn="Total_"+ComparedYearValue+""}
        if(ComparedTypeIndex==2){ComparedColumn="Growth_"+ComparedYearValue+""}
        if(ComparedTypeIndex==3){ComparedColumn="Growth_"+ComparedYearValue+"_P"}


        const CompareIndex=document.getElementById("cmbPreviousComparedAmount").selectedIndex;
        const CompareValue1=document.getElementById("txtPreviousCompareValue1").value;
        const CompareValue2=document.getElementById("txtPreviousCompareValue2").value;

        let Compare=""
        if(ComparedTypeIndex!=0){
            if(CompareIndex==1){Compare="and "+ComparedColumn+" <"+CompareValue1+" ";}

            if(CompareIndex==2){Compare="and "+ComparedColumn+" > "+CompareValue1+" ";}
            if(CompareIndex==3){Compare="and "+ComparedColumn+" Between "+CompareValue1+" and "+CompareValue2+" ";}

        }


        const jsonObject_4={"sql":"SELECT "+Circle+","+Name+","+Total4_5+","+Total5_6+","+Growth_5_6+","+Parcent5_6+","+Total6_7+","+Growth_6_7+","+Parcent6_7+","+Total7_8+","+Growth_7_8+","+Parcent7_8+","+Total8_9+","+Growth_8_9+","+Parcent8_9+","+Total9_10+","+Growth_9_10+","+Parcent9_10+","+Total10_11+","+Growth_10_11+","+Parcent10_11+","+Total11_12+","+Growth_11_12+","+Parcent11_12+","+Total12_13+","+Growth_12_13+","+Parcent12_13+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_5={"sql":"SELECT "+Circle+","+Name+","+Total5_6+","+Growth_5_6+","+Parcent5_6+","+Total6_7+","+Growth_6_7+","+Parcent6_7+","+Total7_8+","+Growth_7_8+","+Parcent7_8+","+Total8_9+","+Growth_8_9+","+Parcent8_9+","+Total9_10+","+Growth_9_10+","+Parcent9_10+","+Total10_11+","+Growth_10_11+","+Parcent10_11+","+Total11_12+","+Growth_11_12+","+Parcent11_12+","+Total12_13+","+Growth_12_13+","+Parcent12_13+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_6={"sql":"SELECT "+Circle+","+Name+","+Total6_7+","+Growth_6_7+","+Parcent6_7+","+Total7_8+","+Growth_7_8+","+Parcent7_8+","+Total8_9+","+Growth_8_9+","+Parcent8_9+","+Total9_10+","+Growth_9_10+","+Parcent9_10+","+Total10_11+","+Growth_10_11+","+Parcent10_11+","+Total11_12+","+Growth_11_12+","+Parcent11_12+","+Total12_13+","+Growth_12_13+","+Parcent12_13+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_7={"sql":"SELECT "+Circle+","+Name+","+Total7_8+","+Growth_7_8+","+Parcent7_8+","+Total8_9+","+Growth_8_9+","+Parcent8_9+","+Total9_10+","+Growth_9_10+","+Parcent9_10+","+Total10_11+","+Growth_10_11+","+Parcent10_11+","+Total11_12+","+Growth_11_12+","+Parcent11_12+","+Total12_13+","+Growth_12_13+","+Parcent12_13+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_8={"sql":"SELECT "+Circle+","+Name+","+Total8_9+","+Growth_8_9+","+Parcent8_9+","+Total9_10+","+Growth_9_10+","+Parcent9_10+","+Total10_11+","+Growth_10_11+","+Parcent10_11+","+Total11_12+","+Growth_11_12+","+Parcent11_12+","+Total12_13+","+Growth_12_13+","+Parcent12_13+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_9={"sql":"SELECT "+Circle+","+Name+","+Total9_10+","+Growth_9_10+","+Parcent9_10+","+Total10_11+","+Growth_10_11+","+Parcent10_11+","+Total11_12+","+Growth_11_12+","+Parcent11_12+","+Total12_13+","+Growth_12_13+","+Parcent12_13+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_10={"sql":"SELECT "+Circle+","+Name+","+Total10_11+","+Growth_10_11+","+Parcent10_11+","+Total11_12+","+Growth_11_12+","+Parcent11_12+","+Total12_13+","+Growth_12_13+","+Parcent12_13+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_11={"sql":"SELECT "+Circle+","+Name+","+Total11_12+","+Growth_11_12+","+Parcent11_12+","+Total12_13+","+Growth_12_13+","+Parcent12_13+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_12={"sql":"SELECT "+Circle+","+Name+","+Total12_13+","+Growth_12_13+","+Parcent12_13+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_13={"sql":"SELECT "+Circle+","+Name+","+Total13_14+","+Growth_13_14+","+Parcent13_14+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_14={"sql":"SELECT "+Circle+","+Name+","+Total14_15+","+Growth_14_15+","+Parcent14_15+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_15={"sql":"SELECT "+Circle+","+Name+","+Total15_16+","+Growth_15_16+","+Parcent15_16+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_16={"sql":"SELECT "+Circle+","+Name+","+Total16_17+","+Growth_16_17+","+Parcent16_17+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_17={"sql":"SELECT "+Circle+","+Name+","+Total17_18+","+Growth_17_18+","+Parcent17_18+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_18={"sql":"SELECT "+Circle+","+Name+","+Total18_19+","+Growth_18_19+","+Parcent18_19+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_19={"sql":"SELECT "+Circle+","+Name+","+Total19_20+","+Growth_19_20+","+Parcent19_20+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_20={"sql":"SELECT "+Circle+","+Name+","+Total20_21+","+Growth_20_21+","+Parcent20_21+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_21={"sql":"SELECT "+Circle+","+Name+","+Total21_22+","+Growth_21_22+","+Parcent21_22+" ,"+Total22_23+","+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_22={"sql":"SELECT "+Circle+","+Name+","+Total22_23+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}

        let jsonObject="";
            if(UptoYearIndex==0){jsonObject=jsonObject_22;}
            if(UptoYearIndex==1){jsonObject=jsonObject_21;}
            if(UptoYearIndex==2){jsonObject=jsonObject_20;}
            if(UptoYearIndex==3){jsonObject=jsonObject_19;}
            if(UptoYearIndex==4){jsonObject=jsonObject_18;}
            if(UptoYearIndex==5){jsonObject=jsonObject_17;}
            if(UptoYearIndex==6){jsonObject=jsonObject_16;}
            if(UptoYearIndex==7){jsonObject=jsonObject_15;}
            if(UptoYearIndex==8){jsonObject=jsonObject_14;}
            if(UptoYearIndex==9){jsonObject=jsonObject_13;}
            if(UptoYearIndex==10){jsonObject=jsonObject_12;}
            if(UptoYearIndex==11){jsonObject=jsonObject_11;}
            if(UptoYearIndex==12){jsonObject=jsonObject_10;}
            if(UptoYearIndex==13){jsonObject=jsonObject_9;}
            if(UptoYearIndex==14){jsonObject=jsonObject_8;}
            if(UptoYearIndex==15){jsonObject=jsonObject_7;}
            if(UptoYearIndex==16){jsonObject=jsonObject_6;}
            if(UptoYearIndex==17){jsonObject=jsonObject_5;}
            if(UptoYearIndex==18){jsonObject=jsonObject_4;}

        setData([])

        if(ComparedTypeIndex!=0){
            if(CompareIndex==0){cogoToast.error("Please select Compared Amount")}else{

                if(CompareIndex==3){

                    if(CompareValue1==""){value1Ref.current.focus();cogoToast.error("Please insert Value1");}else{
                        if(CompareValue2==""){cogoToast.error("Please insert Value2");value2Ref.current.focus();}else{
                            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                                if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                    if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};

                                    setColumn(
                                        Object.keys(result[0]).map((k) => {
                                            return {dataField: k, text: k, sort: true}
                                        })
                                    )
                                    setData(result)
                                    setBtnSearch("primary")
                                }
                            }).catch(error=>{ setBtnSearch("primary")});
                        }}
                }else{
                    if(CompareValue1==""){cogoToast.error("Please insert Value-1");value1Ref.current.focus()}else{


                        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                                setColumn(
                                    Object.keys(result[0]).map((k) => {
                                        return {dataField: k, text: k, sort: true}
                                    })
                                )
                                setData(result);setBtnSearch("primary")
                            }
                        }).catch(error=>{setBtnSearch("primary") });

                    }}}
        }else{RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setData(result);setBtnSearch("primary")
            }
        }).catch(error=>{setBtnSearch("primary") });}






    }
    const getGrowth_Parcentage=()=>{
        setBtnSearch("secondary")







        const UptoYearIndex=document.getElementById("cmbUptoYear").selectedIndex;

        const PreviousBINName=document.getElementById("cmbPreviousBINName");
        const PreviousBINNameValue=PreviousBINName.options[PreviousBINName.selectedIndex].text


        const PreviousItemIndex=document.getElementById("cmbPreviousItem");
        const PreviousItemIndexValue=PreviousItemIndex.options[PreviousItemIndex.selectedIndex].text

        const CircleIndexPreviousYear=document.getElementById("cmbCirclePreviousYear");
        const CirclePreviousYearValue=CircleIndexPreviousYear.options[CircleIndexPreviousYear.selectedIndex].text;




        let CirclePreviouseYear="";

        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CirclePreviouseYear="and circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndexPreviousYear.selectedIndex!=0){CirclePreviouseYear="and circle='"+CirclePreviousYearValue+"'";}

            }
        }

        let PreviousItems="";
        if(PreviousItemIndex.selectedIndex!=0){PreviousItems=" and Items='"+PreviousItemIndexValue+"' "}

        let BIN_Name="";
        if(PreviousBINName.selectedIndex!=0){BIN_Name=" and name='"+PreviousBINNameValue+"' "}




        const ComparedYear=document.getElementById("cmbComparedYear");
        const ComparedYearValue=ComparedYear.options[ComparedYear.selectedIndex].text;

        const ComparedTypeIndex=document.getElementById("cmbComparedType").selectedIndex;
        let ComparedColumn="";
        if(ComparedTypeIndex==1){ComparedColumn="Total_"+ComparedYearValue+""}
        if(ComparedTypeIndex==2){ComparedColumn="Growth_"+ComparedYearValue+""}
        if(ComparedTypeIndex==3){ComparedColumn="Growth_"+ComparedYearValue+"_P"}


        const CompareIndex=document.getElementById("cmbPreviousComparedAmount").selectedIndex;
        const CompareValue1=document.getElementById("txtPreviousCompareValue1").value;
        const CompareValue2=document.getElementById("txtPreviousCompareValue2").value;

        let Compare=""
        if(ComparedTypeIndex!=0){
            if(CompareIndex==1){Compare="and "+ComparedColumn+" <"+CompareValue1+" ";}

            if(CompareIndex==2){Compare="and "+ComparedColumn+" > "+CompareValue1+" ";}
            if(CompareIndex==3){Compare="and "+ComparedColumn+" Between "+CompareValue1+" and "+CompareValue2+" ";}

        }

        const jsonObject_4={"sql":"SELECT "+Circle+","+Name+","+Growth_5_6+","+Parcent5_6+","+Growth_6_7+","+Parcent6_7+","+Growth_7_8+","+Parcent7_8+","+Growth_8_9+","+Parcent8_9+","+Growth_9_10+","+Parcent9_10+","+Growth_10_11+","+Parcent10_11+","+Growth_11_12+","+Parcent11_12+","+Growth_12_13+","+Parcent12_13+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_5={"sql":"SELECT "+Circle+","+Name+","+Growth_5_6+","+Parcent5_6+","+Growth_6_7+","+Parcent6_7+","+Growth_7_8+","+Parcent7_8+","+Growth_8_9+","+Parcent8_9+","+Growth_9_10+","+Parcent9_10+","+Growth_10_11+","+Parcent10_11+","+Growth_11_12+","+Parcent11_12+","+Growth_12_13+","+Parcent12_13+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_6={"sql":"SELECT "+Circle+","+Name+","+Growth_6_7+","+Parcent6_7+","+Growth_7_8+","+Parcent7_8+","+Growth_8_9+","+Parcent8_9+","+Growth_9_10+","+Parcent9_10+","+Growth_10_11+","+Parcent10_11+","+Growth_11_12+","+Parcent11_12+","+Growth_12_13+","+Parcent12_13+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_7={"sql":"SELECT "+Circle+","+Name+","+Growth_7_8+","+Parcent7_8+","+Growth_8_9+","+Parcent8_9+","+Growth_9_10+","+Parcent9_10+","+Growth_10_11+","+Parcent10_11+","+Growth_11_12+","+Parcent11_12+","+Growth_12_13+","+Parcent12_13+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_8={"sql":"SELECT "+Circle+","+Name+","+Growth_8_9+","+Parcent8_9+","+Growth_9_10+","+Parcent9_10+","+Growth_10_11+","+Parcent10_11+","+Growth_11_12+","+Parcent11_12+","+Growth_12_13+","+Parcent12_13+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_9={"sql":"SELECT "+Circle+","+Name+","+Growth_9_10+","+Parcent9_10+","+Growth_10_11+","+Parcent10_11+","+Growth_11_12+","+Parcent11_12+","+Growth_12_13+","+Parcent12_13+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_10={"sql":"SELECT "+Circle+","+Name+","+Growth_10_11+","+Parcent10_11+","+Growth_11_12+","+Parcent11_12+","+Growth_12_13+","+Parcent12_13+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_11={"sql":"SELECT "+Circle+","+Name+","+Growth_11_12+","+Parcent11_12+","+Growth_12_13+","+Parcent12_13+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_12={"sql":"SELECT "+Circle+","+Name+","+Growth_12_13+","+Parcent12_13+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_13={"sql":"SELECT "+Circle+","+Name+","+Growth_13_14+","+Parcent13_14+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_14={"sql":"SELECT "+Circle+","+Name+","+Growth_14_15+","+Parcent14_15+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_15={"sql":"SELECT "+Circle+","+Name+","+Growth_15_16+","+Parcent15_16+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" , "+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_16={"sql":"SELECT "+Circle+","+Name+","+Growth_16_17+","+Parcent16_17+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_17={"sql":"SELECT "+Circle+","+Name+","+Growth_17_18+","+Parcent17_18+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_18={"sql":"SELECT "+Circle+","+Name+","+Growth_18_19+","+Parcent18_19+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_19={"sql":"SELECT "+Circle+","+Name+","+Growth_19_20+","+Parcent19_20+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_20={"sql":"SELECT "+Circle+","+Name+","+Growth_20_21+","+Parcent20_21+","+Growth_21_22+","+Parcent21_22+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_21={"sql":"SELECT "+Circle+","+Name+","+Growth_21_22+","+Parcent21_22+" ,"+Growth_22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_22={"sql":"SELECT "+Circle+","+Name+","+Growth_22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}

        let jsonObject="";
        if(UptoYearIndex==0){jsonObject=jsonObject_22;}
        if(UptoYearIndex==1){jsonObject=jsonObject_21;}
        if(UptoYearIndex==2){jsonObject=jsonObject_20;}
        if(UptoYearIndex==3){jsonObject=jsonObject_19;}
        if(UptoYearIndex==4){jsonObject=jsonObject_18;}
        if(UptoYearIndex==5){jsonObject=jsonObject_17;}
        if(UptoYearIndex==6){jsonObject=jsonObject_16;}
        if(UptoYearIndex==7){jsonObject=jsonObject_15;}
        if(UptoYearIndex==8){jsonObject=jsonObject_14;}
        if(UptoYearIndex==9){jsonObject=jsonObject_13;}
        if(UptoYearIndex==10){jsonObject=jsonObject_12;}
        if(UptoYearIndex==11){jsonObject=jsonObject_11;}
        if(UptoYearIndex==12){jsonObject=jsonObject_10;}
        if(UptoYearIndex==13){jsonObject=jsonObject_9;}
        if(UptoYearIndex==14){jsonObject=jsonObject_8;}
        if(UptoYearIndex==15){jsonObject=jsonObject_7;}
        if(UptoYearIndex==16){jsonObject=jsonObject_6;}
        if(UptoYearIndex==17){jsonObject=jsonObject_5;}
        if(UptoYearIndex==18){jsonObject=jsonObject_4;}

        setData([])

        if(ComparedTypeIndex!=0){
            if(CompareIndex==0){cogoToast.error("Please select Compared Amount")}else{

                if(CompareIndex==3){

                    if(CompareValue1==""){value1Ref.current.focus();cogoToast.error("Please insert Value1");}else{
                        if(CompareValue2==""){cogoToast.error("Please insert Value2");value2Ref.current.focus();}else{
                            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                                if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                    if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};

                                    setColumn(
                                        Object.keys(result[0]).map((k) => {
                                            return {dataField: k, text: k, sort: true}
                                        })
                                    )
                                    setData(result)
                                    setBtnSearch("primary")
                                }
                            }).catch(error=>{ setBtnSearch("primary")});
                        }}
                }else{
                    if(CompareValue1==""){cogoToast.error("Please insert Value-1");value1Ref.current.focus()}else{


                        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                                setColumn(
                                    Object.keys(result[0]).map((k) => {
                                        return {dataField: k, text: k, sort: true}
                                    })
                                )
                                setData(result)
                                setBtnSearch("primary")
                            }
                        }).catch(error=>{setBtnSearch("primary") });

                    }}}
        }else{RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setData(result)
                setBtnSearch("primary")
            }
        }).catch(error=>{ setBtnSearch("primary")});}






    }
    const get_Total_Parcentage=()=>{
        setBtnSearch("secondary")






        const UptoYearIndex=document.getElementById("cmbUptoYear").selectedIndex;

        const PreviousBINName=document.getElementById("cmbPreviousBINName");
        const PreviousBINNameValue=PreviousBINName.options[PreviousBINName.selectedIndex].text


        const PreviousItemIndex=document.getElementById("cmbPreviousItem");
        const PreviousItemIndexValue=PreviousItemIndex.options[PreviousItemIndex.selectedIndex].text

        const CircleIndexPreviousYear=document.getElementById("cmbCirclePreviousYear");
        const CirclePreviousYearValue=CircleIndexPreviousYear.options[CircleIndexPreviousYear.selectedIndex].text;




        let CirclePreviouseYear="";

        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CirclePreviouseYear="and circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndexPreviousYear.selectedIndex!=0){CirclePreviouseYear="and circle='"+CirclePreviousYearValue+"'";}

            }
        }

        let PreviousItems="";
        if(PreviousItemIndex.selectedIndex!=0){PreviousItems=" and Items='"+PreviousItemIndexValue+"' "}

        let BIN_Name="";
        if(PreviousBINName.selectedIndex!=0){BIN_Name=" and name='"+PreviousBINNameValue+"' "}




        const ComparedYear=document.getElementById("cmbComparedYear");
        const ComparedYearValue=ComparedYear.options[ComparedYear.selectedIndex].text;

        const ComparedTypeIndex=document.getElementById("cmbComparedType").selectedIndex;
        let ComparedColumn="";
        if(ComparedTypeIndex==1){ComparedColumn="Total_"+ComparedYearValue+""}
        if(ComparedTypeIndex==2){ComparedColumn="Growth_"+ComparedYearValue+""}
        if(ComparedTypeIndex==3){ComparedColumn="Growth_"+ComparedYearValue+"_P"}


        const CompareIndex=document.getElementById("cmbPreviousComparedAmount").selectedIndex;
        const CompareValue1=document.getElementById("txtPreviousCompareValue1").value;
        const CompareValue2=document.getElementById("txtPreviousCompareValue2").value;

        let Compare=""
        if(ComparedTypeIndex!=0){
            if(CompareIndex==1){Compare="and "+ComparedColumn+" <"+CompareValue1+" ";}

            if(CompareIndex==2){Compare="and "+ComparedColumn+" > "+CompareValue1+" ";}
            if(CompareIndex==3){Compare="and "+ComparedColumn+" Between "+CompareValue1+" and "+CompareValue2+" ";}

        }


        const jsonObject_4={"sql":"SELECT "+Circle+","+Name+","+Total4_5+","+Total5_6+","+Parcent5_6+","+Total6_7+","+Parcent6_7+","+Total7_8+","+Parcent7_8+","+Total8_9+","+Parcent8_9+","+Total9_10+","+Parcent9_10+","+Total10_11+","+Parcent10_11+","+Total11_12+","+Parcent11_12+","+Total12_13+","+Parcent12_13+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_5={"sql":"SELECT "+Circle+","+Name+","+Total5_6+","+Parcent5_6+","+Total6_7+","+Parcent6_7+","+Total7_8+","+Parcent7_8+","+Total8_9+","+Parcent8_9+","+Total9_10+","+Parcent9_10+","+Total10_11+","+Parcent10_11+","+Total11_12+","+Parcent11_12+","+Total12_13+","+Parcent12_13+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_6={"sql":"SELECT "+Circle+","+Name+","+Total6_7+","+Parcent6_7+","+Total7_8+","+Parcent7_8+","+Total8_9+","+Parcent8_9+","+Total9_10+","+Parcent9_10+","+Total10_11+","+Parcent10_11+","+Total11_12+","+Parcent11_12+","+Total12_13+","+Parcent12_13+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_7={"sql":"SELECT "+Circle+","+Name+","+Total7_8+","+Parcent7_8+","+Total8_9+","+Parcent8_9+","+Total9_10+","+Parcent9_10+","+Total10_11+","+Parcent10_11+","+Total11_12+","+Parcent11_12+","+Total12_13+","+Parcent12_13+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_8={"sql":"SELECT "+Circle+","+Name+","+Total8_9+","+Parcent8_9+","+Total9_10+","+Parcent9_10+","+Total10_11+","+Parcent10_11+","+Total11_12+","+Parcent11_12+","+Total12_13+",,"+Parcent12_13+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_9={"sql":"SELECT "+Circle+","+Name+","+Total9_10+","+Parcent9_10+","+Total10_11+","+Parcent10_11+","+Total11_12+","+Parcent11_12+","+Total12_13+","+Parcent12_13+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_10={"sql":"SELECT "+Circle+","+Name+","+Total10_11+","+Parcent10_11+","+Total11_12+","+Parcent11_12+","+Total12_13+","+Parcent12_13+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_11={"sql":"SELECT "+Circle+","+Name+","+Total11_12+","+Parcent11_12+","+Total12_13+","+Parcent12_13+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_12={"sql":"SELECT "+Circle+","+Name+","+Total12_13+","+Parcent12_13+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_13={"sql":"SELECT "+Circle+","+Name+","+Total13_14+","+Parcent13_14+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_14={"sql":"SELECT "+Circle+","+Name+","+Total14_15+","+Parcent14_15+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_15={"sql":"SELECT "+Circle+","+Name+","+Total15_16+","+Parcent15_16+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_16={"sql":"SELECT "+Circle+","+Name+","+Total16_17+","+Parcent16_17+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_17={"sql":"SELECT "+Circle+","+Name+","+Total17_18+","+Parcent17_18+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_18={"sql":"SELECT "+Circle+","+Name+","+Total18_19+","+Parcent18_19+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_19={"sql":"SELECT "+Circle+","+Name+","+Total19_20+","+Parcent19_20+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_20={"sql":"SELECT "+Circle+","+Name+","+Total20_21+","+Parcent20_21+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_21={"sql":"SELECT "+Circle+","+Name+","+Total21_22+","+Parcent21_22+" ,"+Total22_23+","+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_22={"sql":"SELECT "+Circle+","+Name+","+Total22_23+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}

        let jsonObject="";
        if(UptoYearIndex==0){jsonObject=jsonObject_22;}
        if(UptoYearIndex==1){jsonObject=jsonObject_21;}
        if(UptoYearIndex==2){jsonObject=jsonObject_20;}
        if(UptoYearIndex==3){jsonObject=jsonObject_19;}
        if(UptoYearIndex==4){jsonObject=jsonObject_18;}
        if(UptoYearIndex==5){jsonObject=jsonObject_17;}
        if(UptoYearIndex==6){jsonObject=jsonObject_16;}
        if(UptoYearIndex==7){jsonObject=jsonObject_15;}
        if(UptoYearIndex==8){jsonObject=jsonObject_14;}
        if(UptoYearIndex==9){jsonObject=jsonObject_13;}
        if(UptoYearIndex==10){jsonObject=jsonObject_12;}
        if(UptoYearIndex==11){jsonObject=jsonObject_11;}
        if(UptoYearIndex==12){jsonObject=jsonObject_10;}
        if(UptoYearIndex==13){jsonObject=jsonObject_9;}
        if(UptoYearIndex==14){jsonObject=jsonObject_8;}
        if(UptoYearIndex==15){jsonObject=jsonObject_7;}
        if(UptoYearIndex==16){jsonObject=jsonObject_6;}
        if(UptoYearIndex==17){jsonObject=jsonObject_5;}
        if(UptoYearIndex==18){jsonObject=jsonObject_4;}

        setData([])

        if(ComparedTypeIndex!=0){
            if(CompareIndex==0){cogoToast.error("Please select Compared Amount")}else{

                if(CompareIndex==3){

                    if(CompareValue1==""){value1Ref.current.focus();cogoToast.error("Please insert Value1");}else{
                        if(CompareValue2==""){cogoToast.error("Please insert Value2");value2Ref.current.focus();}else{
                            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                                if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                    if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};

                                    setColumn(
                                        Object.keys(result[0]).map((k) => {
                                            return {dataField: k, text: k, sort: true}
                                        })
                                    )
                                    setData(result)
                                    setBtnSearch("primary")
                                }
                            }).catch(error=>{setBtnSearch("primary") });
                        }}
                }else{
                    if(CompareValue1==""){cogoToast.error("Please insert Value-1");value1Ref.current.focus()}else{


                        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                                setColumn(
                                    Object.keys(result[0]).map((k) => {
                                        return {dataField: k, text: k, sort: true}
                                    })
                                )
                                setData(result)
                                setBtnSearch("primary")
                            }
                        }).catch(error=>{setBtnSearch("primary") });

                    }}}
        }else{RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setData(result)
                setBtnSearch("primary")
            }
        }).catch(error=>{setBtnSearch("primary") });}






    }
    const get_Parcentage=()=>{

        setBtnSearch("secondary")

        const UptoYearIndex=document.getElementById("cmbUptoYear").selectedIndex;

        const PreviousBINName=document.getElementById("cmbPreviousBINName");
        const PreviousBINNameValue=PreviousBINName.options[PreviousBINName.selectedIndex].text


        const PreviousItemIndex=document.getElementById("cmbPreviousItem");
        const PreviousItemIndexValue=PreviousItemIndex.options[PreviousItemIndex.selectedIndex].text

        const CircleIndexPreviousYear=document.getElementById("cmbCirclePreviousYear");
        const CirclePreviousYearValue=CircleIndexPreviousYear.options[CircleIndexPreviousYear.selectedIndex].text;




        let CirclePreviouseYear="";

        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CirclePreviouseYear="and circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndexPreviousYear.selectedIndex!=0){CirclePreviouseYear="and circle='"+CirclePreviousYearValue+"'";}

            }
        }

        let PreviousItems="";
        if(PreviousItemIndex.selectedIndex!=0){PreviousItems=" and Items='"+PreviousItemIndexValue+"' "}

        let BIN_Name="";
        if(PreviousBINName.selectedIndex!=0){BIN_Name=" and name='"+PreviousBINNameValue+"' "}




        const ComparedYear=document.getElementById("cmbComparedYear");
        const ComparedYearValue=ComparedYear.options[ComparedYear.selectedIndex].text;

        const ComparedTypeIndex=document.getElementById("cmbComparedType").selectedIndex;
        let ComparedColumn="";
        if(ComparedTypeIndex==1){ComparedColumn="Total_"+ComparedYearValue+""}
        if(ComparedTypeIndex==2){ComparedColumn="Growth_"+ComparedYearValue+""}
        if(ComparedTypeIndex==3){ComparedColumn="Growth_"+ComparedYearValue+"_P"}


        const CompareIndex=document.getElementById("cmbPreviousComparedAmount").selectedIndex;
        const CompareValue1=document.getElementById("txtPreviousCompareValue1").value;
        const CompareValue2=document.getElementById("txtPreviousCompareValue2").value;

        let Compare=""
        if(ComparedTypeIndex!=0){
            if(CompareIndex==1){Compare="and "+ComparedColumn+" <"+CompareValue1+" ";}

            if(CompareIndex==2){Compare="and "+ComparedColumn+" > "+CompareValue1+" ";}
            if(CompareIndex==3){Compare="and "+ComparedColumn+" Between "+CompareValue1+" and "+CompareValue2+" ";}

        }



        const jsonObject_4={"sql":"SELECT "+Circle+","+Name+","+Parcent5_6+","+Parcent6_7+","+Parcent7_8+","+Parcent8_9+","+Parcent9_10+","+Parcent10_11+","+Parcent11_12+","+Parcent12_13+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_5={"sql":"SELECT "+Circle+","+Name+","+Parcent5_6+","+Parcent6_7+","+Parcent7_8+","+Parcent8_9+","+Parcent9_10+","+Parcent10_11+","+Parcent11_12+","+Parcent12_13+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_6={"sql":"SELECT "+Circle+","+Name+","+Parcent6_7+","+Parcent7_8+","+Parcent8_9+","+Parcent9_10+","+Parcent10_11+","+Parcent11_12+","+Parcent12_13+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_7={"sql":"SELECT "+Circle+","+Name+","+Parcent7_8+","+Parcent8_9+","+Parcent9_10+","+Parcent10_11+","+Parcent11_12+","+Parcent12_13+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_8={"sql":"SELECT "+Circle+","+Name+","+Parcent8_9+","+Parcent9_10+","+Parcent10_11+","+Parcent11_12+","+Parcent12_13+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_9={"sql":"SELECT "+Circle+","+Name+","+Parcent9_10+","+Parcent10_11+","+Parcent11_12+","+Parcent12_13+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_10={"sql":"SELECT "+Circle+","+Name+","+Parcent10_11+","+Parcent11_12+","+Parcent12_13+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_11={"sql":"SELECT "+Circle+","+Name+","+Parcent11_12+","+Parcent12_13+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_12={"sql":"SELECT "+Circle+","+Name+","+Parcent12_13+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_13={"sql":"SELECT "+Circle+","+Name+","+Parcent13_14+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_14={"sql":"SELECT "+Circle+","+Name+","+Parcent14_15+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_15={"sql":"SELECT "+Circle+","+Name+","+Parcent15_16+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_16={"sql":"SELECT "+Circle+","+Name+","+Parcent16_17+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_17={"sql":"SELECT "+Circle+","+Name+","+Parcent17_18+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_18={"sql":"SELECT "+Circle+","+Name+","+Parcent18_19+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_19={"sql":"SELECT "+Circle+","+Name+","+Parcent19_20+","+Parcent20_21+","+Parcent21_22+" ,"+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_20={"sql":"SELECT "+Circle+","+Name+","+Parcent20_21+","+Parcent21_22+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_21={"sql":"SELECT "+Circle+","+Name+","+Parcent21_22+" ,"+Parcent22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_22={"sql":"SELECT "+Circle+","+Name+","+Parcent22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}

        let jsonObject="";
        if(UptoYearIndex==0){jsonObject=jsonObject_22;}
        if(UptoYearIndex==1){jsonObject=jsonObject_21;}
        if(UptoYearIndex==2){jsonObject=jsonObject_20;}
        if(UptoYearIndex==3){jsonObject=jsonObject_19;}
        if(UptoYearIndex==4){jsonObject=jsonObject_18;}
        if(UptoYearIndex==5){jsonObject=jsonObject_17;}
        if(UptoYearIndex==6){jsonObject=jsonObject_16;}
        if(UptoYearIndex==7){jsonObject=jsonObject_15;}
        if(UptoYearIndex==8){jsonObject=jsonObject_14;}
        if(UptoYearIndex==9){jsonObject=jsonObject_13;}
        if(UptoYearIndex==10){jsonObject=jsonObject_12;}
        if(UptoYearIndex==11){jsonObject=jsonObject_11;}
        if(UptoYearIndex==12){jsonObject=jsonObject_10;}
        if(UptoYearIndex==13){jsonObject=jsonObject_9;}
        if(UptoYearIndex==14){jsonObject=jsonObject_8;}
        if(UptoYearIndex==15){jsonObject=jsonObject_7;}
        if(UptoYearIndex==16){jsonObject=jsonObject_6;}
        if(UptoYearIndex==17){jsonObject=jsonObject_5;}
        if(UptoYearIndex==18){jsonObject=jsonObject_4;}

        setData([])

        if(ComparedTypeIndex!=0){
            if(CompareIndex==0){cogoToast.error("Please select Compared Amount")}else{

                if(CompareIndex==3){

                    if(CompareValue1==""){value1Ref.current.focus();cogoToast.error("Please insert Value1");}else{
                        if(CompareValue2==""){cogoToast.error("Please insert Value2");value2Ref.current.focus();}else{
                            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                                if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                    if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};

                                    setColumn(
                                        Object.keys(result[0]).map((k) => {
                                            return {dataField: k, text: k, sort: true}
                                        })
                                    )
                                    setData(result)
                                }
                            }).catch(error=>{ });
                        }}
                }else{
                    if(CompareValue1==""){cogoToast.error("Please insert Value-1");value1Ref.current.focus()}else{


                        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                                setColumn(
                                    Object.keys(result[0]).map((k) => {
                                        return {dataField: k, text: k, sort: true}
                                    })
                                )
                                setData(result)
                            }
                        }).catch(error=>{ });

                    }}}
        }else{RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setData(result)
            }
        }).catch(error=>{ });}




        setBtnSearch("primary")

    }
    const get_Growth=()=>{


        setBtnSearch("secondary")




        const UptoYearIndex=document.getElementById("cmbUptoYear").selectedIndex;

        const PreviousBINName=document.getElementById("cmbPreviousBINName");
        const PreviousBINNameValue=PreviousBINName.options[PreviousBINName.selectedIndex].text


        const PreviousItemIndex=document.getElementById("cmbPreviousItem");
        const PreviousItemIndexValue=PreviousItemIndex.options[PreviousItemIndex.selectedIndex].text

        const CircleIndexPreviousYear=document.getElementById("cmbCirclePreviousYear");
        const CirclePreviousYearValue=CircleIndexPreviousYear.options[CircleIndexPreviousYear.selectedIndex].text;




        let CirclePreviouseYear="";

        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CirclePreviouseYear="and circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndexPreviousYear.selectedIndex!=0){CirclePreviouseYear="and circle='"+CirclePreviousYearValue+"'";}

            }
        }

        let PreviousItems="";
        if(PreviousItemIndex.selectedIndex!=0){PreviousItems=" and Items='"+PreviousItemIndexValue+"' "}

        let BIN_Name="";
        if(PreviousBINName.selectedIndex!=0){BIN_Name=" and name='"+PreviousBINNameValue+"' "}




        const ComparedYear=document.getElementById("cmbComparedYear");
        const ComparedYearValue=ComparedYear.options[ComparedYear.selectedIndex].text;

        const ComparedTypeIndex=document.getElementById("cmbComparedType").selectedIndex;
        let ComparedColumn="";
        if(ComparedTypeIndex==1){ComparedColumn="Total_"+ComparedYearValue+""}
        if(ComparedTypeIndex==2){ComparedColumn="Growth_"+ComparedYearValue+""}
        if(ComparedTypeIndex==3){ComparedColumn="Growth_"+ComparedYearValue+"_P"}


        const CompareIndex=document.getElementById("cmbPreviousComparedAmount").selectedIndex;
        const CompareValue1=document.getElementById("txtPreviousCompareValue1").value;
        const CompareValue2=document.getElementById("txtPreviousCompareValue2").value;

        let Compare=""
        if(ComparedTypeIndex!=0){
            if(CompareIndex==1){Compare="and "+ComparedColumn+" <"+CompareValue1+" ";}

            if(CompareIndex==2){Compare="and "+ComparedColumn+" > "+CompareValue1+" ";}
            if(CompareIndex==3){Compare="and "+ComparedColumn+" Between "+CompareValue1+" and "+CompareValue2+" ";}

        }


        const jsonObject_4={"sql":"SELECT "+Circle+","+Name+","+Growth_5_6+","+Growth_6_7+","+Growth_7_8+","+Growth_8_9+","+Growth_9_10+","+Growth_10_11+","+Growth_11_12+","+Growth_12_13+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_5={"sql":"SELECT "+Circle+","+Name+","+Growth_5_6+","+Growth_6_7+","+Growth_7_8+","+Growth_8_9+","+Growth_9_10+","+Growth_10_11+","+Growth_11_12+","+Growth_12_13+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_6={"sql":"SELECT "+Circle+","+Name+","+Growth_6_7+","+Growth_7_8+","+Growth_8_9+","+Growth_9_10+","+Growth_10_11+","+Growth_11_12+","+Growth_12_13+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_7={"sql":"SELECT "+Circle+","+Name+","+Growth_7_8+","+Growth_8_9+","+Growth_9_10+","+Growth_10_11+","+Growth_11_12+","+Growth_12_13+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_8={"sql":"SELECT "+Circle+","+Name+","+Growth_8_9+","+Growth_9_10+","+Growth_10_11+","+Growth_11_12+","+Growth_12_13+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_9={"sql":"SELECT "+Circle+","+Name+","+Growth_9_10+","+Growth_10_11+","+Growth_11_12+","+Growth_12_13+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_10={"sql":"SELECT "+Circle+","+Name+","+Growth_10_11+","+Growth_11_12+","+Growth_12_13+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_11={"sql":"SELECT "+Circle+","+Name+","+Growth_11_12+","+Growth_12_13+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_12={"sql":"SELECT "+Circle+","+Name+","+Growth_12_13+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_13={"sql":"SELECT "+Circle+","+Name+","+Growth_13_14+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_14={"sql":"SELECT "+Circle+","+Name+","+Growth_14_15+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_15={"sql":"SELECT "+Circle+","+Name+","+Growth_15_16+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+", "+Growth_22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_16={"sql":"SELECT "+Circle+","+Name+","+Growth_16_17+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_17={"sql":"SELECT "+Circle+","+Name+","+Growth_17_18+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_18={"sql":"SELECT "+Circle+","+Name+","+Growth_18_19+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_19={"sql":"SELECT "+Circle+","+Name+","+Growth_19_20+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_20={"sql":"SELECT "+Circle+","+Name+","+Growth_20_21+","+Growth_21_22+","+Growth_22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}
        const jsonObject_21={"sql":"SELECT "+Circle+","+Name+","+Growth_21_22+","+Growth_22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+""}
        const jsonObject_22={"sql":"SELECT "+Circle+","+Name+","+Growth_22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "}

        let jsonObject="";
        if(UptoYearIndex==0){jsonObject=jsonObject_22;}
        if(UptoYearIndex==1){jsonObject=jsonObject_21;}
        if(UptoYearIndex==2){jsonObject=jsonObject_20;}
        if(UptoYearIndex==3){jsonObject=jsonObject_19;}
        if(UptoYearIndex==4){jsonObject=jsonObject_18;}
        if(UptoYearIndex==5){jsonObject=jsonObject_17;}
        if(UptoYearIndex==6){jsonObject=jsonObject_16;}
        if(UptoYearIndex==7){jsonObject=jsonObject_15;}
        if(UptoYearIndex==8){jsonObject=jsonObject_14;}
        if(UptoYearIndex==9){jsonObject=jsonObject_13;}
        if(UptoYearIndex==10){jsonObject=jsonObject_12;}
        if(UptoYearIndex==11){jsonObject=jsonObject_11;}
        if(UptoYearIndex==12){jsonObject=jsonObject_10;}
        if(UptoYearIndex==13){jsonObject=jsonObject_9;}
        if(UptoYearIndex==14){jsonObject=jsonObject_8;}
        if(UptoYearIndex==15){jsonObject=jsonObject_7;}
        if(UptoYearIndex==16){jsonObject=jsonObject_6;}
        if(UptoYearIndex==17){jsonObject=jsonObject_5;}
        if(UptoYearIndex==18){jsonObject=jsonObject_4;}

        setData([])

        if(ComparedTypeIndex!=0){
            if(CompareIndex==0){cogoToast.error("Please select Compared Amount")}else{

                if(CompareIndex==3){

                    if(CompareValue1==""){value1Ref.current.focus();cogoToast.error("Please insert Value1");}else{
                        if(CompareValue2==""){cogoToast.error("Please insert Value2");value2Ref.current.focus();}else{
                            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                                if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                    if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};

                                    setColumn(
                                        Object.keys(result[0]).map((k) => {
                                            return {dataField: k, text: k, sort: true}
                                        })
                                    )
                                    setData(result)
                                }
                            }).catch(error=>{ });
                        }}
                }else{
                    if(CompareValue1==""){cogoToast.error("Please insert Value-1");value1Ref.current.focus()}else{


                        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                                setColumn(
                                    Object.keys(result[0]).map((k) => {
                                        return {dataField: k, text: k, sort: true}
                                    })
                                )
                                setData(result)
                            }
                        }).catch(error=>{ });

                    }}}
        }else{RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setData(result)
            }
        }).catch(error=>{ });}



        setBtnSearch("primary")

    }
    const get_Total=()=>{
        setBtnSearch("secondary")


        const UptoYearIndex=document.getElementById("cmbUptoYear").selectedIndex;

        const PreviousBINName=document.getElementById("cmbPreviousBINName");
        const PreviousBINNameValue=PreviousBINName.options[PreviousBINName.selectedIndex].text


        const PreviousItemIndex=document.getElementById("cmbPreviousItem");
        const PreviousItemIndexValue=PreviousItemIndex.options[PreviousItemIndex.selectedIndex].text

        const CircleIndexPreviousYear=document.getElementById("cmbCirclePreviousYear");
        const CirclePreviousYearValue=CircleIndexPreviousYear.options[CircleIndexPreviousYear.selectedIndex].text;




        let CirclePreviouseYear="";

        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CirclePreviouseYear="and circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndexPreviousYear.selectedIndex!=0){CirclePreviouseYear="and circle='"+CirclePreviousYearValue+"'";}

            }
        }

        let PreviousItems="";
        if(PreviousItemIndex.selectedIndex!=0){PreviousItems=" and Items='"+PreviousItemIndexValue+"' "}

        let BIN_Name="";
        if(PreviousBINName.selectedIndex!=0){BIN_Name=" and name='"+PreviousBINNameValue+"' "}




        const ComparedYear=document.getElementById("cmbComparedYear");
        const ComparedYearValue=ComparedYear.options[ComparedYear.selectedIndex].text;

        const ComparedTypeIndex=document.getElementById("cmbComparedType").selectedIndex;
        let ComparedColumn="";
        if(ComparedTypeIndex==1){ComparedColumn="Total_"+ComparedYearValue+""}
        if(ComparedTypeIndex==2){ComparedColumn="Growth_"+ComparedYearValue+""}
        if(ComparedTypeIndex==3){ComparedColumn="Growth_"+ComparedYearValue+"_P"}


        const CompareIndex=document.getElementById("cmbPreviousComparedAmount").selectedIndex;
        const CompareValue1=document.getElementById("txtPreviousCompareValue1").value;
        const CompareValue2=document.getElementById("txtPreviousCompareValue2").value;

       let Compare=""
        if(ComparedTypeIndex!=0){
            if(CompareIndex==1){Compare="and "+ComparedColumn+" <"+CompareValue1+" ";}

            if(CompareIndex==2){Compare="and "+ComparedColumn+" > "+CompareValue1+" ";}
            if(CompareIndex==3){Compare="and "+ComparedColumn+" Between "+CompareValue1+" and "+CompareValue2+" ";}

        }


        const jsonObject_4={"sql":"SELECT "+Circle+","+Name+","+Total4_5+","+Total5_6+","+Total6_7+","+Total7_8+","+Total8_9+","+Total9_10+","+Total10_11+","+Total11_12+","+Total12_13+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+" "}
        const jsonObject_5={"sql":"SELECT "+Circle+","+Name+","+Total5_6+","+Total6_7+","+Total7_8+","+Total8_9+","+Total9_10+","+Total10_11+","+Total11_12+","+Total12_13+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+" "}
        const jsonObject_6={"sql":"SELECT "+Circle+","+Name+","+Total6_7+","+Total7_8+","+Total8_9+","+Total9_10+","+Total10_11+","+Total11_12+","+Total12_13+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_7={"sql":"SELECT "+Circle+","+Name+","+Total7_8+","+Total8_9+","+Total9_10+","+Total10_11+","+Total11_12+","+Total12_13+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+" ,"+Total22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_8={"sql":"SELECT "+Circle+","+Name+","+Total8_9+","+Total9_10+","+Total10_11+","+Total11_12+","+Total12_13+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+" ,"+Total22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+" "}
        const jsonObject_9={"sql":"SELECT "+Circle+","+Name+","+Total9_10+","+Total10_11+","+Total11_12+","+Total12_13+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+" ,"+Total22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+" "}
        const jsonObject_10={"sql":"SELECT "+Circle+","+Name+","+Total10_11+","+Total11_12+","+Total12_13+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+" "}
        const jsonObject_11={"sql":"SELECT "+Circle+","+Name+","+Total11_12+","+Total12_13+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_12={"sql":"SELECT "+Circle+","+Name+","+Total12_13+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_13={"sql":"SELECT "+Circle+","+Name+","+Total13_14+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+"   FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_14={"sql":"SELECT "+Circle+","+Name+","+Total14_15+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_15={"sql":"SELECT "+Circle+","+Name+","+Total15_16+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+" "}
        const jsonObject_16={"sql":"SELECT "+Circle+","+Name+","+Total16_17+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_17={"sql":"SELECT "+Circle+","+Name+","+Total17_18+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_18={"sql":"SELECT "+Circle+","+Name+","+Total18_19+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+" "}
        const jsonObject_19={"sql":"SELECT "+Circle+","+Name+","+Total19_20+","+Total20_21+","+Total21_22+","+Total22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_20={"sql":"SELECT "+Circle+","+Name+","+Total20_21+","+Total21_22+","+Total22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+" "}
        const jsonObject_21={"sql":"SELECT "+Circle+","+Name+","+Total21_22+","+Total22_23+" FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}
        const jsonObject_22={"sql":"SELECT "+Circle+","+Name+","+Total22_23+"  FROM `Previouse_Revenue` where 1=1 "+CirclePreviouseYear+" "+PreviousItems+" "+BIN_Name+" "+Compare+""}

        let jsonObject="";
        if(UptoYearIndex==0){jsonObject=jsonObject_22;}
        if(UptoYearIndex==1){jsonObject=jsonObject_21;}
        if(UptoYearIndex==2){jsonObject=jsonObject_20;}
        if(UptoYearIndex==3){jsonObject=jsonObject_19;}
        if(UptoYearIndex==4){jsonObject=jsonObject_18;}
        if(UptoYearIndex==5){jsonObject=jsonObject_17;}
        if(UptoYearIndex==6){jsonObject=jsonObject_16;}
        if(UptoYearIndex==7){jsonObject=jsonObject_15;}
        if(UptoYearIndex==8){jsonObject=jsonObject_14;}
        if(UptoYearIndex==9){jsonObject=jsonObject_13;}
        if(UptoYearIndex==10){jsonObject=jsonObject_12;}
        if(UptoYearIndex==11){jsonObject=jsonObject_11;}
        if(UptoYearIndex==12){jsonObject=jsonObject_10;}
        if(UptoYearIndex==13){jsonObject=jsonObject_9;}
        if(UptoYearIndex==14){jsonObject=jsonObject_8;}
        if(UptoYearIndex==15){jsonObject=jsonObject_7;}
        if(UptoYearIndex==16){jsonObject=jsonObject_6;}
        if(UptoYearIndex==17){jsonObject=jsonObject_5;}
        if(UptoYearIndex==18){jsonObject=jsonObject_4;}

        setData([])


        if(ComparedTypeIndex!=0){
            if(CompareIndex==0){cogoToast.error("Please select Compared Amount")}else{

                if(CompareIndex==3){

                    if(CompareValue1==""){value1Ref.current.focus();cogoToast.error("Please insert Value1");}else{
                        if(CompareValue2==""){cogoToast.error("Please insert Value2");value2Ref.current.focus();}else{
                            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                                if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                   if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};

                                    setColumn(
                                        Object.keys(result[0]).map((k) => {
                                            return {dataField: k, text: k, sort: true}
                                        })
                                    )
                                    setData(result)
                                }
                            }).catch(error=>{ });
                        }}
                }else{
                    if(CompareValue1==""){cogoToast.error("Please insert Value-1");value1Ref.current.focus()}else{


                        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                            if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                                if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                                setColumn(
                                    Object.keys(result[0]).map((k) => {
                                        return {dataField: k, text: k, sort: true}
                                    })
                                )
                                setData(result)
                            }
                        }).catch(error=>{ });

                    }}}
        }else{RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                    if(result.length!=0){cogoToast.success(result.length+" Data found")}else{cogoToast.error("0 Data found")};
                    setColumn(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    setData(result)
                }
            }).catch(error=>{ });}

        setBtnSearch("primary")
    }

const getData=()=>{
    const ColumnType=document.getElementById("cmbColumnType").selectedIndex;
       if(ColumnType==0){ get_Total()}else{
           if(ColumnType==1){ get_Growth()}else{
               if(ColumnType==2){ get_Parcentage()}else{
                   if(ColumnType==3){ get_Total_Parcentage()}else{
                       if(ColumnType==4){ get_Total_Growth_Parcentage()}else{
                           if(ColumnType==5){ get_Total_Growth_Parcentage()}
                       }}}}}
}

    return(
        <Fragment>
            <Container className="purchaseDiv">
                <Row>
                    <Col lg={3}>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Column</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbColumnType"  >
                                    <option value="0">Total Amount</option>
                                    <option value="1">Growth Amount</option>
                                    <option value="2">Growth %</option>
                                    <option value="3">Total and Growth%</option>
                                    <option value="4">Growth and Growth%</option>
                                    <option value="5">Total, Growth and Growth%</option>


                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2" >
                            <Col lg={3}><label className="text-danger fw-bold">Upto</label></Col>
                            <Col lg={9}>
                                <select className="float-start  m-1 w-100 customfontsize" id="cmbUptoYear"  >
                                    <option>2022-23</option>
                                    <option>2021-22</option>
                                    <option>2020-21</option>
                                    <option>2019-20</option>
                                    <option>2018-19</option>
                                    <option>2017-18</option>
                                    <option>2016-17</option>
                                    <option>2015-16</option>
                                    <option>2014-15</option>
                                    <option>2013-14</option>
                                    <option>2012-13</option>
                                    <option>2011-12</option>
                                    <option>2010-11</option>
                                    <option>2009-10</option>
                                    <option>2008-09</option>
                                    <option>2007-08</option>
                                    <option>2006-07</option>
                                    <option>2005-06</option>
                                    <option>2004-05</option>
                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2" >
                            <Col lg={3}><label className="text-danger fw-bold">Circle</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbCirclePreviousYear"  >
                                    <option value="0">All Circle</option>
                                    <option value="1">Circle-1</option>
                                    <option value="2">Circle-2</option>
                                    <option value="3">Circle-3</option>
                                    <option value="4">Circle-4</option>
                                    <option value="5">Circle-5</option>
                                    <option value="6">Circle-6</option>

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbPreviousBINName"  >
                                    <option >All BIN</option>
                                    {


                                        state.bin.map((ctr,index)=>{
                                            return (
                                                <option  >{ctr.Name}</option>
                                            )
                                        })
                                    }


                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Sector</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize text-primary" id="cmbPreviousItem"  >
                                    <option value="0">All Sector</option>
                                    <option>Advertiseing</option>
                                    <option>Bank</option>
                                    <option>Beverage</option>
                                    <option>Biscuit</option>
                                    <option>BTRC</option>
                                    <option>Cement</option>
                                    <option>Cigeratte</option>
                                    <option>Cosmetics</option>
                                    <option>Coconut Oil</option>
                                    <option>Dairy</option>
                                    <option>Edibal Oil</option>
                                    <option>Electical Goods</option>
                                    <option>Electricity Distributor</option>
                                    <option>Footware</option>
                                    <option>Glassware</option>
                                    <option>Hotel</option>
                                    <option>Insurance</option>
                                    <option>Izarader</option>
                                    <option>Medicine</option>
                                    <option>Mobile Phone</option>
                                    <option>NG</option>
                                    <option>Oxygen</option>
                                    <option>Other Food</option>
                                    <option>Paper</option>
                                    <option>Packing materials</option>
                                    <option>Paints</option>
                                    <option>Security</option>
                                    <option>Shoes</option>D
                                    <option>Shoes</option>D
                                    <option>Telephone</option>
                                    <option>Telecom Equipment</option>
                                    <option>Ceramic Tiles</option>
                                    <option>Vegetable Oil</option>
                                    <option>Water Supply</option>
                                    <option>Warehouse</option>
                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Compared</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbComparedType"  >
                                    <option value="0">No compared</option>
                                    <option value="0">Total Revenue</option>
                                    <option value="0">Growth</option>
                                    <option value="0">Growth%</option>

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Year</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbComparedYear"  >
                                    <option>22_23</option>
                                    <option>21_22</option>
                                    <option>20_21</option>
                                    <option>19_20</option>
                                    <option>18_19</option>
                                    <option>17_18</option>
                                    <option>16_17</option>
                                    <option>15_16</option>
                                    <option>14_15</option>
                                    <option>13_14</option>
                                    <option>12_13</option>
                                    <option>11_12</option>
                                    <option>10_11</option>
                                    <option>09_10</option>
                                    <option>08_09</option>
                                    <option>07_08</option>
                                    <option>06_07</option>
                                    <option>05_06</option>
                                    <option>04_05</option>
                                </select>
                            </Col>

                        </Row>

                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Amount</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbPreviousComparedAmount" onChange={(event)=>{

                                    if( event.target.options.selectedIndex==0){ setCompValue1(true) ; setCompValue2(true);value1Ref.current.value="";value2Ref.current.value=""
                                    }else{ setCompValue1(false);  setCompValue2(true)}

                                    if( event.target.options.selectedIndex==3){ setCompValue1(false) ; setCompValue2(false);}

                                }
                                }  >
                                    <option value="0">No Amount</option>
                                    <option value="0">Less than</option>
                                    <option value="0">Greater than</option>
                                    <option value="0">Between</option>
                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger w-100 fw-bold">Value-1</label></Col>
                            <Col lg={3}>
                                <input className="w-100" type="text" id="txtPreviousCompareValue1" ref={value1Ref} disabled={CompValue1}/>
                            </Col>
                            <Col lg={3}><label className="text-danger w-100 fw-bold">Value-2</label></Col>
                            <Col lg={3}>
                                <input type="text" className="w-100" id="txtPreviousCompareValue2" ref={value2Ref} disabled={CompValue2}/>
                            </Col>

                        </Row>
                        <Row className="mt-2"> <Button onClick={getData} variant={BtnSearch} className=" m-2 w-100"> Search</Button></Row>
                        <Row className="mt-2">
                            <Col lg={8}>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="tblSearch"
                                    filename="Order Report"
                                    sheet="tablexls"
                                    buttonText="Download as XLS"/>
                            </Col>
                            <Col lg={4}>
                                <ReactToPrint   trigger={()=><Button className="w-100 " variant="danger">Print</Button>} content={()=>ref.current}  />
                            </Col>

                        </Row>

                    </Col>
                    <Col lg={9}>
                        <div className="tableDiv tableDivBorder">
                            <BootstrapTable ref={ref} id="tblSearch" keyField='ID' data={Data} columns={Column}  > </BootstrapTable>
                        </div>
                    </Col>

                </Row>


            </Container>

        </Fragment>
    )
}