import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";

export default function HomePage (){




    return (

        <Fragment>
            <TopNavigation />
        </Fragment>
    );
}