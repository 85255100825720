import {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Container, ListGroup, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";

export default function AuditDashboard(){
    let state=useSelector((state)=> state);

    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{



        }
    }
    const getData=(Event)=>{

        let jsonObject="";
        if(Event.target.value=="Continue"){jsonObject={"sql":"select Name,Circle,Status,Last_Audit_Date,Last_Audit_By,Last_Find_Amount from Audit_Data where Status='Continue' Order by Name ASC"}}
        if(Event.target.value=="Commi"){jsonObject={"sql":"select Name,Circle,Status,Last_Audit_Date,Last_Audit_By,Last_Find_Amount from Audit_Data where Last_Audit_By='Commissionerate' Order by Name ASC"}}
        if(Event.target.value=="VatIntel"){jsonObject={"sql":"select Name,Circle,Status,Last_Audit_Date,Last_Audit_By,Last_Find_Amount from Audit_Data where Last_Audit_By='VAT Inteligence' Order by Name ASC"}}


        setData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)

        }).catch(error=>{ });


    }

    return(
        <Fragment>
            <Container className=" text-center">
                <Row>

                            <Col lg={2}>
                                <Row>
                                    <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                        <Card.Header>Audit Continue</Card.Header>
                                        <Card.Body>
                                            <Button  value="Continue" className="w-100" onClick={(event)=> getData(event)}>7</Button>

                                        </Card.Body>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                        <Card.Header className="bg-success">Audit By VAT Intel.</Card.Header>
                                        <Card.Body>
                                            <Button value="VatIntel" className="w-100" onClick={(event)=> getData(event)}>7</Button>

                                        </Card.Body>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                        <Card.Header>Audit By Comm.</Card.Header>
                                        <Card.Body>
                                            <Button value="Commi" className="w-100" onClick={(event)=> getData(event)}>7</Button>

                                        </Card.Body>
                                    </Card>
                                </Row>

                            </Col>
                    <Col lg={10}>
                        <div className="purchaseSmallDiv overflow-scroll">
                            <BootstrapTable keyField='Name' data={Data} columns={Column} selectRow={selectRow} > </BootstrapTable>

                        </div>

                    </Col>

                        </Row>






            </Container>
        </Fragment>
    );
}