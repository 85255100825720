
import './App.css';

import AppRoute from "./router/AppRoute";
import React, {Fragment, useState} from "react";
function App() {

    const [show,setShow]=useState();

  return (

    <div className="App mainBackground">

        <AppRoute/>
    </div>


  );
}

export default App;
