import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";

export default function CaseEntryInternal(){
    let state=useSelector((state)=> state);
    const ref=useRef();

    const [StatusDate, setStatusDate] = useState(new Date());

    const [ScnDate, setScnDate] = useState(new Date());
    const [DataMamla, setDataMamla]=useState([]);
    const [Data, setData]=useState([]);
    const [ColumnMamla, setColumnMamla]=useState([{}]);
    const [Column, setColumn]=useState([{}]);
    const [Name, setName]=useState();
    const [BIN, setBIN]=useState();
    const [Circle, setCircle]=useState();
    const [Items, setItems]=useState();
    const [MamlaNo, setMamlaNo]=useState();
    const [SL, setSL]=useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row['Name']);
            setBIN(row['BIN']);
            setCircle(row['Circle']);
            setItems(row['Items']);
            handleShow()


        }
    }

    const [showStatus, setShowStatus] = useState(false);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);
    const selectRowStatus={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
                setName(row['Name']);
                setMamlaNo(row['Mamla_No']);
                setSL(row['SL']);

                handleShowStatus()


        }
    }
const updateStatus = () => {


        const txtAreaComments=document.getElementById("txtAreaComments").value;
        const MamlaStatus=document.getElementById("cmbMamlaStatus");
        const MamlaStatusValue=MamlaStatus.selectedIndex;

        let jsonObject={"sql":"Update Mamla_Internal set Status='"+MamlaStatusValue+"', Hearing_Date='"+AppUtility.getDate(StatusDate)+"',Comments='"+txtAreaComments+"' where Id='"+SL+"' "}

        if(MamlaStatus.selectedIndex==0){cogoToast.error("Please insert Mamla Status")}else{
        if(txtAreaComments==""){cogoToast.error("Please insert Comments")}else{
            if(StatusDate==null){cogoToast.error("Please insert Date")}else{
                RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{
                    if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
                }).catch(error=>{ cogoToast.error(error)});
            }}
        }



}
    const saveData=()=>{
        const CourtIndex=document.getElementById("cmbCourt");
        const CourtName=CourtIndex.options[CourtIndex.selectedIndex].text;
        const MamlaNo=document.getElementById("textMamlaNo").value
        const MamlaYear=document.getElementById("txtMamlaYear").value;
        const MamlaAmount=document.getElementById("txtMamlaAmount").value;
        const MamlaType=document.getElementById("cmbMamlaType");
        const MamlaTypeValue=MamlaType.options[MamlaType.selectedIndex].text;

        const jsonObject={"sql":"INSERT INTO `Mamla` (`Id`, `Name`, `BIN`, `Circle`, `Items`,`Court`, `Mamla_No`, `Mamla_Year`, `Mamla_Type`,`Amount`, `Status`, `Last_Hearing`, `Status_Date`, `Comments`,`Mamla_Title`,`Analogous`)" +
                " VALUES (NULL, '"+Name+"','"+BIN+"', '"+Circle+"', '"+Items+"', '"+CourtName+"', '"+MamlaNo+"', "+MamlaYear+", '"+MamlaTypeValue+"', '"+MamlaAmount+"','Active', NULL,NULL,'','','')"}

        console.log(jsonObject)
        if(MamlaNo==""){cogoToast.error("Please Insert mamla No")}else{
        if(CourtIndex.selectedIndex==0){cogoToast.error("Please select Court")}else{
        if(MamlaType.selectedIndex==0){cogoToast.error("Please select mamla type")}else{
                if(MamlaYear==""){cogoToast.error("Please Insert mamla Year")}else{
                if(MamlaAmount==""){cogoToast.error("Please Insert mamla Amount")}else{
                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                        if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
                    }).catch(error=>{ cogoToast.error(error)});
                }}}
            }
        }





    }
    const saveInternalCase=()=>{

        let Nothi_No=document.getElementById("txtInternalMamlaNothiNo").value;
        let Mamla_Year=document.getElementById("txtInternalMamlaYear").value;
        let Mamla_No=document.getElementById("txtInternalMamlaNo").value;
        let Mamla_Amount=document.getElementById("txtInternalMamlaAmount").value;
        let MamlaComments=document.getElementById("txtInternalComments").value;
        let SCNDate=AppUtility.getDate(ScnDate);

        const jsonObject={"sql":"INSERT INTO `Mamla_Internal` (`Id`, `Name`, `BIN`, `Circle`, `Items`,`Nothi_No`, `Mamla_No`, `Mamla_Year`, `Amount`, `Status`,`Reply_Date`, `Hearing_Date`, `Comments`)" +
                " VALUES (NULL, '"+Name+"', '"+BIN+"', '"+Circle+"', '"+Items+"', '"+Nothi_No+"', '"+Mamla_No+"', '"+Mamla_Year+"', '"+Mamla_Amount+"', '0', '"+SCNDate+"',NULL, '"+MamlaComments+"')"}
        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
        }).catch(error=>{ cogoToast.error(error)});


    }
    const getData=()=>{

const BinNameIndex=document.getElementById("cmbBinName");
const BinName=BinNameIndex.options[BinNameIndex.selectedIndex].text;
const MamlaCircle=document.getElementById("cmbMamlaCircle");
const Circle=MamlaCircle.options[MamlaCircle.selectedIndex].text;

        let getCircle=""
        let getBin=""

        if(MamlaCircle.selectedIndex !=0){getCircle=" and Circle='"+Circle+"' "}
        if(BinNameIndex.selectedIndex !=0){getBin=" and Name='"+BinName+"' "}

        const jsonObject={"sql":"select Name,BIN,Circle,Items from BIN where 1=1 "+getCircle+" "+getBin+" Order by Name ASC"}
        setData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)

        }).catch(error=>{ });


    }
    const getMamla=()=>{

        const BinNameIndex=document.getElementById("cmbBinName");
        const BinName=BinNameIndex.options[BinNameIndex.selectedIndex].text;
        const MamlaCircle=document.getElementById("cmbMamlaCircle");
        const Circle=MamlaCircle.options[MamlaCircle.selectedIndex].text;

        let getCircle=""
        let getBin=""

        if(MamlaCircle.selectedIndex !=0){getCircle=" and Circle='"+Circle+"' "}
        if(BinNameIndex.selectedIndex !=0){getBin=" and Name='"+BinName+"' "}

        const jsonObject={"sql":"select Id AS SL, Name, Mamla_No,Amount, Status,Reply_Date,Hearing_Date  from Mamla_Internal where 1=1 "+getCircle+" "+getBin+" Order by Name ASC"}
        setDataMamla([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumnMamla(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setDataMamla(result)

        }).catch(error=>{ });


    }

    return(

        <Fragment>
            <Container className="animated zoomIn" >
                <Row>
                    <Col lg={2}>
                        <Row className="mt-2" >
                            <Col lg={3}><label className="text-danger fw-bold">Circle</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbMamlaCircle"  >
                                    <option value="0">All Circle</option>
                                    <option value="1">Circle-1</option>
                                    <option value="2">Circle-2</option>
                                    <option value="3">Circle-3</option>
                                    <option value="4">Circle-4</option>
                                    <option value="5">Circle-5</option>
                                    <option value="6">Circle-6</option>

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbBinName"  >
                                    <option >All BIN</option>
                                    {


                                        state.bin.map((ctr,index)=>{
                                            return (
                                                <option  >{ctr.Name}</option>
                                            )
                                        })
                                    }


                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2"> <Button onClick={getData}>Search BIN</Button></Row>
                        <Row className="mt-2"> <Button onClick={getMamla}>Search Mamla</Button></Row>
                    </Col>
                    <Col lg={10}>
                        <Row>
                            <Col lg={6}>
                                <label>Case Entry</label>
                                <div className="tableDiv tableDivBorder">
                                    <BootstrapTable ref={ref} id="tblSearch" keyField='BIN' data={Data} columns={Column} selectRow={selectRow} > </BootstrapTable>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <label>Update Status</label>
                                <div className="tableDiv tableDivBorder">
                                    <BootstrapTable  keyField='Mamla_No' data={DataMamla} columns={ColumnMamla} selectRow={selectRowStatus}  > </BootstrapTable>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
            <Modal  show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <label className="w-25">Nothi No</label><input id="txtInternalMamlaNothiNo" type="text" className="w-75"/>
                    <label className="w-25">Mamla No</label><input id="txtInternalMamlaNo" type="text" className="w-25 mt-3"/>
                    <h3 className="mt-3 text-danger">Mamla Year</h3>
                    <input  type="text" id="txtInternalMamlaYear" className=" w-50"/>
                    <h3 className="mt-3 text-danger">Total Amount</h3>
                    <input  type="text" id="txtInternalMamlaAmount" className=" w-50"/>
                    <h3 className="mt-3 text-danger">SCN Date</h3>
                    <DatePicker selected={ScnDate} onChange={(date) => setScnDate(date)} dateFormat='dd-MM-yyyy' seletable  isClearable showYearDropdown scrollableMonthYearDropdown  />
                    <h3 className="mt-3 text-danger">Comments</h3>
                    <textarea className="w-100" id="txtInternalComments"/>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={saveInternalCase} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal  show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <label className="w-25">Mamla No</label>
                    <input id="textMamlaNo" value={MamlaNo} type="text" disabled={true} className="w-75"/>
                    <label className="w-25 mt-2">Status</label>
                    <select className="w-75 mt-2" id="cmbMamlaStatus"  >
                        <option>Select</option>
                        <option>Hearing Date</option>

                    </select>
                    <label className=" w-25">Comments</label>
                    <textarea className="mt-2 w-75" id="txtAreaComments"></textarea>
                    <h3 className="w-50">Date</h3>
                    <DatePicker selected={StatusDate}
                                onChange={(date) => setStatusDate(date)}
                                dateFormat='dd-MM-yyyy'
                                seletable
                                isClearable
                                showYearDropdown
                                scrollableMonthYearDropdown
                    />


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseStatus}>
                        Close
                    </Button>
                    <Button variant="success" onClick={updateStatus} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
