import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import BootstrapTable from 'react-bootstrap-table-next';
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";

export default function CurrentRevenue(){

    let state=useSelector((state)=> state);
    const [BtnSearch,setBtnSearch]=useState("success")



    const ref=useRef();
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);

    const getData=()=>{
        setBtnSearch("secondary")

        const CircleIndexCurrent=document.getElementById("cmbCircleCurrent");
         const CircleIndexCurrentValue=CircleIndexCurrent.options[CircleIndexCurrent.selectedIndex].text;

        const itemsIndex=document.getElementById("cmbItems");
        const itemsIndexValue=itemsIndex.options[itemsIndex.selectedIndex].text;




        const UptoMonth=document.getElementById("cmbUptoMonth").selectedIndex;




        let CircleCurrent="";
        let Items="";
        if(itemsIndex.selectedIndex!=0){Items=" And BIN.items='"+itemsIndexValue+"' "}


        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CircleCurrent="and Revenue21_22.circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndexCurrent.selectedIndex !=0){CircleCurrent="and Revenue21_22.circle='"+CircleIndexCurrentValue+"'";}

            }
        }

        const Jan22="Revenue21_22.january As Jan_22";
        const Feb22="Revenue21_22.february As Feb_22";
        const Mar22="Revenue21_22.march As Mar_22";
        const Apr22="Revenue21_22.april As Apr_22";
        const May22="Revenue21_22.may As May_22";
        const Jun22="Revenue21_22.june As Jun_22";
        const Jul22="Revenue21_22.july As Jul_22";
        const Aug22="Revenue21_22.august As Aug_22";
        const Sep22="Revenue21_22.september As Sep_22";
        const Oct22="Revenue21_22.october As Oct_22";
        const Nov22="Revenue21_22.november As Nov_22";
        const Dec22="Revenue21_22.december As Dec_22";

        const Jan23="Revenue22_23.january As Jan_23";
        const Feb23="Revenue22_23.february As Feb_23";
        const Mar23="Revenue22_23.march As Mar_23";
        const Apr23="Revenue22_23.april As Apr_23";
        const May23="Revenue22_23.may As May_23";
        const Jun23="Revenue22_23.june As Jun_23";
        const Jul23="Revenue22_23.july As Jul_23";
        const Aug23="Revenue22_23.august As Aug_23";
        const Sep23="Revenue22_23.september As Sep_23";
        const Oct23="Revenue22_23.october As Oct_23";
        const Nov23="Revenue22_23.november As Nov_23";
        const Dec23="Revenue22_23.december As Dec_23"

        const JanTgt="Target.january As Jan_Target";
        const FebTgt="Target.february As Feb_Target";
        const MarTgt="Target.march As Mar_Target";
        const AprTgt="Target.april As Apr_Target";
        const MayTgt="Target.may As May_Target";
        const JunTgt="Target.june As Jun_Target";
        const JulTgt="Target.july As Jul_Target";
        const AugTgt="Target.august As Aug_Target";
        const SepTgt="Target.september As Sep_Target";
        const OctTgt="Target.october As Oct_Target";
        const NovTgt="Target.november As Nov_Target";
        const DecTgt="Target.december As Dec_Target";




        const BinName="BIN.name As Name_Of_The_Industries"
        const CircleName="BIN.circle As Circle_Name"






        const JunPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september+Revenue21_22.october+Revenue21_22.november+Revenue21_22.december+Revenue21_22.january+Revenue21_22.february+Revenue21_22.march+Revenue21_22.april+Revenue21_22.may+Revenue21_22.june,3)"
        const MayPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september+Revenue21_22.october+Revenue21_22.november+Revenue21_22.december+Revenue21_22.january+Revenue21_22.february+Revenue21_22.march+Revenue21_22.april+Revenue21_22.may,3)"
        const AprPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september+Revenue21_22.october+Revenue21_22.november+Revenue21_22.december+Revenue21_22.january+Revenue21_22.february+Revenue21_22.march+Revenue21_22.april,3)"
        const MarPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september+Revenue21_22.october+Revenue21_22.november+Revenue21_22.december+Revenue21_22.january+Revenue21_22.february+Revenue21_22.march,3)"
        const FebPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september+Revenue21_22.october+Revenue21_22.november+Revenue21_22.december+Revenue21_22.january+Revenue21_22.february,3)"
        const JanPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september+Revenue21_22.october+Revenue21_22.november+Revenue21_22.december+Revenue21_22.january,3)"
        const DecPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september+Revenue21_22.october+Revenue21_22.november+Revenue21_22.december,3)"
        const NovPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september+Revenue21_22.october+Revenue21_22.november,3)"
        const OctPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september+Revenue21_22.october,3)"
        const SeptPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august+Revenue21_22.september,3)"
        const AugPrevTotal="TRUNCATE(Revenue21_22.july+Revenue21_22.august,3)"
        const JulPrevTotal="TRUNCATE(Revenue21_22.july,3)"



        const JunThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september+Revenue22_23.october+Revenue22_23.november+Revenue22_23.december+Revenue22_23.january+Revenue22_23.february+Revenue22_23.march+Revenue22_23.april+Revenue22_23.may+Revenue22_23.june,3)"
        const MayThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september+Revenue22_23.october+Revenue22_23.november+Revenue22_23.december+Revenue22_23.january+Revenue22_23.february+Revenue22_23.march+Revenue22_23.april+Revenue22_23.may,3)"
        const AprThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september+Revenue22_23.october+Revenue22_23.november+Revenue22_23.december+Revenue22_23.january+Revenue22_23.february+Revenue22_23.march+Revenue22_23.april,3)"
        const MarThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september+Revenue22_23.october+Revenue22_23.november+Revenue22_23.december+Revenue22_23.january+Revenue22_23.february+Revenue22_23.march,3)"
        const FebThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september+Revenue22_23.october+Revenue22_23.november+Revenue22_23.december+Revenue22_23.january+Revenue22_23.february,3)"
        const JanThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september+Revenue22_23.october+Revenue22_23.november+Revenue22_23.december+Revenue22_23.january,3)"
        const DecThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september+Revenue22_23.october+Revenue22_23.november+Revenue22_23.december,3)"
        const NovThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september+Revenue22_23.october+Revenue22_23.november,3)"
        const OctThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september+Revenue22_23.october,3)"
        const SeptThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august+Revenue22_23.september,3)"
        const AugThisYearTotal="TRUNCATE(Revenue22_23.july+Revenue22_23.august,3)"
        const JulThisYearTotal="Revenue22_23.july"




        const JunTargetTotal="TRUNCATE(Target.july+Target.august+Target.september+Target.october+Target.november+Target.december+Target.january+Target.february+Target.march+Target.april+Target.may+Target.june,3)"
        const MayTargetTotal="TRUNCATE(Target.july+Target.august+Target.september+Target.october+Target.november+Target.december+Target.january+Target.february+Target.march+Target.april+Target.may,3)"
        const AprTargetTotal="TRUNCATE(Target.july+Target.august+Target.september+Target.october+Target.november+Target.december+Target.january+Target.february+Target.march+Target.april,3)"
        const MarTargetTotal="TRUNCATE(Target.july+Target.august+Target.september+Target.october+Target.november+Target.december+Target.january+Target.february+Target.march,3)"
        const FebTargetTotal="TRUNCATE(Target.july+Target.august+Target.september+Target.october+Target.november+Target.december+Target.january+Target.february,3)"
        const JanTargetTotal="TRUNCATE(Target.july+Target.august+Target.september+Target.october+Target.november+Target.december+Target.january,3)"
        const DecTargetTotal="TRUNCATE(Target.july+Target.august+Target.september+Target.october+Target.november+Target.december,3)"
        const NovTargetTotal="TRUNCATE(Target.july+Target.august+Target.september+Target.october+Target.november,3)"
        const OctTargetTotal="TRUNCATE(Target.july+Target.august+Target.september+Target.october,3)"
        const SeptTargetTotal="TRUNCATE(Target.july+Target.august+Target.september,3)"
        const AugTargetTotal="TRUNCATE(Target.july+Target.august,3)"
        const JulTargetTotal="Target.july"




        const JunDiffWithLastYear="TRUNCATE("+JunThisYearTotal+"-"+JunPrevTotal+",2) "
        const MayDiffWithLastYear="TRUNCATE("+MayThisYearTotal+"-"+MayPrevTotal+",2) "
        const AprDiffWithLastYear="TRUNCATE("+AprThisYearTotal+"-"+AprPrevTotal+",2) "
        const MarDiffWithLastYear="TRUNCATE("+MarThisYearTotal+"-"+MarPrevTotal+",2) "
        const FebDiffWithLastYear="TRUNCATE("+FebThisYearTotal+"-"+FebPrevTotal+",2) "
        const JanDiffWithLastYear="TRUNCATE("+JanThisYearTotal+"-"+JanPrevTotal+",2) "
        const DecDiffWithLastYear="TRUNCATE("+DecThisYearTotal+"-"+DecPrevTotal+",2) "
        const NovDiffWithLastYear="TRUNCATE("+NovThisYearTotal+"-"+NovPrevTotal+",2) "
        const OctDiffWithLastYear="TRUNCATE("+OctThisYearTotal+"-"+OctPrevTotal+",2) "
        const SeptDiffWithLastYear="TRUNCATE("+SeptThisYearTotal+"-"+SeptPrevTotal+",2) "
        const AugDiffWithLastYear="TRUNCATE("+AugThisYearTotal+"-"+AugPrevTotal+",2) "
        const JulDiffWithLastYear="TRUNCATE("+JulThisYearTotal+"-"+JulPrevTotal+",2) "




        const JunDiffWithTarget="TRUNCATE("+JunThisYearTotal+"-"+JunTargetTotal+",2) "
        const MayDiffWithTarget="TRUNCATE("+MayThisYearTotal+"-"+MayTargetTotal+",2) "
        const AprDiffWithTarget="TRUNCATE("+AprThisYearTotal+"-"+AprTargetTotal+",2) "
        const MarDiffWithTarget="TRUNCATE("+MarThisYearTotal+"-"+MarTargetTotal+",2) "
        const FebDiffWithTarget="TRUNCATE("+FebThisYearTotal+"-"+FebTargetTotal+",2) "
        const JanDiffWithTarget="TRUNCATE("+JanThisYearTotal+"-"+JanTargetTotal+",2) "
        const DecDiffWithTarget="TRUNCATE("+DecThisYearTotal+"-"+DecTargetTotal+",2) "
        const NovDiffWithTarget="TRUNCATE("+NovThisYearTotal+"-"+NovTargetTotal+",2) "
        const OctDiffWithTarget="TRUNCATE("+OctThisYearTotal+"-"+OctTargetTotal+",2) "
        const SeptDiffWithTarget="TRUNCATE("+SeptThisYearTotal+"-"+SeptTargetTotal+",2) "
        const AugDiffWithTarget="TRUNCATE("+AugThisYearTotal+"-"+AugTargetTotal+",2) "
        const JulDiffWithTarget="TRUNCATE("+JulThisYearTotal+"-"+JulTargetTotal+",2)"


        const JunDiffWithLastYearParcent=" TRUNCATE(("+JunDiffWithLastYear+"*100)/"+JunPrevTotal+",2)"
        const MayDiffWithLastYearParcent=" TRUNCATE(("+MayDiffWithLastYear+"*100)/"+MayPrevTotal+",2)"
        const AprDiffWithLastYearParcent=" TRUNCATE(("+AprDiffWithLastYear+"*100)/"+AprPrevTotal+",2)"
        const MarDiffWithLastYearParcent=" TRUNCATE(("+MarDiffWithLastYear+"*100)/"+MarPrevTotal+",2)"
        const FebDiffWithLastYearParcent=" TRUNCATE(("+FebDiffWithLastYear+"*100)/"+FebPrevTotal+",2)"
        const JanDiffWithLastYearParcent=" TRUNCATE(("+JanDiffWithLastYear+"*100)/"+JanPrevTotal+",2)"
        const DecDiffWithLastYearParcent=" TRUNCATE(("+DecDiffWithLastYear+"*100)/"+DecPrevTotal+",2)"
        const NovDiffWithLastYearParcent=" TRUNCATE(("+NovDiffWithLastYear+"*100)/"+NovPrevTotal+",2)"
        const OctDiffWithLastYearParcent=" TRUNCATE(("+OctDiffWithLastYear+"*100)/"+OctPrevTotal+",2)"
        const SeptDiffWithLastYearParcent=" TRUNCATE(("+SeptDiffWithLastYear+"*100)/"+SeptPrevTotal+",2)"
        const AugDiffWithLastYearParcent=" TRUNCATE(("+AugDiffWithLastYear+"*100)/"+AugPrevTotal+",2)"
        const JulDiffWithLastYearParcent=" TRUNCATE(("+JulDiffWithLastYear+"*100)/"+JulPrevTotal+",2)"


        const JunDiffWithTagetParcent=" TRUNCATE(("+JunDiffWithTarget+"*100)/"+JunTargetTotal+",2)"
        const MayDiffWithTagetParcent=" TRUNCATE(("+MayDiffWithTarget+"*100)/"+MayTargetTotal+",2)"
        const AprDiffWithTagetParcent=" TRUNCATE(("+AprDiffWithTarget+"*100)/"+AprTargetTotal+",2)"
        const MarDiffWithTagetParcent=" TRUNCATE(("+MarDiffWithTarget+"*100)/"+MarTargetTotal+",2)"
        const FebDiffWithTagetParcent=" TRUNCATE(("+FebDiffWithTarget+"*100)/"+FebTargetTotal+",2)"
        const JanDiffWithTagetParcent=" TRUNCATE(("+JanDiffWithTarget+"*100)/"+JanTargetTotal+",2)"
        const DecDiffWithTagetParcent=" TRUNCATE(("+DecDiffWithTarget+"*100)/"+DecTargetTotal+",2)"
        const NovDiffWithTagetParcent=" TRUNCATE(("+NovDiffWithTarget+"*100)/"+NovTargetTotal+",2)"
        const OctDiffWithTagetParcent=" TRUNCATE(("+OctDiffWithTarget+"*100)/"+OctTargetTotal+",2)"
        const SeptDiffWithTagetParcent=" TRUNCATE(("+SeptDiffWithTarget+"*100)/"+SeptTargetTotal+",2)"
        const AugDiffWithTagetParcent=" TRUNCATE(("+AugDiffWithTarget+"*100)/"+AugTargetTotal+",2)"
        const JulDiffWithTagetParcent=" TRUNCATE(("+JulDiffWithTarget+"*100)/"+JulTargetTotal+",2)"





        const JuljsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+"" +
                ","+JulTgt+"" +
                ","+Jul23+","+JulPrevTotal+" As 'UpTo Prev', "+JulThisYearTotal+" As 'UpTO Now' , "+JulDiffWithLastYear+" As 'Diff_With Prev TK',"+JulDiffWithLastYearParcent+" As 'Diff_With Prev %',"+JulDiffWithTarget+" As 'Diff_With Target',"+JulDiffWithTagetParcent+" As 'Diff_with Target %' from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}

        const AugjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+"" +
                ","+JulTgt+","+AugTgt+"" +
                ","+Jul23+","+Aug23+","+AugPrevTotal+" As 'UpTo Prev', "+AugThisYearTotal+" As 'UpTO Now' , "+AugDiffWithLastYear+" As 'Diff_With Prev TK',"+AugDiffWithLastYearParcent+" As 'Diff_With Prev %',"+AugDiffWithTarget+" As 'Diff_With Target',"+AugDiffWithTagetParcent+" As 'Diff_with Target %' from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}


        const SeptjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+"" +
                ","+Jul23+","+Aug23+","+Sep23+","+SeptPrevTotal+" As 'UpTo Prev', "+SeptThisYearTotal+" As 'UpTO Now' , "+SeptDiffWithLastYear+" As 'Diff_With Prev TK',"+SeptDiffWithLastYearParcent+" As 'Diff_With Prev %',"+SeptDiffWithTarget+" As 'Diff_With Target',"+SeptDiffWithTagetParcent+" As 'Diff_with Target %' from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}


        const OctjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+","+Oct22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+","+OctTgt+"" +
                ","+Jul23+","+Aug23+","+Sep23+","+Oct23+","+OctPrevTotal+" As 'UpTo Prev', "+OctThisYearTotal+" As 'UpTO Now' , "+OctDiffWithLastYear+" As 'Diff_With Prev TK',"+OctDiffWithLastYearParcent+" As 'Diff_With Prev %',"+OctDiffWithTarget+" As 'Diff_With Target',"+OctDiffWithTagetParcent+" As 'Diff_with Target %' from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+"  "+Items+" "}


        const NovjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+","+Oct22+","+Nov22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+","+OctTgt+","+NovTgt+"" +
                ","+Jul23+","+Aug23+","+Sep23+","+Oct23+","+Nov23+","+NovPrevTotal+" As 'UpTo Prev', "+NovThisYearTotal+" As 'UpTO Now' , "+NovDiffWithLastYear+" As 'Diff_With Prev TK',"+NovDiffWithLastYearParcent+" As 'Diff_With Prev %',"+NovDiffWithTarget+" As 'Diff_With Target',"+NovDiffWithTagetParcent+" As 'Diff_with Target %' from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}

        const DecjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+","+Oct22+","+Nov22+","+Dec22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+","+OctTgt+","+NovTgt+","+DecTgt+"" +
                ","+Jul23+","+Aug23+","+Sep23+","+Oct23+","+Nov23+","+Dec23+","+DecPrevTotal+" As 'UpTo Prev', "+DecThisYearTotal+" As 'UpTO Now' , "+DecDiffWithLastYear+" As 'Diff_With Prev TK',"+DecDiffWithLastYearParcent+" As 'Diff_With Prev %',"+DecDiffWithTarget+" As 'Diff_With Target',"+DecDiffWithTagetParcent+" As 'Diff_with Target %' from  BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}




        const JanjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+","+Oct22+","+Nov22+","+Dec22+","+Jan22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+","+OctTgt+","+NovTgt+","+DecTgt+","+JanTgt+"" +
                ","+Jul23+","+Aug23+","+Sep23+","+Oct23+","+Nov23+","+Dec23+","+Jan23+","+JanPrevTotal+" As 'UpTo Prev', "+JanThisYearTotal+" As 'UpTO Now' , "+JanDiffWithLastYear+" As 'Diff_With Prev TK',"+JanDiffWithLastYearParcent+" As 'Diff_With Prev %',"+JanDiffWithTarget+" As 'Diff_With Target',"+JanDiffWithTagetParcent+" As 'Diff_with Target %'  from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}

        const FebjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+","+Oct22+","+Nov22+","+Dec22+","+Jan22+","+Feb22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+","+OctTgt+","+NovTgt+","+DecTgt+","+JanTgt+","+FebTgt+"" +
                ","+Jul23+","+Aug23+","+Sep23+","+Oct23+","+Nov23+","+Dec23+","+Jan23+","+Feb23+","+FebPrevTotal+" As 'UpTo Prev', "+FebThisYearTotal+" As 'UpTO Now' , "+FebDiffWithLastYear+" As 'Diff_With Prev TK',"+FebDiffWithLastYearParcent+" As 'Diff_With Prev %',"+FebDiffWithTarget+" As 'Diff_With Target',"+FebDiffWithTagetParcent+" As 'Diff_with Target %' from  BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}

        const MarjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+","+Oct22+","+Nov22+","+Dec22+","+Jan22+","+Feb22+","+Mar22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+","+OctTgt+","+NovTgt+","+DecTgt+","+JanTgt+","+FebTgt+"" +
                ","+MarTgt+","+Jul23+","+Aug23+","+Sep23+","+Oct23+","+Nov23+","+Dec23+","+Jan23+","+Feb23+","+Mar23+","+MarPrevTotal+" As 'UpTo Prev', "+MarThisYearTotal+" As 'UpTO Now' , "+MarDiffWithLastYear+" As 'Diff_With Prev TK',"+MarDiffWithLastYearParcent+" As 'Diff_With Prev %',"+MarDiffWithTarget+" As 'Diff_With Target',"+MarDiffWithTagetParcent+" As 'Diff_with Target %' from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}


        const AprjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+","+Oct22+","+Nov22+","+Dec22+","+Jan22+","+Feb22+","+Mar22+","+Apr22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+","+OctTgt+","+NovTgt+","+DecTgt+","+JanTgt+","+FebTgt+"" +
                ","+MarTgt+","+AprTgt+","+Jul23+","+Aug23+","+Sep23+","+Oct23+","+Nov23+","+Dec23+","+Jan23+","+Feb23+","+Mar23+","+Apr23+","+AprPrevTotal+" As 'UpTo Prev', "+AprThisYearTotal+" As 'UpTO Now' , "+AprDiffWithLastYear+" As 'Diff_With Prev TK',"+AprDiffWithLastYearParcent+" As 'Diff_With Prev %',"+AprDiffWithTarget+" As 'Diff_With Target',"+AprDiffWithTagetParcent+" As 'Diff_with Target %' from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}

        const MayjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+","+Oct22+","+Nov22+","+Dec22+","+Jan22+","+Feb22+","+Mar22+","+Apr22+","+May22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+","+OctTgt+","+NovTgt+","+DecTgt+","+JanTgt+","+FebTgt+"" +
                ","+MarTgt+","+AprTgt+","+MayTgt+","+Jul23+","+Aug23+","+Sep23+","+Oct23+","+Nov23+","+Dec23+","+Jan23+","+Feb23+","+Mar23+","+Apr23+","+May23+","+MayPrevTotal+" As 'UpTo Prev', "+MayThisYearTotal+" As 'UpTO Now' , "+MayDiffWithLastYear+" As 'Diff_With Prev TK',"+MayDiffWithLastYearParcent+" As 'Diff_With Prev %',"+MayDiffWithTarget+" As 'Diff_With Target',"+MayDiffWithTagetParcent+" As 'Diff_with Target %' from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}

        const JunjsonObject={"sql":"Select "+CircleName+","+BinName+","+Jul22+","+Aug22+","+Sep22+","+Oct22+","+Nov22+","+Dec22+","+Jan22+","+Feb22+","+Mar22+","+Apr22+","+May22+","+Jun22+"" +
                ","+JulTgt+","+AugTgt+","+SepTgt+","+OctTgt+","+NovTgt+","+DecTgt+","+JanTgt+","+FebTgt+"" +
                ","+MarTgt+","+AprTgt+","+MayTgt+","+JunTgt+","+Jul23+","+Aug23+","+Sep23+","+Oct23+","+Nov23+","+Dec23+","+Jan23+","+Feb23+","+Mar23+","+Apr23+","+May23+","+Jun23+","+JunPrevTotal+" As 'UpTo Prev', "+JunThisYearTotal+" As 'UpTO Now' , "+JunDiffWithLastYear+" As 'Diff_With Prev TK',"+JunDiffWithLastYearParcent+" As 'Diff_With Prev %',"+JunDiffWithTarget+" As 'Diff_With Target',"+JunDiffWithTagetParcent+" As 'Diff_with Target %' from BIN inner join Revenue21_22 on BIN.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Target.bin=Revenue22_23.bin  where 1=1 "+CircleCurrent+" "+Items+" "}




        let jsonObject=""
        if(UptoMonth==0){ jsonObject=JuljsonObject;}
        if(UptoMonth==1){ jsonObject=AugjsonObject;}
        if(UptoMonth==2){ jsonObject=SeptjsonObject;}
        if(UptoMonth==3){ jsonObject=OctjsonObject;}
        if(UptoMonth==4){ jsonObject=NovjsonObject;}
        if(UptoMonth==5){ jsonObject=DecjsonObject;}
        if(UptoMonth==6){ jsonObject=JanjsonObject;}
        if(UptoMonth==7){ jsonObject=FebjsonObject;}
        if(UptoMonth==8){ jsonObject=MarjsonObject;}
        if(UptoMonth==9){ jsonObject=AprjsonObject;}
        if(UptoMonth==10){ jsonObject=MayjsonObject;}
        if(UptoMonth==11){ jsonObject=JunjsonObject;}


        setData("")
        if(jsonObject==""){cogoToast.error("Please select Upto Month")}else {
            RestClient.PostRequest(AppUrl.getData, jsonObject).then(result => {
               if(result==""){cogoToast.error("Data not found");setBtnSearch("primary")}else{
                   setBtnSearch("primary")
                   setColumn(
                       Object.keys(result[0]).map((k) => {
                           return {
                               dataField: k, text: k, headerStyle: {
                                   backgroundColor: '#FFA07A'
                               }, sort: true
                           }
                       })
                   )
                   setData(result)
               }


            }).catch(error => {
                setBtnSearch("primary")
            });
        }









    }

    return(
        <Fragment>
            <Container className="purchaseDiv">



                    <Row className="mt-2">
                        <Col lg={1}><label className="text-danger w-100 fw-bold">Month</label></Col>
                        <Col lg={2}>
                            <select className="float-start w-100 customfontsize" id="cmbUptoMonth"  >
                                <option value="0">July</option>
                                <option value="0">August</option>
                                <option value="0">September</option>
                                <option value="0">October</option>
                                <option value="0">November</option>
                                <option value="0">December</option>
                                <option value="0">January</option>
                                <option value="0">February</option>
                                <option value="0">March</option>
                                <option value="0">April</option>
                                <option value="0">May</option>
                                <option value="0">June</option>

                            </select>
                        </Col>
                        <Col lg={1}><label className="text-danger w-100 fw-bold">Circle</label></Col>
                        <Col lg={2}>
                            <select className="float-start w-100 customfontsize" id="cmbCircleCurrent"  >
                                <option value="0">All Circle</option>
                                <option value="1">Circle-1</option>
                                <option value="2">Circle-2</option>
                                <option value="3">Circle-3</option>
                                <option value="4">Circle-4</option>
                                <option value="5">Circle-5</option>
                                <option value="6">Circle-6</option>

                            </select>
                        </Col>

                        <Col lg={2}>
                            <select className="float-start w-100 customfontsize" id="cmbItems"  >
                                <option value="0">All Items</option>
                                <option>Advertiseing</option>
                                <option>Bank</option>
                                <option>Beverage</option>
                                <option>Biscuit</option>
                                <option>BTRC</option>
                                <option>Cement</option>
                                <option>Cigeratte</option>
                                <option>Cosmetics</option>
                                <option>Coconut Oil</option>
                                <option>Dairy</option>
                                <option>Edibal Oil</option>
                                <option>Electical Goods</option>
                                <option>Electricity Distributor</option>
                                <option>Footware</option>
                                <option>Glassware</option>
                                <option>Hotel</option>
                                <option>Insurance</option>
                                <option>Izarader</option>
                                <option>Medicine</option>
                                <option>Mobile Phone</option>
                                <option>NG</option>
                                <option>Oxygen</option>
                                <option>Other Food</option>
                                <option>Paper</option>
                                <option>Packing materials</option>
                                <option>Paints</option>
                                <option>Security</option>
                                <option>Shoes</option>D
                                <option>Shoes</option>D
                                <option>Telephone</option>
                                <option>Telecom Equipment</option>
                                <option>Ceramic Tiles</option>
                                <option>Vegetable Oil</option>
                                <option>Water Supply</option>
                                <option>Warehouse</option>
                            </select>
                        </Col>
                        <Col lg={1}><Button onClick={getData} variant={BtnSearch} className="mb-2 w-100"> Search</Button></Col>

                        <Col lg={3}>

                            <Row className="m-2">
                                <Col lg={6}>
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="download-table-xls-button"
                                        table="tblSearch"
                                        filename="Order Report"
                                        sheet="tablexls"
                                        buttonText="Download"/>
                                </Col>
                                <Col lg={6}>
                                    <ReactToPrint   trigger={()=><Button className="w-100 " variant="danger">Print</Button>} content={()=>ref.current}  />
                                </Col>

                            </Row>

                        </Col>
                    </Row>
                <Row>
                    <div className="tableDiv tableDivBorder">
                        <BootstrapTable  ref={ref} id="tblSearch" keyField='id' data={Data}   columns={Column} > </BootstrapTable>
                    </div>

                </Row>







            </Container>
        </Fragment>
    )
}