class AppUrl {

    //static BaseUrlFarco='https://siteapi.farcochemical.com';
    static BaseUrl='https://ltu.farcochemical.com';

    static insertAccounts=this.BaseUrl+'/saveData';
    static getData=this.BaseUrl+'/getData';
    static insertStock=this.BaseUrl+'/saveData';
    static saveData=this.BaseUrl+'/saveData';
    static savesalesDemand=this.BaseUrl+'/saveData';
    static getAccount=this.BaseUrl+'/getAccount';


    static sendSms='https://bulksmsbd.net/api/smsapi';





    static getData=this.BaseUrl+'/getData';

    static getDistrict=this.BaseUrl+'/getData';
    static getUpazila=this.BaseUrl+'/getData';
    static getUnion=this.BaseUrl+'/getData';
    static getBazar=this.BaseUrl+'/getData';

    static inserBazar=this.BaseUrl+'/saveData';
    static insertCustomer=this.BaseUrl+'/saveData';
    static insertOrder=this.BaseUrl+'/saveData';
    static getCustomerByDistrict=this.BaseUrl+'/getData';
    static customerUpdate=this.BaseUrl+'/updateData';
    static updateData=this.BaseUrl+'/updateData';
    static deleteData=this.BaseUrl+'/deleteData';





}
export default AppUrl

