import {Fragment, useEffect, useState} from "react";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Col, Container, Row} from "react-bootstrap";

export default function UnitProfileCoEfficient(props){
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const[CoBinName,setCoBinName]=useState()
    useEffect(()=>{
        setDataCoEffeciant([])
        setCoBinName(props.bin)

    },[props.bin]);
const get4_3=()=>{

    if(CoBinName=="All BIN"){cogoToast.error("Please select BIN")}else{

        const AdditionParcen=document.getElementById("txtAdditionParcen").value;
        const AddCompare=document.getElementById("cmbAddCompare");
        let getAddParcent=""
        if(AddCompare.selectedIndex == 1){getAddParcent =" And Addition_Parcentage >"+AdditionParcen+" ";
            if(AdditionParcen==""){cogoToast.error("Please insert value")}
        }
        if(AddCompare.selectedIndex == 2){getAddParcent =" And Addition_Parcentage <"+AdditionParcen+" ";
            if(AdditionParcen==""){cogoToast.error("Please insert value")}
        }

        let jsonObject={"sql":"SELECT ID,Description As Description_Of_Goods,Supply_Qty AS Qty,Supply_Unit As Unit,Rm_Price,Addition_Price,Addition_Parcentage AS 'Add_%',Total_Price,Entry_Date As 'Effect_Date',DATEDIFF(CURDATE(),Entry_Date ) AS Total_Days,Status,Sale_Category,Comments As Comments_for_goods FROM `Co_Efficient` where BIN='"+CoBinName+"' and DATEDIFF( CURDATE(),Entry_Date) >=0 "+getAddParcent+" "}
        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);

            }
        }).catch(error=>{});
    }
}

    return(
        <Fragment>
            <Container className="">
                <Row>
                    <Col lg={7}></Col>
                    <Col lg={2}>
                        <select  className="form-control form-control-sm form-select" id="cmbAddCompare">
                            <option>All Addition</option>
                            <option>More than</option>
                            <option>Less than</option>

                        </select>

                    </Col>
                    <Col lg={1}><input type="text" className="w-100 h-100" id="txtAdditionParcen"/></Col>
                    <Col lg={2}>

                        <Button variant="success" onClick={get4_3}>Load Data</Button>
                    </Col>

                </Row>

                <div className="overflow-scroll animated zoomIn purchaseMediumDiv"><BootstrapTable   keyField='ID' data={DataCoEffeciant} columns={ColumnCoEfficient}   > </BootstrapTable></div>

            </Container>

        </Fragment>


    );
}