import {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";


export default function CigaretteeDashboard(){
    const [hideDirector, setHideDirector] = useState(false);


    let state=useSelector((state)=> state);
    const [Data, setData]=useState([{}]);
    const [DataSupremeCourt, setDataSupremeCourt]=useState();
    const [DataHighCourt, setDataHighCourt]=useState();
    const [DataTribunal, setDataTribunal]=useState();
    const [DataAppeal, setDataAppeal]=useState();
    const [DataDetailes, setDataDetailes]=useState();
    const [DataAdr, setDataAdr]=useState();
    const [DataStay, setDataStay]=useState();
    const [DataJudgement, setDataJudgement]=useState();
    const [Column, setColumn]=useState([{}]);
    const [MamalNo,setMamlaNo]=useState();
    const [DataMsg,setDataMsg]=useState();
    const [ColumnAllMamla, setColumnAllMamla]=useState([{dataField: 'k', text: 'All mamla list', sort: true}]);
    const [DataAllMamla, setDataAllMamla]=useState([{}]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

        useEffect(()=>{

            setColumn(Object.keys(state.c_balance[0]).map((k) => {return  {dataField: k, text: k, sort: true} }));

            setData(state.c_balance);
            setColumnAllMamla(Object.keys(state.all_mamla[0]).map((k) => {return {dataField: k, text: k, sort: true}}))
             setDataHighCourt(state.all_mamla.filter(x=>x.Court=="High Court").length)
              setDataSupremeCourt(state.all_mamla.filter(x=>x.Court=="Supreme Court").length)
              setDataTribunal(state.all_mamla.filter(x=>x.Court=="Tribunal").length)
             setDataAppeal(state.all_mamla.filter(x=>x.Court=="Appeal").length)

             setDataAdr(state.all_mamla.filter(x=>x.Status=="ADR").length)
             setDataStay(state.all_mamla.filter(x=>x.Status=="Stay").length)
             setDataJudgement(state.all_mamla.filter(x=>x.Status=="Judgement").length)

        },[])



    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{


            RestClient.PostRequest(AppUrl.getData,{"sql":"Select Detailes from Mamla_Detailes where Mamla_No='"+row['Mamla_No']+"' "}).then(result=>{
                        if(result==""){cogoToast.error("No Data found")}else{

                setDataDetailes(result[0]["Detailes"])
                handleShow()}

            }).catch(error=>{ cogoToast.error(error)});


        }
    }

    const getAllMamlaArray=async(event)=>{

        let dataType=event.target.value;

         setDataAllMamla([]);

        setDataAllMamla(state.all_mamla.filter(x=>x.Court==dataType));
        cogoToast.success("Data found "+state.all_mamla.filter(x=>x.Court==dataType).length);


    }
    const getAllMamlaStatus=async(event)=>{
         setDataAllMamla([]);

     setDataAllMamla(state.all_mamla.filter(x=>x.Status==event.target.value));

       await cogoToast.success("Data found "+state.all_mamla.filter(x=>x.Status==event.target.value).length);

    }
    const getAllMamlaNo=async()=>{

            const mamlaNo=document.getElementById("txtmamlaNo").value;

            if(mamlaNo==""){cogoToast.error("Please insert mamla no")}else {
                 setDataAllMamla([]);
                 setDataAllMamla(state.all_mamla.filter(x => x.Mamla_No == mamlaNo));
                await cogoToast.success("Data found " + state.all_mamla.filter(x => x.Mamla_No == mamlaNo).length);
            }

    }


    return(
        <Fragment>
            <Container className="animated zoomIn text-center">
                <Row>

                        <Row>
                            <div className="purchaseSmallDiv" >
                                <Card.Header className="text-danger">Balance of Revenue</Card.Header>

                                <BootstrapTable  keyField='Rebate' data={Data} columns={Column}  > </BootstrapTable>

                            </div>
                        </Row>


                        <Row>
                            <Col lg={3}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Low Segment</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="High Court" onClick={(event)=> getAllMamlaArray(event)}>{DataHighCourt}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header >Medium</Card.Header>
                                    <Card.Body>
                                        <label>Opening</label><br/>
                                         <label>Closing</label><br/>
                                         <label>Used</label>
                                     </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>High</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="Tribunal" onClick={(event)=> getAllMamlaArray(event)}>{DataTribunal}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Premium</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="Appeal" onClick={(event)=> getAllMamlaArray(event)}>{DataAppeal}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg={3}>
                            <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                <Card.Header>Stay Case</Card.Header>
                                <Card.Body>
                                    <Button className="w-100" value="Stay" onClick={(event)=> getAllMamlaStatus(event)}>{DataStay}</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={3}>
                            <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                <Card.Header >ADR</Card.Header>
                                <Card.Body>
                                    <Button className="w-100" value="ADR" onClick={(event)=> getAllMamlaStatus(event)}>{DataAdr}</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={3}>
                            <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                <Card.Header>Judgement</Card.Header>
                                <Card.Body>
                                    <Button className="w-100" value="Judgement" onClick={(event)=> getAllMamlaStatus(event)}>{DataJudgement}</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={3}>
                            <Card>
                            <Card.Header><Button variant="success" onClick={getAllMamlaNo}>Find Case by Mamla No</Button><br/></Card.Header>
                            <label>Mamla No :</label><input id="txtmamlaNo" type="text"/>
                            </Card>

                        </Col>
                    </Row>





                </Row>
                <Row>
                    <div className="purchaseSmallDiv ">

                        <BootstrapTable selectRow={selectRow}  keyField='Mamla_No' data={DataAllMamla} columns={ColumnAllMamla}  > </BootstrapTable>

                    </div>

                </Row>

            </Container>
            <Modal animation={false} className="animated zoomIn" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{}</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label>{DataDetailes}</label>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}