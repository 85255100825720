import {Fragment, useEffect, useState} from "react";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Container} from "react-bootstrap";

export default function UnitProfileRevenue(props){
    const [Data, setData]=useState([]);
    const [DataLastThree, setDataLastThree]=useState([]);
    const [DataCurrent, setDataCurrent]=useState([]);
    const [DataTarget, setDataTarget]=useState([]);
    const [Column, setColumn]=useState([{}]);
    const [ColumnTarget, setColumnTarget]=useState([{}]);
    const [ColumnLastThree, setColumnLastThree]=useState([{}]);
    const [ColumnCurrent, setColumnCurrent]=useState([{}]);
    useEffect(()=>{

        setData([])
        setDataCurrent([])
        setDataTarget([])
        setDataLastThree([])



    },[props.bin]);

const getCurrent=()=>{
    let jsonObjectCurrent={"sql":"Select July,August,September,October,November,December,January,February,March,April,May,June  from Revenue22_23 where Name='"+props.bin+"' "}
    RestClient.PostRequest(AppUrl.getData,jsonObjectCurrent).then(async result=>{
        if(result==""){cogoToast.error("Data not found")}else {
            await setColumnCurrent(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            await setDataCurrent(result);

        }
    }).catch(error=>{});
}
    const getTarget=()=>{
        let jsonObjectTarget={"sql":"Select July,August,September,October,November,December,January,February,March,April,May,June  from Target where Name='"+props.bin+"' "}
        RestClient.PostRequest(AppUrl.getData,jsonObjectTarget).then(async result=>{
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumnTarget(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataTarget(result);

            }
        }).catch(error=>{});
    }
const getThreeYears=()=>{
    let jsonObjectLastThree={"sql":"Select *  from Previouse_Revenue where Name='"+props.bin+"' "}
    RestClient.PostRequest(AppUrl.getData,jsonObjectLastThree).then(async result=>{
        if(result==""){cogoToast.error("Data not found")}else {
            await setColumnLastThree(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            await setDataLastThree(result);

        }
    }).catch(error=>{});
}
const getPreviousYears=()=>{
        let jsonObjectLastThree={"sql":"Select *  from Previouse_Revenue where Name='"+props.bin+"' "}
        RestClient.PostRequest(AppUrl.getData,jsonObjectLastThree).then(async result=>{
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setData(result);

            }
        }).catch(error=>{});
    }
    return(
        <Fragment>
            <Container className="animated zoomIn">
            <Button  onClick={getCurrent} variant="danger">Current Revenue</Button>
            <div className="overflow-scroll"><BootstrapTable   keyField='Name' data={DataCurrent} columns={ColumnCurrent}   > </BootstrapTable></div>
                <Button  onClick={getTarget} variant="secondary">Target</Button>
                <div className="overflow-scroll"><BootstrapTable   keyField='Name' data={DataTarget} columns={ColumnTarget}   > </BootstrapTable></div>

                <Button onClick={getThreeYears} variant="primary">Last three years</Button>
            <div className="overflow-scroll"> <BootstrapTable  keyField='Name' data={DataLastThree} columns={ColumnLastThree}   > </BootstrapTable></div>
            <Button onClick={getPreviousYears} variant="success">Revenue from 2004</Button>
            <div className="overflow-scroll"> <BootstrapTable  keyField='Name' data={Data} columns={Column}   > </BootstrapTable></div>

            </Container>

        </Fragment>


    );
}