import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";

export default function CigaretteeReport(){

    const[BtnMamlaReport,setBtnMamlaReport]=useState("primary")
    let state=useSelector((state)=> state);
    const ref=useRef();
    const value1Ref=useRef(false);
    const value2Ref=useRef(false);
    const [Data, setData]=useState([]);
    const [FoundMsg, setFoundMsg]=useState(0);
    const [Column, setColumn]=useState([{}]);

    const [DataUpdate, setDataUpdate]=useState();
    const [BinName, setBinName]=useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const getData=()=>{
        setBtnMamlaReport("secondary")
        const cmbCategory=document.getElementById("cmbStickCategory")

        const cmbName=document.getElementById("cmbMamlaBinName")
        const BinName=cmbName.options[cmbName.selectedIndex].text;

        const cmbYear=document.getElementById("cmbStickYear")
        const YearValue=cmbYear.options[cmbYear.selectedIndex].text;

        
        const cmbSegment=document.getElementById("cmbSegment")
        const Segemnt=cmbSegment.options[cmbSegment.selectedIndex].text;
        
       
        

        let getBin=""
        let getYear=""
      
        let getSegment=""
       

        if(cmbSegment !=0){}

        if(cmbName.selectedIndex !=0){getBin=" And Name='"+BinName+"'"}
        if(cmbYear.selectedIndex !=0){getYear=" And Stick_Year='"+YearValue+"'"}
        
        if(cmbSegment.selectedIndex !=0){getSegment=" And Segment='"+Segemnt+"'"}

        let July_Data="Round(July/1000000,2) As July"
        let August_Data="Round((August/1000000),2)as August, Round((August-July)/1000000,2) AS Aug_Growth,Round(((August-July)*100)/July,2)  As 'Aug_Growth%'"
        let September_Data="Round((September/1000000),2)as September,Round((September-August)/1000000,2) AS Sept_Growth,Round(((September-August)*100)/August,2)  As 'Sept_Growth%'"
        let October_Data="Round((October/1000000),2)as October,Round((October-September)/1000000,2) AS Oct_Growth,Round(((October-September)*100)/September,2)  As 'Oct_Growth%'"
        let November_Data="Round((November/1000000),2)as November,Round((November-October)/1000000,2) AS Nov_Growth,Round(((November-October)*100)/October,2)  As 'Nov_Growth%'"
        let December_Data="Round((December/1000000),2)as December,Round((December-November)/1000000,2) AS Dec_Growth,Round(((December-November)*100)/November,2)  As 'Dec_Growth%'"
        let January_Data="Round((January/1000000),2)as January,Round((January-December)/1000000,2) AS Jan_Growth,Round(((January-December)*100)/December,2)  As 'Jan_Growth%'"
        let February_Data="Round((February/1000000),2)as February,Round((February-January)/1000000,2) AS Feb_Growth,Round(((February-January)*100)/January,2)  As 'Feb_Growth%'"
        let March_Data="Round((March/1000000),2)as March,Round((March-February)/1000000,2) AS Mar_Growth,Round(((March-February)*100)/February,2)  As 'Mar_Growth%'"
        let April_Data="Round((April/1000000),2)as April,Round((April-March)/1000000,2) AS Apr_Growth,Round(((April-March)*100)/March,2)  As 'Apr_Growth%'"
        let May_data="Round((May/1000000),2)as May,Round((May-April)/1000000,2) AS May_Growth,Round(((May-April)*100)/April,2)  As 'May_Growth%'"
        let June_Data="Round((June/1000000),2)as June,Round((June-May)/1000000,2) AS Jun_Growth,Round(((June-May)*100)/May,2)  As 'Jun_Growth%'"

        let jsonObj={
            "sql":"SELECT Name AS 'Name_of_Industries',Stick_Year,Segment,"+July_Data+","+August_Data+","+September_Data+","+October_Data+","+November_Data+","+December_Data+","+January_Data+","+February_Data+","+March_Data+","+April_Data+","+May_data+","+June_Data+" FROM `Cigarettee_Stick` where 1=1 "+getBin+"   "+getSegment+" "+getYear+""
        }
        let jsonObjSum={"sql":"Select '"+YearValue+"','"+BinName+"','"+Segemnt+"',Round(sum(JUly)/1000000,2)As July,Round(sum(August)/1000000,2)As August,Round(sum(September)/1000000,2)As September,Round(sum(October)/1000000,2)As October,Round(sum(November)/1000000,2)As November,Round(sum(December)/1000000,2)As December,Round(sum(January)/1000000,2)As January,Round(sum(February)/1000000,2)As February,Round(sum(March)/1000000,2)As March,Round(sum(April)/1000000,2)As April,Round(sum(May)/1000000,2)As May,Round(sum(June)/1000000,2)As June,Round(sum(Total)/1000000,2)As Total from Cigarettee_Stick  where 1=1 "+getBin+"   "+getSegment+" "+getYear+"  "}
        
            
        let jsonObjGroup={"sql":"SELECT * FROM Cigarettee_Stick group by Segment"}

        let jsonObjOnlyMonth={"sql":"Select Name,Segment,Stick_Year,Round(July/1000000,2)As July,Round(August/1000000,2)As August,Round(September/1000000,2)As September,Round(October/1000000,2)As October,Round(November/1000000,2)As November,Round(December/1000000,2)As December,Round(January/1000000,2)As January,Round(February/1000000,2)As February,Round(March/1000000,2)As March,Round(April/1000000,2)As April,Round(May/1000000,2)As May,Round(June/1000000,2)As June,Round(Total/1000000,2)As Total from Cigarettee_Stick where 1=1 "+getBin+" "+getYear+" "+getSegment+" "}
        
        let jsonObjMonthlyRevenue={"sql":"SELECT * FROM `cigarette_month_revenue` where 1=1 "+getBin+" "+getYear+"  "}

        let jsonObjYearRevenue={"sql":"SELECT * FROM `cigarettee_year_revenue` where 1=1 "+getYear+""}

        let getSql=jsonObjOnlyMonth
        if(cmbCategory.selectedIndex==1){getSql=jsonObj}
        if(cmbCategory.selectedIndex==2){getSql=jsonObjOnlyMonth}
        if(cmbCategory.selectedIndex==3){getSql=jsonObjYearRevenue}
        
        setData([])
        RestClient.PostRequest(AppUrl.getData, getSql).then(async result => {

            await setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            await setData(result);
            cogoToast.success("Data found " + result.length);
            setFoundMsg(result.length)
            setBtnMamlaReport("primary")

        }).catch(error => { setBtnMamlaReport("primary")
        });

    }


    return(
        <Fragment>
            <Container className="animated zoomIn">
                <Row>
                    <Col lg={1} >
                    <Row className="mt-2">
                           
                           <Col lg={12} >
                               <select className=" customfontsize" id="cmbStickCategory"  >
                                   <option>Monthly Stick</option>
                                   <option>Growth wise Stick </option>
                                   <option>Monthly Revenue </option>
                                   <option>Yearly Revenue </option>
                                   
                               </select>
                           </Col>

                       </Row>
                       
                        <Row className="mt-2">
                           
                            <Col lg={12} >
                                <select className=" customfontsize" id="cmbStickYear"  >
                                    <option>All_Year</option>
                                    <option>22_23</option>
                                    <option>21_22</option>
                                    <option>20_21</option>
                                    <option>19_20</option>
                                    
                                </select>
                            </Col>

                        </Row>
                      
                        <Row className="mt-2">
                           
                            <Col lg={12}>
                                <select className=" customfontsize" id="cmbMamlaBinName"  >
                                    <option >All_BIN</option>
                                    <option>BATB Mohakhali</option>
                                     <option>BATB Savar</option>
                                    <option>JTI</option>


                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                           
                            <Col lg={12} >
                                <select className=" customfontsize" id="cmbSegment"  >
                                    <option >All_Segment</option>
                                    <option>Premium</option>
                                     <option>High</option>
                                    <option>Medium</option>
                                    <option>Low</option>


                                </select>
                            </Col>

                        </Row>
                       
                       
                        
                        <Row className="mt-2"> <Button onClick={getData} variant={BtnMamlaReport} className=" m-2 w-100"> Search</Button></Row>
                        <Row className="mt-2">
                            <Col lg={12}>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="tblSearch"
                                    filename="Order Report"
                                    sheet="tablexls"
                                    buttonText="Download as XLS"/>
                            </Col>
                            <Col lg={12}>
                                <ReactToPrint   trigger={()=><Button className="w-100 mt-2 "  variant="danger">Print</Button>} content={()=>ref.current}  />
                            </Col>

                        </Row>

                    </Col>
                    <Col lg={11}>
                        <div className="tableDiv tableDivBorder">
                            <BootstrapTable ref={ref} id="tblSearch" keyField='Id' data={Data} columns={Column}  > </BootstrapTable>

                        </div>
                    </Col>

                </Row>
                <Row> <h3 className="text-danger">Data found: {FoundMsg}</h3></Row>


            </Container>
            <Modal animation={false} className="animated zoomIn" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{BinName}</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label>{DataUpdate}</label>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}