import {Fragment, useEffect, useState} from "react";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Col, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";

export default function UnitProfileComments(props){

    let state=useSelector((state)=> state);
    const [Data, setData]=useState([]);
    const[Show,setShow]=useState();
    const handleClose=()=>{

        setShow(false)
    }
    const handleOpen=()=>{
        setShow(true)
    }

useEffect((props)=>{
    setData([])
},[props.bin])
const getComments=()=>{
    let jsonObjectPrevious={"sql":"SELECT * FROM `Unit_Profile_Comments` where Name='"+props.bin+"'"}
    if(props.bin=="All BIN"){cogoToast.error("Please select BIN")}else {
        RestClient.PostRequest(AppUrl.getData, jsonObjectPrevious).then(async result => {
            if (result == "") {
                cogoToast.error("Data not found")
            } else {
                setData(result)

            }
        }).catch(error => {
        });
    }
}
    const saveData=()=>{
        const UnitComments=document.getElementById("textAreaComments").value;
        let jsonObject={"sql":"INSERT INTO `Unit_Profile_Comments` (`Id`, `Name`, `Comments`, `Entry_Date`, `Entry_User`)" +
                " VALUES (NULL, '"+props.bin+"', '"+UnitComments+"',current_timestamp(), '"+state.name+"')"}
       if(UnitComments==""){cogoToast.error("Please insert comments")}else {
           RestClient.PostRequest(AppUrl.saveData, jsonObject).then(async result => {
               if (result == "") {
                   cogoToast.error("Data not found")
               } else {
                   cogoToast.success("Data saved successfully")

               }
           }).catch(error => {
           });
       }

    }

    return(
        <Fragment>
            <Button className="float-start circular-btn" onClick={getComments}><i className="fa fa-plus"/></Button>
            <button onClick={handleOpen} className="float-end circular-btn"><i className="fa fa-plus"/></button>

            <br/>
            <div className="purchaseDiv overflow-scroll animated zoomIn">
            {



                Data.map((ctr,index)=>{
                return (

                   <><label>{ctr.Comments}</label><h5 className="text-danger">{ctr.Entry_Date}</h5></>
                )
            })
            }
            </div>
            <Modal animation={false} className="animated zoomIn" show={Show} onHide={handleClose}>
                <Modal.Header>
                    <h6>{props.bin}</h6>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label">Comments</label>
                    <textarea id="textAreaComments" className="form-control form-control-sm"></textarea>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-sm btn-site" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <button className="btn btn-sm btn-site"  onClick={saveData}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>


    );
}