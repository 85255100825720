import {Fragment} from "react";
import Revenue from "../components/Revenue/Revenue";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import RevenueAnalysis from "../components/Revenue/RevenueAnalysis";
import RevenueCompare from "../components/Revenue/RevenueCompare";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import RevenueEntry from "../components/Revenue/RevenueEntry";
import Hscode from "../components/Revenue/Hscode";
import CurrentRevenue from "../components/Revenue/CurrentRevenue";
import PreviousYear from "../components/Revenue/PreviousYear";
import CoEfficientEntry from "../components/UnitProfile/CoEfficientEntry";
import LPEntry from "../components/Revenue/LPEntry";
export default function RevenuePage(){



    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center">

                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Current Revenue"><CurrentRevenue/></Tab>
                    <Tab eventKey="revenue" title="Revenue Entry"><RevenueEntry/></Tab>
                    <Tab eventKey="previous" title="Three Year analysis"><RevenueAnalysis/></Tab>
                    <Tab eventKey="analysys" title="Three Month"><Revenue/></Tab>
                    <Tab eventKey="compare" title="Compare"><RevenueCompare/></Tab>
                    <Tab eventKey="previousYears" title="Previous Revenue"><PreviousYear/></Tab>
                    <Tab eventKey="hscode" title="Hscode"><Hscode/></Tab>
                    <Tab eventKey="coefficient" title="4.3"><CoEfficientEntry/></Tab>
                    <Tab eventKey="lp" title="LP"><LPEntry/></Tab>

                </Tabs>
            </div>
        </Fragment>
    )
}
