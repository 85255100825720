import {Fragment, useState} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Button, Card, Col, Container, ListGroup, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../RestAPI/RestClient";
import AppUrl from "../RestAPI/AppUrl";
import UnitProfileRevenue from "../components/UnitProfile/UnitProfileRevenue";
import UnitProfileMamla from "../components/UnitProfile/UnitProfileMamla";
import UnitProfileAudit from "../components/UnitProfile/UnitProfileAudit";
import UnitProfileCoEfficient from "../components/UnitProfile/UnitProfileCoEfficient";
import UnitProfileComments from "../components/UnitProfile/UnitProfileComments";
import UnitProfileLP from "../components/UnitProfile/UnitProfileLP";
export default function UnitProfilePage(){
    let state=useSelector((state)=> state);
    const[BinName,setBinName]=useState("No Name found");
    const[BtnSearch,setBtnSearch]=useState("primary");

    const[AllData,setAllData]=useState([{}]);

    const getBinName=()=>{
        const BinNameIndex=document.getElementById("cmbBinNameUnit");
        const BinNameValue=BinNameIndex.options[BinNameIndex.selectedIndex].text;


        let jsonObject={"sql":"Select *  from IVAS_BIN where Name='"+BinNameValue+"' "}
        setBtnSearch("secondary")
        setAllData([{}])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            if(result==""){cogoToast.error("Data not found");setBinName([]);setBtnSearch("primary")}else {
                        cogoToast.success("Data found");setBtnSearch("primary")
                setBinName(result[0]["Name"])

                setAllData(result)



            }
        }).catch(error=>{
            setBtnSearch("primary")
        });
    }

    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center">
                <div >
                    <Row className="mt-2">
                        <Col lg={1}><label className="text-danger fw-bold">BIN</label></Col>
                        <Col lg={3}>
                            <select className="float-start w-100 customfontsize" id="cmbBinNameUnit"  >
                                <option >All BIN</option>
                                {

                                    state.bin.map((ctr,index)=>{
                                        return (
                                            <option  >{ctr.Name}</option>
                                        )
                                    })
                                }


                            </select>
                        </Col>
                        <Col lg={1}> <Button variant={BtnSearch} onClick={getBinName}>Search</Button></Col>
                    </Row>

                </div>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Profile">
                        <div className="purchaseMediumDiv overflow-scroll animated zoomIn">
                        <Row>
                            {

                                Object.keys(AllData[0]).map((k) => {

                                    return <Col lg={3}>
                                        <Card className="m-2 text-justify  overflow-hidden" style={{ height:'8rem' }}>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item className="text-danger fs-5  ">{k}</ListGroup.Item>
                                                <ListGroup.Item className="mt-1"> {AllData[0][k]}</ListGroup.Item>

                                            </ListGroup>
                                        </Card>
                                    </Col>
                                })


                            }
                        </Row>
                        </div>
                    </Tab>
                    <Tab eventKey="revenue" title="Revenue"><UnitProfileRevenue bin={BinName}/></Tab>
                    <Tab eventKey="mamla" title="Mamla"><UnitProfileMamla bin={BinName}/></Tab>
                    <Tab eventKey="audit" title="Audit"><UnitProfileAudit bin={BinName}/></Tab>
                    <Tab eventKey="4.3" title="4.3"><UnitProfileCoEfficient bin={BinName}/></Tab>
                    <Tab eventKey="lp" title="LP"><UnitProfileLP bin={BinName}/></Tab>
                    <Tab eventKey="comments" title="Comments"><UnitProfileComments bin={BinName} /></Tab>



                </Tabs>
            </div>
        </Fragment>
    )
}
