import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import {useSelector} from "react-redux";
export default function RevenueCompare(){
    let state=useSelector((state)=> state);
    const ref=useRef();

    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);

const [CompValue1,setCompValue1]=useState(false)
const [CompValue2,setCompValue2]=useState(false)


    const getData=()=>{
        const Month2021=document.getElementById("cmb2021");
        const Month2021Value=Month2021.options[Month2021.selectedIndex].text



        const CircleIndexCompare=document.getElementById("cmbCircleCompare");
        const CircleIndexCompareValue=CircleIndexCompare.options[CircleIndexCompare.selectedIndex].text;
        const CompareIndex=document.getElementById("cmbCompare").selectedIndex;

        const CompareValue1=document.getElementById("txtCompareValue1").value;
        const CompareValue2=document.getElementById("txtCompareValue2").value;


        let CircleCompare="";
        let Compare="";


        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CircleCompare="and BIN.circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndexCompare.selectedIndex !=0){CircleCompare="and BIN.circle='"+CircleIndexCompareValue+"'";}

            }
        }


        if(CompareIndex==1){Compare="and Revenue20_21.july <="+CompareValue1+" ";}
        if(CompareIndex==2){Compare="and Revenue20_21.july >="+CompareValue1+" ";}
        if(CompareIndex==3){Compare="and Revenue20_21.july Between "+CompareValue1+" and "+CompareValue2+" ";}



        let jsonObject={"sql":"Select  BIN.circle As Circle_Name,BIN.name As Name_OF_The_Industries," +
                "Revenue20_21.july As July_21,Revenue21_22.july As July_22,Revenue22_23.july As July_23 ,Revenue20_21.august As August_21,Revenue21_22.august As August_22,Revenue22_23.august As August_23,Revenue20_21.september As Sept_21,Revenue21_22.september As Sept_22,Revenue22_23.september As Sept_23," +
                "Revenue20_21.october As Oct_21,Revenue21_22.october As Oct_22,Revenue22_23.october As Oct_23,Revenue20_21.november As Nov_21,Revenue21_22.november As Nov_22,Revenue22_23.november As Nov_23," +
                "Revenue20_21.december As Dec_21,Revenue21_22.december As Dec_22,Revenue22_23.december As Dec_23,Revenue20_21.january As Jan_21,Revenue21_22.january As Jan_22,Revenue22_23.january As Jan_23," +
                "Revenue20_21.february As Feb_21,Revenue21_22.february As feb_22,Revenue22_23.february As feb_23,Revenue20_21.march As Mar_21,Revenue21_22.march As Mar_22,Revenue22_23.march As Mar_23," +
                "Revenue20_21.april As Apr_21,Revenue21_22.april As Apr_22,Revenue22_23.april As Apr_23,Revenue20_21.may As May_21,Revenue21_22.may As May_22,Revenue22_23.may As May_23,Revenue20_21.june As Jun_21, Revenue21_22.june As Jun_22,Revenue22_23.june As Jun_23 from BIN inner join Revenue20_21 on BIN.bin=Revenue20_21.bin  inner join Revenue21_22 on Revenue20_21.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin Where 1=1 "+CircleCompare+" "+Compare+" "}


        setData("")

        if(CompareIndex==0){
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                setColumn(
                    Object.keys(result[0]).map((k)=>{
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setData(result)
            }).catch(error=>{

            });
        }else{

            if(CompareIndex==3){

                if(CompareValue1==""){cogoToast.error("Please insert Value1")}else{
                if(CompareValue2==""){cogoToast.error("Please insert Value2")}else{
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        setColumn(
                            Object.keys(result[0]).map((k)=>{
                                return {dataField: k, text: k, sort: true}
                            })
                        )
                        setData(result)
                    }).catch(error=>{

                    });
                }}
            }else{


                if(CompareValue1==""){cogoToast.error("Please insert Value")}else{
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        setColumn(
                            Object.keys(result[0]).map((k)=>{
                                return {dataField: k, text: k, sort: true}
                            })
                        )
                        setData(result)
                    }).catch(error=>{

                    });
                }
            }



        }





    }

    return(
        <Fragment>
            <Container className="purchaseDiv">


                    <Row className="mt-2">
                        <Col lg={1}><label className="text-danger fw-bold">2022-23</label></Col>
                        <Col lg={1}>
                        <select className="float-start w-100 customfontsize" id="cmb2021"  >
                            <option value="0">Jul</option>
                            <option value="0">Aug</option>
                            <option value="0">Sept</option>
                            <option value="0">Oct</option>
                            <option value="0">Nov</option>
                            <option value="0">Dec</option>
                            <option value="0">Jan</option>
                            <option value="0">Feb</option>
                            <option value="0">Mar</option>
                            <option value="0">Apr</option>
                            <option value="0">May</option>
                            <option value="0">Jun</option>
                            <option value="0">Total</option>
                        </select>
                        </Col>
                        <Col lg={3}>
                            <Row>
                                <Col lg={5}><label className="text-danger fw-bold">Compare</label></Col>
                                <Col lg={7}>
                                    <select className="float-start w-100 customfontsize" id="cmbCompare" onChange={(event)=>{

                                        if( event.target.options.selectedIndex==0){ setCompValue1(true) ; setCompValue2(true)}else{ setCompValue1(false);  setCompValue2(true)}
                                        if( event.target.options.selectedIndex==3){ setCompValue1(false) ; setCompValue2(false)}

                                    }
                                    }  >
                                        <option value="0">No Compare</option>
                                        <option value="0">Less than</option>
                                        <option value="0">Greater than</option>
                                        <option value="0">Between</option>
                                    </select>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3}>
                            <Row>
                                <Col lg={3}><label className="text-danger  fw-bold">Value-1</label></Col>
                                <Col lg={3}>
                                    <input type="text" id="txtCompareValue1" className="w-100" disabled={CompValue1}/>
                                </Col>
                                <Col lg={3}><label className="text-danger fw-bold">Value-2</label></Col>
                                <Col lg={3}>
                                    <input type="text" id="txtCompareValue2" className="w-100" disabled={CompValue2}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={1}><label className="text-danger fw-bold">Circle</label></Col>
                        <Col lg={2}>
                            <select className="float-start w-100 customfontsize" id="cmbCircleCompare"  >
                                <option value="0">All</option>
                                <option value="1">Circle-1</option>
                                <option value="2">Circle-2</option>
                                <option value="3">Circle-3</option>
                                <option value="4">Circle-4</option>
                                <option value="5">Circle-5</option>
                                <option value="6">Circle-6</option>

                            </select>
                        </Col>
                        <Col lg={1}>
                            <Button onClick={getData} className="mb-1 w-100"> Search</Button>
                        </Col>

                    </Row>







                <Row>
                    <div className="tableDiv tableDivBorder">
                        <BootstrapTable  keyField='id' ref={ref} id="tblSearch" data={Data} columns={Column} > </BootstrapTable>

                    </div>
                    <Row className="m-2">
                        <Col lg={3}>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="tblSearch"
                                filename="Order Report"
                                sheet="tablexls"
                                buttonText="Download as XLS"/>
                        </Col>
                        <Col lg={2}>
                            <ReactToPrint   trigger={()=><Button className="w-100 " variant="danger">Print</Button>} content={()=>ref.current}  />
                        </Col>

                    </Row>
                </Row>



            </Container>
        </Fragment>
    )
}