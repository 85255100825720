import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CaseEntry from "../components/Law/CaseEntry";
import AuditEntry from "../components/Audit/AuditEntry";
import AuditDashboard from "../components/Audit/AuditDashboard";
import NewAuditEntry from "../components/Audit/NewAuditEntry";

export default function AuditPage(){



    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center">

                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Dashboard"><AuditDashboard/></Tab>
                    <Tab eventKey="CaseEntry" title="Audit Entry"><AuditEntry/></Tab>
                    <Tab eventKey="NewAudit" title="New Audit Entry"><NewAuditEntry/></Tab>


                </Tabs>
            </div>
        </Fragment>
    )
}
