import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Setting from "../components/Setting/Setting";

export default function SettingPage(){

    return (

        <Fragment>
            <TopNavigation />
            <Setting/>
        </Fragment>
    );
}