import {Fragment} from "react";

import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Password from "./Password";


export default function Profile(){

    return(
        <Fragment>
            <div className="purchaseDiv text-center">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>
                <h3>User Profile</h3>
                <hr/>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Home">

                    </Tab>
                    <Tab eventKey="profile" title="Profile">

                    </Tab>
                    <Tab eventKey="contact" title="Contact" >

                    </Tab>
                    <Tab eventKey="salary" title="Salary" >

                    </Tab>
                    <Tab eventKey="performance" title="Performance" >

                    </Tab>
                    <Tab eventKey="email" title="Email" >

                    </Tab>
                    <Tab eventKey="password" title="Password" >
                       <Password/>
                    </Tab>
                </Tabs>
            </div>
        </Fragment>
    )
}