import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import {useSelector} from "react-redux";

export default function Revenue(){
    let state=useSelector((state)=> state);
    const ref=useRef();
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);

const [CompValue1,setCompValue1]=useState(false)
const [CompValue2,setCompValue2]=useState(false)


    const getData=()=>{

        const Month=document.getElementById("cmbMonth");
        const MonthValue=Month.options[Month.selectedIndex].text

        const RevenueTable=document.getElementById("cmbRevenueTable");
        const RevenueTableValue=RevenueTable.options[RevenueTable.selectedIndex].text




        const Month2021New=document.getElementById("cmb2021New");
        const Month2021ValueNew=Month2021New.options[Month2021New.selectedIndex].text

        const Month2122=document.getElementById("cmb2122");
        const Month2122Value=Month2122.options[Month2122.selectedIndex].text

        const Month2223=document.getElementById("cmb2223");
        const Month2223Value=Month2223.options[Month2223.selectedIndex].text

        const CircleIndex=document.getElementById("cmbNoCircle");
        const CircleIndexValue=CircleIndex.options[CircleIndex.selectedIndex].text;


        const CompareIndex=document.getElementById("cmbNoCompare").selectedIndex;

        const CompareValue1=document.getElementById("txtNoCompareValue1").value;
        const CompareValue2=document.getElementById("txtNoCompareValue2").value;

        const itemsIndexRevenue=document.getElementById("cmbItemsRevenue");
        const itemsIndexRevenueValue=itemsIndexRevenue.options[itemsIndexRevenue.selectedIndex].text;

        let ItemsRevenue="";
        if(itemsIndexRevenue.selectedIndex!=0){ItemsRevenue=" And BIN.items='"+itemsIndexRevenueValue+"' "}

        let Circle="";
        let Compare="";
        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){Circle="and Revenue20_21.circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndex.selectedIndex !=0){Circle="and Revenue20_21.circle='"+CircleIndexValue+"'";}

            }
        }


        if(CompareIndex==1){Compare="and "+RevenueTableValue+"."+MonthValue+" <'"+CompareValue1+"' ";}
        if(CompareIndex==2){Compare="and "+RevenueTableValue+"."+MonthValue+" > '"+CompareValue1+"' ";}
        if(CompareIndex==3){Compare="and "+RevenueTableValue+"."+MonthValue+" Between '"+CompareValue1+"' and '"+CompareValue2+"' ";}

            let firstColumn="Revenue20_21."+Month2021ValueNew+" As "+Month2021ValueNew+"_21"
            let secondColumn="Revenue21_22."+Month2122Value+" As "+Month2122Value+"_22"
            let thirdColumn="Revenue22_23."+Month2223Value+" As "+Month2223Value+"_23"



        let jsonObject={"sql":"Select BIN.circle As Circle_Name,BIN.name As Name_Of_Industries,"+firstColumn+","+secondColumn+","+thirdColumn+"  from BIN inner join Revenue20_21 on BIN.bin=Revenue20_21.bin   inner join Revenue21_22 on Revenue20_21.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin where 1=1 "+Circle+" "+Compare+" "+ItemsRevenue+" "}

            setData("")

        if(CompareIndex==0){
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                    setColumn(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    setData(result)
                }
            }).catch(error=>{

            });
        }else{

            if(CompareIndex==3){

                if(CompareValue1==""){cogoToast.error("Please insert Value1")}else{
                if(CompareValue2==""){cogoToast.error("Please insert Value2")}else{
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                            setColumn(
                                Object.keys(result[0]).map((k) => {
                                    return {dataField: k, text: k, sort: true}
                                })
                            )
                            setData(result)
                        }
                    }).catch(error=>{

                    });
                }}
            }else{


                if(CompareValue1==""){cogoToast.error("Please insert Value")}else{
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        if(result==""){cogoToast.error("Data not found, Please check Parameter")}else {
                            setColumn(
                                Object.keys(result[0]).map((k) => {
                                    return {dataField: k, text: k, sort: true}
                                })
                            )
                            setData(result)
                        }
                    }).catch(error=>{

                    });
                }
            }



        }





    }

    return(
        <Fragment>
            <Container className="purchaseDiv">
            <Row>
                <Col lg={3}>
                    <Row className="mt-2">
                        <Col lg={3}><label className="text-danger fw-bold">Circle</label></Col>
                        <Col lg={9}>
                            <select className="float-start w-100 customfontsize" id="cmbNoCircle"  >
                                <option value="0">All Circle</option>
                                <option value="1">Circle-1</option>
                                <option value="2">Circle-2</option>
                                <option value="3">Circle-3</option>
                                <option value="4">Circle-4</option>
                                <option value="5">Circle-5</option>
                                <option value="6">Circle-6</option>

                            </select>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={3}><label className="text-danger fw-bold">2020-21</label></Col>
                        <Col lg={9}>
                            <select className="float-start w-100 customfontsize" id="cmb2021New"  >
                                <option value="0">July</option>
                                <option value="0">August</option>
                                <option value="0">September</option>
                                <option value="0">October</option>
                                <option value="0">November</option>
                                <option value="0">December</option>
                                <option value="0">January</option>
                                <option value="0">February</option>
                                <option value="0">March</option>
                                <option value="0">April</option>
                                <option value="0">May</option>
                                <option value="0">June</option>
                                <option value="0">Total</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={3}><label className="text-danger fw-bold">2021-22</label></Col>
                        <Col lg={9}>
                            <select className="float-start w-100 customfontsize" id="cmb2122"  >
                                <option value="0">July</option>
                                <option value="0">August</option>
                                <option value="0">September</option>
                                <option value="0">October</option>
                                <option value="0">November</option>
                                <option value="0">December</option>
                                <option value="0">January</option>
                                <option value="0">February</option>
                                <option value="0">March</option>
                                <option value="0">April</option>
                                <option value="0">May</option>
                                <option value="0">June</option>
                                <option value="0">Total</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={3}><label className="text-danger fw-bold">2022-23</label></Col>
                        <Col lg={9}>
                            <select className="float-start w-100 customfontsize" id="cmb2223"  >
                                <option value="0">July</option>
                                <option value="0">August</option>
                                <option value="0">September</option>
                                <option value="0">October</option>
                                <option value="0">November</option>
                                <option value="0">December</option>
                                <option value="0">January</option>
                                <option value="0">February</option>
                                <option value="0">March</option>
                                <option value="0">April</option>
                                <option value="0">May</option>
                                <option value="0">June</option>
                                <option value="0">Total</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={3}><label className="text-danger fw-bold">Amount</label></Col>
                        <Col lg={9}>
                            <select className="float-start w-100 customfontsize" id="cmbNoCompare" onChange={(event)=>{

                                if( event.target.options.selectedIndex==0){ setCompValue1(true) ; setCompValue2(true)}else{ setCompValue1(false);  setCompValue2(true)}
                                if( event.target.options.selectedIndex==3){ setCompValue1(false) ; setCompValue2(false)}

                            }
                            }  >
                                <option value="0">Any Amount</option>
                                <option value="0">Less than</option>
                                <option value="0">Greater than</option>
                                <option value="0">Between</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={3}><label className="text-danger  fw-bold w-100 ">Value-1</label></Col>
                        <Col lg={3}>
                            <input type="text" id="txtNoCompareValue1" className="w-100" disabled={CompValue1}/>

                        </Col>
                        <Col lg={3}><label className="text-danger fw-bold w-100  ">Value-2</label></Col>
                        <Col lg={3}>
                            <input type="text" id="txtNoCompareValue2" className="w-100" disabled={CompValue2}/>
                        </Col>
                    </Row>


                    <Row className="mt-2">
                        <Col lg={3}><label className="text-danger fw-bold">Year</label></Col>
                        <Col lg={9}>
                            <select className="float-start  m-1 w-100 customfontsize" id="cmbRevenueTable"  >
                                <option>Revenue22_23</option>
                                <option>Revenue21_22</option>
                                <option>Revenue20_21</option>
                                <option>Target</option>


                            </select>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={3}><label className="text-danger fw-bold">Month</label></Col>
                        <Col lg={9}>
                            <select className="float-start w-100 customfontsize" id="cmbMonth"  >
                                <option value="0">July</option>
                                <option value="0">August</option>
                                <option value="0">September</option>
                                <option value="0">October</option>
                                <option value="0">November</option>
                                <option value="0">December</option>
                                <option value="0">January</option>
                                <option value="0">February</option>
                                <option value="0">March</option>
                                <option value="0">April</option>
                                <option value="0">May</option>
                                <option value="0">June</option>
                                <option value="0">Total</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={3}><label className="text-danger fw-bold">Items</label></Col>
                        <Col lg={9}>
                            <select className="float-start w-100 customfontsize" id="cmbItemsRevenue"  >
                                <option value="0">All Items</option>
                                <option>Advertiseing</option>
                                <option>Bank</option>
                                <option>Beverage</option>
                                <option>Biscuit</option>
                                <option>BTRC</option>
                                <option>Cement</option>
                                <option>Cigeratte</option>
                                <option>Cosmetics</option>
                                <option>Coconut Oil</option>
                                <option>Dairy</option>
                                <option>Edibal Oil</option>
                                <option>Electical Goods</option>
                                <option>Electricity Distributor</option>
                                <option>Footware</option>
                                <option>Glassware</option>
                                <option>Hotel</option>
                                <option>Insurance</option>
                                <option>Izarader</option>
                                <option>Medicine</option>
                                <option>Mobile Phone</option>
                                <option>NG</option>
                                <option>Oxygen</option>
                                <option>Other Food</option>
                                <option>Paper</option>
                                <option>Packing materials</option>
                                <option>Paints</option>
                                <option>Security</option>
                                <option>Shoes</option>D
                                <option>Shoes</option>D
                                <option>Telephone</option>
                                <option>Telecom Equipment</option>
                                <option>Ceramic Tiles</option>
                                <option>Vegetable Oil</option>
                                <option>Water Supply</option>
                                <option>Warehouse</option>
                            </select>
                        </Col>
                    </Row>

                    <Row> <Button onClick={getData} className="mt-2 w-100"> Search</Button></Row>
                    <Row className="m-2">
                        <Col lg={8}>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="tblSearch"
                                filename="Order Report"
                                sheet="tablexls"
                                buttonText="Download as XLS"/>
                        </Col>
                        <Col lg={4}>
                            <ReactToPrint   trigger={()=><Button className="w-100 " variant="danger">Print</Button>} content={()=>ref.current}  />
                        </Col>

                    </Row>
                </Col>
                <Col lg={9}>
                    <div className="tableDiv purchaseDiv">
                        <BootstrapTable id="tblSearch" ref={ref} keyField='id' data={Data} columns={Column} > </BootstrapTable>
                    </div>
                </Col>

            </Row>


            </Container>
        </Fragment>
    )
}