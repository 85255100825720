import {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {useSelector} from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";
import appUtility from "../../RestAPI/AppUtility";

export default function LPEntry(){

    let audio=new Audio("/Button.mp3");
    const  playSound= ()=>{
        audio.play()
    }
    let state=useSelector((state)=> state);
    const[Data,setData]=useState([]);
    const [Column, setColumn]=useState([{}]);
    const[Show,setShow]=useState();
    const[ShowProduct,setShowProduct]=useState();
    const[BtnLoad,setBtnLoad]=useState("primary");
    const[BtnSave,setBtnSave]=useState("primary");


    const[SellerName,setSellerName]=useState();
    const[SellerBIN,setSellerBIN]=useState();
    const [DateSubmit, setDateSubmit] = useState(new Date());


    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);





    const handleClose=()=>{

        setShow(false)
    }
    const handleOpen=()=>{
        setShow(true)
    }

    const handleCloseProduct=()=>{
        setShowProduct(false)
    }
    const handleOpenProduct=()=>{
        setShowProduct(true)
    }



    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setSellerBIN(row["BIN"])
            setSellerName(row["Name"])

            handleOpen()
        }
    }


    const savePurchase=()=>{
        setBtnSave("secondary")
        const BuyerName=document.getElementById("cmbBuyerName");
        const BuyerNameValue=BuyerName.options[BuyerName.selectedIndex].text;
        const VatAmount=document.getElementById("txtVatAmount").value;
        const InvoiceNo=document.getElementById("txtInvoiceNo").value;



        let jsonObjectPrevious={"sql":"INSERT INTO `Local_Purchase` (`Id`, `Seller_Bin`, `Seller_Name`, `Buyer_Name`, `Vat_Amount`,`Invoice_No`, `Entry_Date`)" +
                " VALUES (NULL, '"+SellerBIN+"', '"+SellerName+"', '"+BuyerNameValue+"', "+VatAmount+", "+InvoiceNo+",'"+appUtility.getDate(DateSubmit)+"')"}

        RestClient.PostRequest(AppUrl.saveData,jsonObjectPrevious).then(async result=>{
            if(result==""){cogoToast.error("Data not found");setBtnSave("primary")}else {
               playSound(); cogoToast.success("Data saved");setBtnSave("primary")
            }
        }).catch(error=>{setBtnSave("primary")});


    }
    const saveNewBIN=()=>{

        const NewBin=document.getElementById("txtNewBin").value;
        const NewBinName=document.getElementById("txtNewBinName").value;
        const TradingName=document.getElementById("txtTradingName").value;
        const Factory=document.getElementById("txtFactoryAddress").value;
        const HQ=document.getElementById("txtHqAddress").value;


        let jsonObject={"sql":"INSERT INTO `All_Bin` (`id`, `BIN`, `Name`, `Trading_Name`, `Factory_Address`, `Hq_Address`)" +
                " VALUES (NULL, '"+NewBin+"', '"+NewBinName+"', '"+TradingName+"', '"+Factory+"', '"+HQ+"')"}

        if(NewBin==""){cogoToast.error("Please select BIN")}else{
            if(NewBinName==""){cogoToast.error("Please select BIN Name")}else{
                if(TradingName==""){cogoToast.error("Please insert Trading name")}else{
                    if(Factory==""){cogoToast.error("Please insert Factory Address")}else{
                    if(HQ==""){cogoToast.error("Please insert HQ Address")}else{
                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(async result=>{
                            if(result==""){cogoToast.error("Data not Saved");}else {
                                cogoToast.success("Data saved"); playSound();handleClose()
                            }
                        }).catch(error=>{});
                    }}
                }}
        }






    }

    const getData=()=>{

        const BINSearch=document.getElementById("txtBinSearch").value;
        const BINName=document.getElementById("txtBinName").value;



        let getBin="";
        let getBinName="";
         if(BINSearch !=""){getBin=" And BIN Like'%"+BINSearch+"%' "}
         if(BINName !=""){getBinName=" And Name Like'%"+BINName+"%' "}

        let jsonObject={"sql":"SELECT Id,BIN,Name,Factory_Address FROM `All_Bin` where 1=1 "+getBin+" "+getBinName+" LIMIT 100 "}

        setColumn([{}]);setData([])
        setBtnLoad("secondary")
        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

            await setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            await setData(result);
            setBtnLoad("primary")




        }).catch(error => {   setBtnLoad("primary")
        });
    }
    const getLastEntry=()=>{


        let jsonObject={"sql":" SELECT * FROM `Local_Purchase` Where Id=(SELECT max(Id) FROM Local_Purchase) "}
        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);

            }
        }).catch(error=>{});

    }
    return (
        <Fragment>
            <Container className="animated zoomIn  ">


                <Row className="mt-2">
                    <Col sm={12} xs={12} md={12} lg={12}>
                        <Card>
                            <Card.Body>
                                <Container fluid={true}>
                                    <Row>
                                        <Col><button onClick={handleOpenProduct} className="float-start circular-btn">New BIN Entry <i className="fa fa-plus"/></button></Col>
                                        <Col><label>BIN</label><input id="txtBinSearch" className="w-50" type="text"/></Col>
                                        <Col><label>Name</label><input id="txtBinName" className="w-50" type="text"/></Col>
                                        <Col><Button variant={BtnLoad} onClick={getData}>Load BIN</Button></Col>
                                    </Row>
                                </Container>
                                <hr className="bg-secondary"/>
                                <Container fluid={true}>
                                    <Row>
                                        <Col sm={12} xs={12} md={12} lg={12}>
                                            <div className="purchaseSmallDiv">
                                                <BootstrapTable  keyField='Id' data={Data} columns={Column}  selectRow={selectRow} > </BootstrapTable>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <Button onClick={getLastEntry}>Last Entry Search</Button>
                                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv "><BootstrapTable   keyField='ID' data={DataCoEffeciant} columns={ColumnCoEfficient}   > </BootstrapTable></div>

                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>

            <Modal animation={false} className="animated zoomIn" show={Show} onHide={handleClose}>
                <Modal.Header>
                    <select  className="form-control form-control-sm form-select" id="cmbBuyerName">
                        <option >All BIN</option>
                        {

                            state.bin.map((ctr,index)=>{
                                return (
                                    <option  >{ctr.Name}</option>
                                )
                            })
                        }
                    </select>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label">Seller Name</label>
                    <input className="form-control form-control-sm" disabled={true} Value={SellerName} type="text" id="txtDescription"/>
                    <label className="form-label">Seller BIN</label>
                    <input className="form-control form-control-sm" disabled={true} Value={SellerBIN} type="text" id="txtDescription"/>
                    <label className="form-label">Invoice No</label>
                    <input className="form-control form-control-sm" type="text" id="txtInvoiceNo"/>

                    <label className="form-label">Submit Date</label>
                    <DatePicker selected={DateSubmit} onChange={(date) => setDateSubmit(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />
                    <label className="form-label">VAT Amount</label>
                    <input className="form-control form-control-sm" type="text" id="txtVatAmount"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-sm btn-site" variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <button className="" variant={BtnSave} onClick={savePurchase}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>


            <Modal animation={false} className="animated zoomIn" show={ShowProduct} onHide={handleCloseProduct}>
                <Modal.Header>


                </Modal.Header>
                <Modal.Body>

                    <label className="form-label ">BIN</label>
                    <input className="form-control form-control-sm" type="text" id="txtNewBin"/>
                    <label className="form-label ">Name</label>
                    <input className="form-control form-control-sm" type="text" id="txtNewBinName"/>
                    <label className="form-label ">Trading Name</label>
                    <input className="form-control form-control-sm" type="text" id="txtTradingName"/>
                    <label className="form-label ">Factory Address</label>
                    <input className="form-control form-control-sm" type="text" id="txtFactoryAddress"/>
                    <label className="form-label ">HQ Address</label>
                    <input className="form-control form-control-sm" type="text" id="txtHqAddress"/>


                </Modal.Body>
                <Modal.Footer>
                    <Button className="" variant="danger" onClick={handleCloseProduct}>
                        Close
                    </Button>
                    <Button  className="" variant="primary" onClick={saveNewBIN}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}
