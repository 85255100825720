import axios from 'axios';
import React from "react";
class RestClient {

   static  GetRequest= async (getUrl)=>{
      return await axios.get(getUrl).then(response=>{
           return response.data;

        }).catch(error=>{
           return null;
       });
    }


    static  PostRequest= async (postURl,postJson)=>{

       let config={
           headers: {'Content-Type': 'application/x-www-form-urlencoded',}
       }

        return await axios.post(postURl,postJson,config).then(response=>{
            return response.data;
        }).catch(error=>{
            return null;
        });
    }

}

export default RestClient;
