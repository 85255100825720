import {Fragment, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, NavLink, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import { Offline, Online } from "react-detect-offline";
export default function TopNavigation(){

    let state=useSelector((state)=> state);


    const isAdmin = () => state.access === 'admin';
    const isDivision = () => state.access === 'Division';
    const isCircle = () => state.access === 'Circle';
    const isGL = () => state.access === 'GL';
    const isET = () => state.access === 'ET';
    const isAP = () => state.access === 'AP';
    const isLaw = () => state.access === 'Law';



    return(

        <Fragment  fluid="true">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="#/home">LTU</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link > <NavLink to="/home">Home</NavLink> </Nav.Link>

                            {isAdmin() &&(
                                <>


                                    <Nav.Link ><NavLink to="/revenue">Revenue</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/law">Law</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/audit">Audit</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/gl">GL</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/hr">HR</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/setting">Setting</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/et">ET</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/up">Unit Profile</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/cigarettee">Cigarettee</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/files/Adjudication/Advanced">New Tab</NavLink></Nav.Link>



                                </> )}
                            {isDivision() &&(
                                <>
                                    <Nav.Link ><NavLink to="/revenue">Revenue</NavLink></Nav.Link>
                                </> )}
                            {isCircle() &&(
                                <>
                                    <Nav.Link ><NavLink to="/revenue">Revenue</NavLink></Nav.Link>
                                </> )}
                            {isGL() &&(
                                <>
                                    <Nav.Link ><NavLink to="/gl">GL</NavLink></Nav.Link>
                                </> )}
                            {isET() &&(
                                <>
                                    <Nav.Link ><NavLink to="/et">ET</NavLink></Nav.Link>
                                </> )}
                            {isLaw() &&(
                                <>
                                    <Nav.Link ><NavLink to="/law">Law</NavLink></Nav.Link>
                                </> )}
                            {isAP() &&(
                                <>
                                    <Nav.Link ><NavLink to="/hr">HR</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/setting">Setting</NavLink></Nav.Link>
                                </> )}



                            <Nav.Link ><NavLink to="/profile">Profile</NavLink></Nav.Link>
                            <Nav.Link ><NavLink to="/">Logout</NavLink></Nav.Link>




                        </Nav>

                    </Navbar.Collapse>

                </Container>

                <div className="bg-white m-1 w-50 text-danger">
                    <label className="text-success"> {state.name}: {state.access} :   </label>
                    <Offline>Disconnected</Offline>


                </div>
            </Navbar>

        </Fragment>
    );
}
