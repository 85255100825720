import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";

export default function CasePendingTask(){
    let state=useSelector((state)=> state);
    const ref=useRef();


    const [ScnDate, setScnDate] = useState(new Date());

    const [Data, setData]=useState([]);
    const [PendingData, setPendingData]=useState([]);

    const [Column, setColumn]=useState([{}]);
    const [PendingColumn, setPendingColumn]=useState([{}]);
    const [Name, setName]=useState();
    const [BIN, setBIN]=useState();
    const [Circle, setCircle]=useState();
    const [Items, setItems]=useState();

    const [MamlaNo, setMamlaNo]=useState();
    const [UpdateMamlaNo, setUpdateMamlaNo]=useState();
    const [MamlaType, setMamlaType]=useState();
    const [ActionDate, setActionDate] = useState(new Date());
    const [ReceivedDate, setReceivedDate] = useState(new Date());
    const [ActionDoneDate, setActionDoneDate] = useState(new Date());


    const [showStatus, setShowStatus] = useState(false);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);

    const [showPending, setShowPending] = useState(false);

    const ClosePendingModal = () => setShowPending(false);
    const ShowPendingModal = () => setShowPending(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row['Name']);
            setMamlaNo(row['Mamla_No']);
            setMamlaType(row['Mamla_Type']);

            handleShowStatus()

        }
    }
    const PendingselectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setUpdateMamlaNo(row['Mamla_No'])
            ShowPendingModal()

        }
    }

    const saveData=()=>{

        let Nothi_No=document.getElementById("txtNothiNo").value;
        let Mamla_Year=document.getElementById("txtMamlaYear").value;
        let Mamla_No=document.getElementById("txtMamlaNo").value;
        let Mamla_Amount=document.getElementById("txtAmount").value;
        let MamlaComments=document.getElementById("txtComments").value;


        let SCNDate=AppUtility.getDate(ScnDate);
        const jsonObject={"sql":"INSERT INTO `Mamla_Internal` (`Id`, `Name`, `BIN`, `Circle`, `Items`,`Nothi_No`, `Mamla_No`, `Mamla_Year`, `Amount`, `Status`,`Reply_Date`, `Hearing_Date`, `Comments`)" +
                " VALUES (NULL, '"+Name+"', '"+BIN+"', '"+Circle+"', '"+Items+"', '"+Nothi_No+"', '"+Mamla_No+"', '"+Mamla_Year+"', '"+Mamla_Amount+"', '0', '"+SCNDate+"',NULL, '"+MamlaComments+"')"}

        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
        }).catch(error=>{ cogoToast.error(error)});


    }
    const updatePendingTask=()=>{


        let actionDobeComments=document.getElementById("txtAreaActionDoneComments").value;

        let actionDoneDate=AppUtility.getDate(ActionDoneDate);
        const jsonUpdatePendingObject={"sql":"UPDATE `Law_Pending_Task` SET `Entry_Date`='"+actionDoneDate+"',`Done_Comments`='"+actionDobeComments+"',`Status`='Completed' where Mamla_No='"+UpdateMamlaNo+"' "}

        RestClient.PostRequest(AppUrl.saveData,jsonUpdatePendingObject).then(result=>{
            if(result==1){cogoToast.success("Data Updated")}else{cogoToast.error("Data not Updated")}
        }).catch(error=>{ cogoToast.error(error)});


    }
    const entryPendingTask=()=>{
        const actionTypeIndex=document.getElementById("cmbActionType");
        const actionTypeValue=actionTypeIndex.options[actionTypeIndex.selectedIndex].text;
        let actionComments=document.getElementById("txtAreaActionComments").value;


        let actionDate=AppUtility.getDate(ActionDate);
        let receiveDate=AppUtility.getDate(ReceivedDate);
        const jsonObject={"sql":"INSERT INTO `Law_Pending_Task` (`Id`, `Name`, `Mamla_No`, `Action_Task`, `Comments`,`Received_Date`, `Last_Action_Date`, `Entry_Date`, `Done_Comments`,`Status`)" +
                " VALUES (NULL, '"+Name+"', '"+MamlaNo+"', '"+actionTypeValue+"', '"+actionComments+"', '"+actionDate+"','"+receiveDate+"', NULL, NULL,'Pending')"}

        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
        }).catch(error=>{ cogoToast.error(error)});


    }
    const getData=()=>{

        const BinNameIndex=document.getElementById("cmbPTBinName");
        const PTBinName=BinNameIndex.options[BinNameIndex.selectedIndex].text;
        const MamlaCircle=document.getElementById("cmbMamlaCircle");
        const Circle=MamlaCircle.options[MamlaCircle.selectedIndex].text;

        let getCircle=""
        let getBin=""

        if(MamlaCircle.selectedIndex !=0){getCircle=" and Circle='"+Circle+"' "}
        if(BinNameIndex.selectedIndex !=0){getBin=" and Name='"+PTBinName+"' "}

        const jsonObject={"sql":"SELECT ID, Name,Mamla_No,Mamla_Type FROM `Mamla` where 1=1 "+getCircle+" "+getBin+" Order by Name ASC"}
        setData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)

        }).catch(error=>{ });


    }
    const getPendingData=()=>{
        const pendingTypeIndex=document.getElementById("cmbPendingType");
        const pendingTypeValue=pendingTypeIndex.options[pendingTypeIndex.selectedIndex].text;


        const jsonPendingObject={"sql":"SELECT Name As Name_Of_The_Company, Mamla_No,Status,Received_Date,Action_Task,Last_Action_Date AS Last_Date FROM `Law_Pending_Task` where Status='"+pendingTypeValue+"'  Order by Name ASC"}
        setPendingData([])
        RestClient.PostRequest(AppUrl.getData,jsonPendingObject).then(result=>{

            setPendingColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setPendingData(result)

        }).catch(error=>{ });


    }

    return(

        <Fragment>
            <Container className="animated zoomIn" >
                <Row>
                    <Col lg={2}>
                        <Row className="mt-2" >
                            <Col lg={3}><label className="text-danger fw-bold">Circle</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbMamlaCircle"  >
                                    <option value="0">All Circle</option>
                                    <option value="1">Circle-1</option>
                                    <option value="2">Circle-2</option>
                                    <option value="3">Circle-3</option>
                                    <option value="4">Circle-4</option>
                                    <option value="5">Circle-5</option>
                                    <option value="6">Circle-6</option>

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbPTBinName"  >
                                    <option >All BIN</option>
                                    {


                                        state.bin.map((ctr,index)=>{
                                            return (
                                                <option  >{ctr.Name}</option>
                                            )
                                        })
                                    }


                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2"> <Button onClick={getData}>Search</Button></Row>

                    </Col>
                    <Col lg={10}>
                        <Row>
                            <Col lg={6}>
                                <label>Case entry</label>
                                <div className="tableDiv tableDivBorder">
                                    <BootstrapTable ref={ref} id="tblSearch" keyField='ID' data={Data} columns={Column} selectRow={selectRow} > </BootstrapTable>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <label>Pending Task</label>
                                <div className="tableDiv tableDivBorder">
                                    <Row>
                                        <Col lg={6}>
                                            <select className="w-75 mt-2" id="cmbPendingType"  >
                                                <option>Pending</option>
                                                <option>Completed</option>
                                            </select>
                                        </Col>
                                        <Col lg={6}>
                                            <Button className="w-25 m-1" onClick={getPendingData}>Searh</Button>
                                        </Col>

                                    </Row>

                                    <BootstrapTable  id="tblSearch" keyField='Mamla_No' data={PendingData} columns={PendingColumn} selectRow={PendingselectRow} > </BootstrapTable>

                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
            <Modal  show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <label className="w-25">Mamla No</label>
                    <input id="textMamlaNo" value={MamlaNo} type="text" disabled={true} className="w-75"/>
                    <label className="w-25 mt-2">Action</label>
                    <select className="w-75 mt-2" id="cmbActionType"  >
                        <option>Select</option>
                        <option>PWC</option>
                        <option>Stay Vacate</option>
                        <option>Appeal</option>
                    </select>
                    <label className=" w-25">Comments</label>
                    <textarea className="mt-2 w-75" id="txtAreaActionComments"></textarea>
                    <h3 className="w-50">Received Date</h3>
                    <DatePicker selected={ReceivedDate}
                                onChange={(date) => setReceivedDate(date)}
                                dateFormat='dd-MM-yyyy'
                                seletable
                                isClearable
                                showYearDropdown
                                scrollableMonthYearDropdown
                    />

                    <h3 className="w-50">Action Date</h3>
                    <DatePicker selected={ActionDate}
                                onChange={(date) => setActionDate(date)}
                                dateFormat='dd-MM-yyyy'
                                seletable
                                isClearable
                                showYearDropdown
                                scrollableMonthYearDropdown
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseStatus} >
                        Close
                    </Button>
                    <Button variant="success" onClick={entryPendingTask}  >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal  show={showPending} onHide={ClosePendingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Pending Task</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <label className="w-25">Mamla No</label>
                    <input id="textMamlaNo" value={UpdateMamlaNo} type="text" disabled={true} className="w-75"/>

                    <label className=" w-25">Comments</label>
                    <textarea className="mt-2 w-75" id="txtAreaActionDoneComments"></textarea>


                    <h3 className="w-50">Done Date</h3>
                    <DatePicker selected={ActionDoneDate}
                                onChange={(date) => setActionDoneDate(date)}
                                dateFormat='dd-MM-yyyy'
                                seletable
                                isClearable
                                showYearDropdown
                                scrollableMonthYearDropdown
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={ClosePendingModal} >
                        Close
                    </Button>
                    <Button variant="success" onClick={updatePendingTask} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
