import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import AppUtility from "../../RestAPI/AppUtility";
import cogoToast from "cogo-toast";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
export default function OfficerEntry(){
    let state=useSelector((state)=> state);
    const ref=useRef();
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);

    const [FirstJoinDate, setFirstJoinDate] = useState(new Date());
    const [HereJoinDate, setHereJoinDate] = useState(new Date());
    const [LprDate, setLprDate] = useState(new Date());
    const [DobDate, setDobDate] = useState(new Date());


const saveData=()=>{

    const OfficerNameEn=document.getElementById("txtOfficerNameEn").value;
    const OfficerNameBn=document.getElementById("txtOfficerNameBan").value;
    const Father=document.getElementById("txtOfficerfather").value;
    const Mother=document.getElementById("txtOfficerMother").value;
    const DOB=AppUtility.getDate(DobDate);
    const First_JoinDate=AppUtility.getDate(DobDate);
    const First_DesignationIndex=document.getElementById("cmbOfficerJoinDesig");
    const First_Designation=First_DesignationIndex.options[First_DesignationIndex.selectedIndex].text;
    const LPRDate=AppUtility.getDate(DobDate);
    const Mobile=document.getElementById("txtOfficerMobile").value;
    const NID=document.getElementById("txtOfficerNid").value;
    const Email=document.getElementById("txtOfficerEmail").value;
    const Blood=document.getElementById("txtOfficerBlood").value;
    const Here_JoinDate=AppUtility.getDate(DobDate);
    const Now_DesignationIndex=document.getElementById("cmbOfficerDesignation");
    const Now_Designation=Now_DesignationIndex.options[Now_DesignationIndex.selectedIndex].text;

    const Pre_Address=document.getElementById("txtOfficerPreAdd").value;
    const Per_Address=document.getElementById("txtOfficerPerAdd").value;


    const jsonObject={"sql":"INSERT INTO `Officer_List` (`Id`, `Name`, `B_Name`, `F_Name`, `M_Name`, `DOB`, `First_Joining_Date`, `LPR_Date`, `NID`, `Mobile`, `Email`, `Blood`, `This_Office_Joining`, `This_Office_Left`, `Designation`, `Joining_Designation`, `Present_Addres`, `Permanent_Address`, `Status`)" +
            " VALUES (NULL, '"+OfficerNameEn+"', '"+OfficerNameBn+"', '"+Father+"', '"+Mother+"', '"+DOB+"', '"+First_JoinDate+"', '"+LPRDate+"', '"+NID+"', '"+Mobile+"', '"+Email+"', '"+Blood+"', '"+Here_JoinDate+"', Null, '"+Now_Designation+"', '"+First_Designation+"', '"+Pre_Address+"', '"+Per_Address+"', 'Active');"}

    if(OfficerNameEn==""){cogoToast.error("Please insert Name En")}else{
        if(OfficerNameBn==""){cogoToast.error("Please insert Name BN")}else{
            if(Father==""){cogoToast.error("Please insert Father")}else{
                if(Mother==""){cogoToast.error("Please insert Mother")}else{
                    if(DobDate==""){cogoToast.error("Please insert DOB")}else{
                        if(FirstJoinDate==""){cogoToast.error("Please insert First Join")}else{
                            if(LprDate==""){cogoToast.error("Please insert LPR Date")}else{
                                if(NID==""){cogoToast.error("Please insert NID")}else{
                                    if(Mobile==""){cogoToast.error("Please insert Mobile")}else{
                                        if(Email==""){cogoToast.error("Please insert Email")}else{
                                            if(Blood==""){cogoToast.error("Please insert Blood")}else{
                                                if(HereJoinDate==""){cogoToast.error("Please insert Joing Date")}else{
                                                    if(Now_Designation.selectedIndex==0){cogoToast.error("Please insert Designation")}else{
                                                        if(Pre_Address==""){cogoToast.error("Please insert Address")}else{
                                                            if(Per_Address==""){cogoToast.error("Please insert Address")}else{
                                                                RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                                                    if(result==1){cogoToast.success("Data saved"); ref.current.value=""}else{cogoToast.error("Data not saved")}


                                                                }).catch(error=>{ });
                                                            }}}} }}}}}}}}}}}

}

const getData=()=>{

    const jsonObject={"sql":"SELECT * FROM `Officer_List`"}
    setData([])
    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
        setColumn(
            Object.keys(result[0]).map((k) => {
                return {dataField: k, text: k, sort: true}
            })
        )
        setData(result)


    }).catch(error=>{ });


}

    return(

        <Fragment>
            <Container >
                <Row>
                    <Col lg={4}>
                        <Row className="mt-2"><Col lg={4}><label>Name(English)</label></Col>
                            <Col lg={8}><input  id="txtOfficerNameEn"/></Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label>Name(Bangla)</label></Col>
                            <Col lg={8}><input  id="txtOfficerNameBan"/></Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label>Father</label></Col>
                            <Col lg={8}><input  id="txtOfficerfather"/></Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label>Mother</label></Col>
                            <Col lg={8}><input  id="txtOfficerMother"/></Col>
                        </Row>
                        <Row className="mt-2">

                            <Col lg={4}><label className="w-75">DOB</label></Col>
                            <Col lg={8}>
                                <DatePicker selected={DobDate} onChange={(date) => setDobDate(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />
                            </Col>
                        </Row>
                        <Row className="mt-2">

                            <Col lg={4}><label className="w-75">First_Join</label></Col>
                            <Col lg={8}>
                                    <DatePicker selected={FirstJoinDate} onChange={(date) => setFirstJoinDate(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />
                            </Col>

                        </Row>
                        <Row className="mt-2">

                        </Row>
                    </Col>
                    <Col lg={4}>
                        <Row className="mt-2"><Col lg={4}><label>Here Join</label></Col>
                            <Col lg={8}>
                                <DatePicker selected={HereJoinDate} onChange={(date) => setHereJoinDate(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label>LPR</label></Col>
                            <Col lg={8}>
                                <DatePicker selected={LprDate} onChange={(date) => setLprDate(date)} dateFormat='dd-MM-yyyy' seletable  isClearable showYearDropdown scrollableMonthYearDropdown  />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label>Mobile</label></Col>
                            <Col lg={8}><input id="txtOfficerMobile"/></Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label>NID</label></Col>
                            <Col lg={8}><input id="txtOfficerNid"/></Col>
                        </Row>
                        <Row className="mt-2">

                            <Col lg={4}><label className="w-75">Email</label></Col>
                            <Col lg={8}><input id="txtOfficerEmail"/></Col>
                        </Row>
                        <Row className="mt-2">

                            <Col lg={4}><label className="w-75">Blood</label></Col>
                            <Col lg={8}><input id="txtOfficerBlood"/></Col>
                        </Row>
                    </Col>
                    <Col lg={4}>

                        <Row className="mt-2"><Col lg={5}><label>JoinDesignation</label></Col>
                            <Col lg={7}>

                                <select className="float-start w-100 customfontsize" id="cmbOfficerJoinDesig"  >
                                    <option value="0">Select</option>
                                    <option value="1">AC</option>
                                    <option value="1">ARO</option>
                                    <option value="1">Programmer</option>
                                    <option value="1">AP</option>
                                    <option value="1">UD</option>
                                    <option value="1">Computer Operator</option>
                                    <option value="1">Cashier</option>
                                    <option value="1">Driver</option>
                                    <option value="1">SI</option>
                                    <option value="1">Sepai</option>
                                    <option value="1">MLSS</option>


                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={5}><label>Designation</label></Col>
                            <Col lg={7}>

                                <select className="float-start w-100 customfontsize" id="cmbOfficerDesignation"  >
                                    <option value="0">Select</option>
                                    <option value="1">Commissioner</option>
                                    <option value="1">ADC</option>
                                    <option value="1">JC</option>
                                    <option value="1">DC</option>
                                    <option value="1">AC</option>
                                    <option value="1">RO</option>
                                    <option value="1">ARO</option>
                                    <option value="1">Programmer</option>
                                    <option value="1">AP</option>
                                    <option value="1">UD</option>
                                    <option value="1">Computer Operator</option>
                                    <option value="1">Cashier</option>
                                    <option value="1">Driver</option>
                                    <option value="1">SI</option>
                                    <option value="1">Sepai</option>
                                    <option value="1">MLSS</option>


                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label>Presend Address</label></Col>
                            <Col lg={8}><textarea id="txtOfficerPreAdd"></textarea></Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={4}><label>Permanent Address</label></Col>
                            <Col lg={8}><textarea id="txtOfficerPerAdd"></textarea></Col>
                        </Row>



                    </Col>


                </Row>
                <Row className="m-2">
                    <Col lg={2}><Button onClick={getData} variant="danger" className="w-100 ">Get Officer list</Button></Col>
                    <Col lg={2}>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="tblSearch"
                            filename="Order Report"
                            sheet="tablexls"
                            buttonText="Download"/>
                    </Col>
                    <Col lg={2}>
                        <ReactToPrint   trigger={()=><Button className="w-100 " variant="danger">Print</Button>} content={()=>ref.current}  />
                    </Col>
                    <Col lg={4}></Col>
                    <Col lg={2}><Button className="w-100" onClick={saveData}>Save</Button></Col>

                </Row>
                <Row>
                    <h1>Officer List</h1>
                    <div className="tableDiv tableDivBorder">
                        <BootstrapTable ref={ref} id="tblSearch" keyField='Id' data={Data} columns={Column}  > </BootstrapTable>
                    </div>
                </Row>
            </Container>

        </Fragment>
    );
}