import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import DatePicker from "react-datepicker";

export default function CaseDetailesEntry(){
    let state=useSelector((state)=> state);
    const ref=useRef();

    const [DataMamla, setDataMamla]=useState([]);
    const [DataMamlaDetail, setDataMamlaDetail]=useState([]);
    const [Data, setData]=useState([]);
    const [ColumnMamlaDetail, setColumnMamlaDetail]=useState([{}]);
    const [ColumnMamla, setColumnMamla]=useState([{}]);

    const [Name, setName]=useState();
    const [MamlaNo, setMamlaNo]=useState();
    const [showUpdate, setShowUpdate] = useState(false);
    const handleCloseUpdate = () => setShowUpdate(false);
    const handleShowUpdate = () => setShowUpdate(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setMamlaNo(row['Mamla_No']);
                setName(row['Name']);


                handleShow()


        }
    }
    const selectRowUpdate={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setMamlaNo(row['Mamla_No']);
            setName(row['Name']);


            handleShowUpdate()


        }
    }

    const saveData=()=>{

      const  MamlaDetailesValue=document.getElementById("textAreaDetailes").value;

        if(MamlaDetailesValue==""){cogoToast.error("Please insert mamla detailes")}else {
            RestClient.PostRequest(AppUrl.saveData, {"sql": "INSERT INTO `Mamla_Detailes` (`Id`, `mamla_No`, `Detailes`) VALUES (NULL, '" + MamlaNo + "', '" + MamlaDetailesValue + "')"}).then(result => {
                if (result == 1) {
                    cogoToast.success("Data saved")
                    handleClose()
                } else {
                    cogoToast.error("Data not saved")
                }
            }).catch(error => {
                cogoToast.error(error)
            });
        }





    }

    const saveDataUpdate=()=>{

        const  MamlaDetailesValue=document.getElementById("textAreaDetailes").value;

        if(MamlaDetailesValue==""){cogoToast.error("Please insert mamla detailes")}else {
            RestClient.PostRequest(AppUrl.saveData, {"sql": "INSERT INTO `Mamla_Update` (`Id`, `mamla_No`, `Mamla_Update`) VALUES (NULL, '" + MamlaNo + "', '" + MamlaDetailesValue + "')"}).then(result => {
                if (result == 1) {
                    cogoToast.success("Data saved")
                    handleCloseUpdate()
                } else {
                    cogoToast.error("Data not saved")
                }
            }).catch(error => {
                cogoToast.error(error)
            });
        }





    }
    const getMamla=()=>{

        const mamlaTypeIndex=document.getElementById("cmbCourtTypeCase");
        const BinNameIndex=document.getElementById("cmbBinNamedetailes");
        const BinName=BinNameIndex.options[BinNameIndex.selectedIndex].text;
        console.log(mamlaTypeIndex.selectedIndex)

        let getBin=""

        if(BinNameIndex.selectedIndex !=0){getBin=" and Name='"+BinName+"' "}
        let SQl=""
        if(mamlaTypeIndex.selectedIndex==0){SQl="Select Name, Mamla_No,Mamla_Type,Amount from Mamla Where 1=1 "+getBin+" Order by Name ASC"}
        else{SQl="Select Name, Mamla_No,Amount from Mamla_Internal Where 1=1 "+getBin+" Order by Name ASC"}
        const jsonObject={"sql":SQl}
        setDataMamla([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumnMamla(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setDataMamla(result)

        }).catch(error=>{ });


    }
    const getMamlaDetail=async ()=>{

        if(MamlaNo==""){cogoToast.error("Please select Mamla No first")}else {
            const jsonObject={"sql":" Select Mamla_No, Detailes from Mamla_Detailes Where Mamla_No='"+MamlaNo+"'"}
           await setDataMamlaDetail([{dataField: 'k', text: 'No data found', sort: true}])
           await setDataMamlaDetail([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{

           await setColumnMamlaDetail(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
           await setDataMamlaDetail(result);

        }).catch(error=>{ });
        }

    }

    return(

        <Fragment>
            <Container className="animated zoomIn" >
                <Row>
                    <Col lg={2}>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Type</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbCourtTypeCase"  >
                                    <option >External</option>
                                    <option >Internal</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbBinNamedetailes"  >
                                    <option >All BIN</option>
                                    {


                                        state.bin.map((ctr,index)=>{
                                            return (
                                                <option  >{ctr.Name}</option>
                                            )
                                        })
                                    }


                                </select>
                            </Col>

                        </Row>

                        <Row className="mt-2"> <Button onClick={getMamla}>GET Mamla</Button></Row>
                        <Row className="mt-2"> <Button onClick={getMamlaDetail}>GET Mamla Detailes</Button></Row>
                    </Col>
                    <Col lg={10}>
                        <Row>
                            <Col lg={8}>
                                <label>Entry Disputed Matter</label>
                                <div className="tableDiv tableDivBorder">
                                    <BootstrapTable  keyField='Mamla_No' data={DataMamla} columns={ColumnMamla} selectRow={selectRow}  > </BootstrapTable>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <label>entry All Situation</label>
                                <div className="tableDiv tableDivBorder">
                                    <BootstrapTable  keyField='Mamla_No' data={DataMamlaDetail} columns={ColumnMamlaDetail} selectRow={selectRowUpdate}   > </BootstrapTable>
                                </div>
                            </Col>

                        </Row>

                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                   <textarea id="textAreaDetailes" className="w-100 h-100"></textarea>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={saveData}>
                        Save
                    </Button>


                </Modal.Footer>
            </Modal>
            <Modal show={showUpdate} onHide={handleCloseUpdate}>
                <Modal.Header closeButton>
                    <Modal.Title>{MamlaNo}</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <textarea id="textAreaDetailes" className="w-100 h-100"></textarea>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseUpdate}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={saveDataUpdate}>
                        Save
                    </Button>


                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
