import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import {useSelector} from "react-redux";


export default function RevenueAnalysis(){
    let state=useSelector((state)=> state);
    const ref=useRef();
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);



    const getData=()=>{


        const CircleThree=document.getElementById("cmbCircleThree");
        const CircleThreeValue=CircleThree.options[CircleThree.selectedIndex].text;



        let CirclePreviousThree="";

        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CirclePreviousThree="and Revenue20_21.circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleThree.selectedIndex !=0){CirclePreviousThree="and Revenue20_21.circle='"+CircleThreeValue+"'";}

            }
        }





        let jsonObject={"sql":"Select  BIN.circle As Circle_Name,BIN.name As Name_OF_The_Industries," +
                "Revenue20_21.july As Jul_21,Revenue21_22.july As Jul_22,Revenue22_23.july As Jul_23,Target.july As Jul_Target ,Revenue20_21.august As Aug_21,Revenue21_22.august As Aug_22,Revenue22_23.august As Aug_23,Target.august As Aug_Target,Revenue20_21.september As Sept_21,Revenue21_22.september As Sept_22,Revenue22_23.september As Sept_23,Target.september As Sept_Target," +
                "Revenue20_21.october As Oct_21,Revenue21_22.october As Oct_22,Revenue22_23.october As Oct_23,Target.october As Oct_Target,Revenue20_21.november As Nov_21,Revenue21_22.november As Nov_22,Revenue22_23.november As Nov_23,Target.november As Nov_Target," +
                "Revenue20_21.december As Dec_21,Revenue21_22.december As Dec_22,Revenue22_23.december As Dec_23,Target.december As Dec_Target,Revenue20_21.january As Jan_21,Revenue21_22.january As Jan_22,Revenue22_23.january As Jan_23,Target.january As Jan_Target," +
                "Revenue20_21.february As Feb_21,Revenue21_22.february As feb_22,Revenue22_23.february As feb_23,Target.february As Feb_Target,Revenue20_21.march As Mar_21,Revenue21_22.march As Mar_22,Revenue22_23.march As Mar_23,Target.march As Mar_Target," +
                "Revenue20_21.april As Apr_21,Revenue21_22.april As Apr_22,Revenue22_23.april As Apr_23,Target.april As Apr_Target,Revenue20_21.may As May_21,Revenue21_22.may As May_22,Revenue22_23.may As May_23,Target.may As May_Target,Revenue20_21.june As Jun_21, Revenue21_22.june As Jun_22,Revenue22_23.june As Jun_23,Target.june As Jun_Target  from BIN inner join Revenue20_21 on BIN.bin=Revenue20_21.bin   inner join Revenue21_22 on Revenue20_21.bin=Revenue21_22.bin inner join Revenue22_23 on Revenue21_22.bin=Revenue22_23.bin inner join Target on Revenue22_23.bin=Target.bin Where 1=1 "+CirclePreviousThree+"  "}


            setData("")






                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        setColumn(
                            Object.keys(result[0]).map((k)=>{
                                return {dataField: k, text: k, sort: true}
                            })
                        )
                        setData(result)
                    }).catch(error=>{

                    });










    }

    return(
        <Fragment>
            <Container className="purchaseDiv">



                    <Row className="mt-2">
                        <Col lg={1}><label className="text-danger w-100 fw-bold">Circle</label></Col>
                        <Col lg={2}>
                            <select className="float-start w-100 customfontsize" id="cmbCircleThree"  >
                                <option value="0">All Circle</option>
                                <option value="1">Circle-1</option>
                                <option value="2">Circle-2</option>
                                <option value="3">Circle-3</option>
                                <option value="4">Circle-4</option>
                                <option value="5">Circle-5</option>
                                <option value="6">Circle-6</option>

                            </select>
                        </Col>
                        <Col lg={1}><Button onClick={getData} className="mb-2 w-100"> Search</Button></Col>
                        <Col lg={4}></Col>
                        <Col lg={4}>

                            <Row className="m-2">
                                <Col lg={8}>
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="download-table-xls-button"
                                        table="tblSearch"
                                        filename="Order Report"
                                        sheet="tablexls"
                                        buttonText="Download as XLS"/>
                                </Col>
                                <Col lg={4}>
                                    <ReactToPrint   trigger={()=><Button className="w-100 " variant="danger">Print</Button>} content={()=>ref.current}  />
                                </Col>

                            </Row>

                        </Col>
                    </Row>
                <Row>
                    <div className="tableDiv tableDivBorder">
                        <BootstrapTable ref={ref} id="tblSearch" keyField='id' data={Data} columns={Column} > </BootstrapTable>
                    </div>
                </Row>







            </Container>
        </Fragment>
    )
}