import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";

export default function PreviousDataEntry(){



    const [BtnSearch,setBtnSearch]=useState("success")
    let state=useSelector((state)=> state);
    const ref=useRef();
const saveData=()=>{




    setBtnSearch("secondary");
    const InputValue=document.getElementById("txtInputValue").value;
    const ComparedYear=document.getElementById("cmbComparedYear");

    const ComparedYearValue=ComparedYear.options[ComparedYear.selectedIndex].text;

    const PreviousBIN=document.getElementById("cmbPreviousBIN");
    const PreviousBINValue=PreviousBIN.options[PreviousBIN.selectedIndex].text;

    const ComparedTypeIndex=document.getElementById("cmbComparedType").selectedIndex;
    let ComparedColumn="";
    if(ComparedTypeIndex==1){ComparedColumn="Total_"+ComparedYearValue+""}
    if(ComparedTypeIndex==2){ComparedColumn="Growth_"+ComparedYearValue+""}
    if(ComparedTypeIndex==3){ComparedColumn="Growth_"+ComparedYearValue+"_P"}

    let jsonObject={"sql":"Update Previouse_Revenue set "+ComparedColumn+"="+InputValue+" Where Name='"+PreviousBINValue+"' "}

    console.log(jsonObject)

    if(ComparedTypeIndex==0){cogoToast.error("Please select Type")}else{
    if(PreviousBIN.selectedIndex==0){cogoToast.error("Please select BIN Name")}else{
    if(InputValue==""){cogoToast.error("Please enter Data")}else{
        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result!=0){cogoToast.success("Data  saved");setBtnSearch("primary")}else{setBtnSearch("primary");cogoToast.error("Data not saved ")}

        }).catch(error=>{ setBtnSearch("primary");});
    }}}



}


    return(
        <Fragment>
            <Container className="purchaseDiv">

                <Row>
                    <Col lg={3}>
                        <Row className="mt-2">
                            <Col lg={5}><label className="text-danger fw-bold">Type</label></Col>
                            <Col lg={7}>
                                <select className="float-start w-100 customfontsize" id="cmbComparedType"  >
                                    <option value="0">No compared</option>
                                    <option value="0">Total Revenue</option>
                                    <option value="0">Growth</option>
                                    <option value="0">Growth%</option>

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={5}><label className="text-danger fw-bold">Year</label></Col>
                            <Col lg={7}>
                                <select className="float-start w-100 customfontsize" id="cmbComparedYear"  >
                                    <option>22_23</option>
                                    <option>21_22</option>
                                    <option>20_21</option>
                                    <option>19_20</option>
                                    <option>18_19</option>
                                    <option>17_18</option>
                                    <option>16_17</option>
                                    <option>15_16</option>
                                    <option>14_15</option>
                                    <option>13_14</option>
                                    <option>12_13</option>
                                    <option>11_12</option>
                                    <option>10_11</option>
                                    <option>09_10</option>
                                    <option>08_09</option>
                                    <option>07_08</option>
                                    <option>06_07</option>
                                    <option>05_06</option>
                                    <option>04_05</option>
                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={5}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={7}>
                                <select className="float-start w-100 customfontsize" id="cmbPreviousBIN"  >
                                    <option >All BIN</option>
                                    {


                                        state.bin.map((ctr,index)=>{
                                            return (
                                                <option  >{ctr.Name}</option>
                                            )
                                        })
                                    }

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={5}></Col>
                            <Col lg={7}><input type="text" className="w-100" id="txtInputValue"/></Col>

                        </Row>
                        <Row className="mt-2"><Button onClick={saveData} variant={BtnSearch}>Save</Button></Row>
                    </Col>
                    <Col lg={9}>












                    </Col>

                </Row>

            </Container>
        </Fragment>
    );
}