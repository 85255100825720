import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";

export default function NewAuditEntry(){
    let state=useSelector((state)=> state);
    const ref=useRef();
    const [LastAuditDate, setLastAuditDate] = useState(new Date());
    const [DataMamla, setDataMamla]=useState([]);
    const [Data, setData]=useState([]);
    const [ColumnMamla, setColumnMamla]=useState([{}]);
    const [Column, setColumn]=useState([{}]);
    const [Name, setName]=useState();
    const [BIN, setBIN]=useState();
    const [Circle, setCircle]=useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row['Name']);
            setBIN(row['BIN']);
            setCircle(row['Circle']);
            handleShow()


        }
    }

    const saveData=()=>{
        const AuditName=document.getElementById("txtAuditName").value;
        const AuditNumbers=document.getElementById("txtAuditNumbers").value;
        const LastAuditAmount=document.getElementById("txtAuditFindAmount").value;
        const AuditComments=document.getElementById("txtAuditComents").value;
        const AuditedBy=document.getElementById("cmbAuditedBy");
        const AuditedByValue=AuditedBy.options[AuditedBy.selectedIndex].text;
        const jsonObject={"sql":"UPDATE `Audit_Data` SET `status`='Continue' ,`Last_Audit_Date`='"+AppUtility.getDate(LastAuditDate)+"',`Audited_Numbers`='"+AuditNumbers+"',`Last_Audit_By`='"+AuditedByValue+"',`Last_Find_Amount`='"+LastAuditAmount+"',`Comments`='"+AuditComments+"' Where Name='"+Name+"' "}


        if(AuditName==""){cogoToast.error("Please Insert BIN Name")}else{
            if(LastAuditDate==null){cogoToast.error("Please select Last Audit date")}else{
                if(AuditNumbers==""){cogoToast.error("Please select Audit Numbers")}else{
                    if(AuditedBy.selectedIndex==0){cogoToast.error("Please select Audited By")}else{
                        if(LastAuditAmount==""){cogoToast.error("Please select Audit amount")}else{
                            if(AuditComments==""){cogoToast.error("Please select Comments")}else {

                                RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{
                                    if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
                                }).catch(error=>{ });

                            }
                        }
                    }
                }
            }}


    }

    const getData=()=>{

        const BinNameIndex=document.getElementById("cmbBinName");
        const BinName=BinNameIndex.options[BinNameIndex.selectedIndex].text;


        let getBin=" and Name='"+BinName+"' "




        const jsonObject={"sql":"select Name,Circle,Items from BIN where 1=1  "+getBin+" Order by Name ASC"}
        setData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)

        }).catch(error=>{ });


    }


    return(

        <Fragment>
            <Container >
                <Row>
                    <Col lg={2}>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbBinName"  >

                                    {


                                        state.bin.map((ctr,index)=>{
                                            return (
                                                <option  >{ctr.Name}</option>
                                            )
                                        })
                                    }


                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2"> <Button onClick={getData}>Search</Button></Row>

                    </Col>
                    <Col lg={10}>
                        <div className="purchaseDiv text-center">
                            <BootstrapTable keyField='Name' data={Data} columns={Column} selectRow={selectRow} > </BootstrapTable>
                            <Row className="mt-2">
                                <Col lg={2}><label className="w-100">Name</label></Col>
                                <Col lg={4}>
                                    <input className="w-100" type="text" disabled={true} id="txtAuditName" value={Name}/>
                                </Col>
                                <Col lg={2}><label className="w-100">Last Audit Date</label></Col>
                                <Col lg={4}>
                                    <DatePicker selected={LastAuditDate} onChange={(date) => setLastAuditDate(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />

                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={2}><label className="w-100">Audit Numbers</label></Col>
                                <Col lg={4}>
                                    <input className="w-100" id="txtAuditNumbers"/>
                                </Col>
                                <Col lg={2}><label className="w-100">Last Audited by</label></Col>
                                <Col lg={4}>
                                    <select className="float-start w-100 customfontsize" id="cmbAuditedBy"  >
                                        <option value="0">Select</option>
                                        <option value="1">VAT Inteligence</option>
                                        <option value="2">Commissionerate</option>
                                        <option value="3">Other</option>

                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={2}><label className="w-100">Last Find Amount</label></Col>
                                <Col lg={4}>
                                    <input className="w-100" id="txtAuditFindAmount"/>
                                </Col>
                                <Col lg={2}><label className="w-100">Comments</label></Col>
                                <Col lg={4}>
                                    <textarea className="w-100" id="txtAuditComents"/>
                                </Col>
                            </Row>
                            <Col lg={6}> <Button onClick={saveData} variant="danger" className="w-50">Save</Button></Col>

                        </div>


                    </Col>
                </Row>
            </Container>

        </Fragment>
    );
}
