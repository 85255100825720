import {Fragment, useState} from "react";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";

export default function TestPage(){
    const [inputFields, setInputFields] = useState([]);
    const [Data, setData] = useState([]);

    const addInputField = () => {
        setInputFields([...inputFields, ['', '', '']]);
    };

    const handleInputChange = (index, subIndex, value) => {
        const updatedFields = [...inputFields];
        updatedFields[index][subIndex] = value;
        setInputFields(updatedFields);
    };

    const removeInputField = (index) => {
        const updatedFields = [...inputFields];
        updatedFields.splice(index, 1);
        setInputFields(updatedFields);
    };

    const handleSubmit = () => {
    let SqlValue=""
      inputFields.map((field,index)=>{

          SqlValue += "(NULL,'"+field[0]+"','"+field[1]+"','"+field[2]+"'),"

      })

let bb="(NULL, 'a', 'v', 'c'), (NULL, 'r', 'r', 'r')"

        let newValue=SqlValue.slice(0,SqlValue.length-1)


        let jsonObject={"sql":"INSERT INTO `Test_Table` (`Id`, `Name`, `Address`, `Password`) VALUES "+newValue+"  "}
        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(async result=>{
            if(result==""){cogoToast.error("Data not found")}else {
                cogoToast.success("Data saved")

            }
        }).catch(error=>{});
        console.log(jsonObject)
    };




    return(
        <Fragment>

            <div>
                <button onClick={addInputField}>Add Input Field</button>
                {inputFields.map((fields, index) => (
                    <div key={index}>
                        {fields.map((value, subIndex) => (
                            <input
                                key={subIndex}
                                type="text"
                                value={value}
                                onChange={(e) => handleInputChange(index, subIndex, e.target.value)}
                            />
                        ))}
                        <button onClick={() => removeInputField(index)}>Remove</button>
                    </div>
                ))}
                <button onClick={handleSubmit}>Submit</button>
            </div>

        </Fragment>

    );
}