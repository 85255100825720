import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import {useSelector} from "react-redux";

export default function Hscode(){
    let state=useSelector((state)=> state);
    const [Commi, setCommi]=useState();
    const [Division, setDivision]=useState([]);
    const [Circle, setCircle]=useState([]);
    const ref=useRef();
    const reset=useRef();
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);

    const [CompValue1,setCompValue1]=useState(false)
    const [CompValue2,setCompValue2]=useState(false)
    const [ID, setID]=useState();
    const [Name, setName]=useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setID(row['ID'])
            setName(row['Name'])



        }
    }

    const entryRevenue=()=>{

        const Month=document.getElementById("cmbMonth");
        const MonthValue=Month.options[Month.selectedIndex].text
        const Year=document.getElementById("cmbYear");
        const YearValue=Year.options[Year.selectedIndex].text
        const Amount=document.getElementById("txtAmount").value


        let jsonObject={"sql":"Update "+YearValue+" set "+MonthValue+"="+Amount+" where Id='"+ID+"'"}

        if(Year.selectedIndex==0){cogoToast.error("Please select Year")}else{
            if(Month.selectedIndex==0){cogoToast.error("Please select Month")}else{
                if(Amount==""){cogoToast.error("Please insert Amount")}else{
                    RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{
                        if(result==1){cogoToast.success("Entry Successfully"); handleClose()}else{cogoToast.error("Data not saved ")}
                    }).catch(error=>{

                    });
                }
            }
        }





    }
    const getData=()=>{


        const Type=document.getElementById("cmbType");
        const TypeValue=Type.options[Type.selectedIndex].text

        const Value=document.getElementById("txtValue").value;



        let jsonObject={"sql":"Select Hscode,Description from Hscode where "+TypeValue+" Like '%"+Value+"%' "}
        setData([])
                if(Type.selectedIndex==0){cogoToast.error("Please Select Type")}else{
                    if(Value==""){cogoToast.error("Please Insert Value");reset.current.focus()}else{
                        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                            reset.current.value="";
                            setColumn(
                                Object.keys(result[0]).map((k)=>{
                                    return {dataField: k, text: k, sort: true}
                                })
                            )
                            setData(result)
                        }).catch(error=>{

                        });
                    }
                }}



    return(
        <Fragment>
            <Container className="purchaseDiv">
                <Row>
                    <Col lg={3}>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Type</label></Col>
                            <Col lg={9}>
                                <select className="float-start  m-1 w-100 customfontsize" id="cmbType"  >
                                    <option>Select</option>
                                    <option>Hscode</option>
                                    <option>Description</option>


                                </select>
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Value</label></Col>
                            <Col lg={9}>
                                <input type="text" ref={reset} id="txtValue" />
                            </Col>
                        </Row>

                        <Row> <Button onClick={getData} className="mt-2 w-100"> Search</Button></Row>
                        <Row className="m-2">
                            <Col lg={8}>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="tblSearch"
                                    filename="Order Report"
                                    sheet="tablexls"
                                    buttonText="Download as XLS"/>
                            </Col>
                            <Col lg={4}>
                                <ReactToPrint   trigger={()=><Button className="w-100 " variant="danger">Print</Button>} content={()=>ref.current}  />
                            </Col>

                        </Row>
                        <Row>
                            <select className="float-start w-100 customfontsize" id="cmbCommi" onChange={()=>{
                                const e = document.getElementById("cmbCommi");
                                const value = e.options[e.selectedIndex].value;
                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                setDivision(dt)
                                setCircle([])
                            }}
                            >
                                <option value="0">-Select Commi-</option>

                                {


                                    state.commi.map((ctr,index)=>{
                                        return (
                                            <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                        )
                                    })

                                }


                            </select>
                        </Row>
                        <Row>
                            <select className="float-start w-100 customfontsize" id="cmbDivision"
                                    onChange={()=>{
                                        const e = document.getElementById("cmbDivision");
                                        const value = e.options[e.selectedIndex].value;
                                        const dt=state.circle.filter(x => x.Division_Id==value);
                                        setCircle(dt)

                                    }}
                            >
                                <option value="0">-Select Division-</option>

                                {


                                    Division.map((ctr,index)=>{
                                        return (
                                            <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                        )
                                    })

                                }


                            </select>
                        </Row>
                        <Row>
                            <select className="float-start w-100 customfontsize" id="cmbDistrict" >
                                <option value="0">-Select Circle-</option>

                                {


                                    Circle.map((ctr,index)=>{
                                        return (
                                            <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                        )
                                    })

                                }


                            </select>
                        </Row>

                    </Col>
                    <Col lg={9}>
                        <div className="tableDiv tableDivBorder">
                            <BootstrapTable id="tblSearch" ref={ref} keyField='ID' data={Data} columns={Column} selectRow={selectRow} > </BootstrapTable>
                        </div>
                    </Col>

                </Row>


            </Container>

        </Fragment>
    )
}