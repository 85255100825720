import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Employee from "../components/HR/Employee";

export default function EmployeePage(){

    return (

        <Fragment>
            <TopNavigation />
            <Employee/>
        </Fragment>
    );
}