import {Fragment} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import AppUtility from "../../RestAPI/AppUtility";

export default function Password(){
    let state=useSelector((state)=> state);

    const  PlaySms= ()=>{let smsSound=new Audio("/sms.mp3"); smsSound.play()}

    const checkPassword=()=>{

        const Password=document.getElementById("txtPssword").value;
        const ConfirmPassword=document.getElementById("txtConfirmPssword").value;

        if(Password==""){cogoToast.error("Insert Password")}else{
            if(ConfirmPassword==""){cogoToast.error("Insert Confirm Password")}else{
               if(Password===ConfirmPassword){}else {cogoToast.error("Password does not matched")} }
        }


    }

    const savePassword=()=>{
        const Password=document.getElementById("txtPssword").value;
        const ConfirmPassword=document.getElementById("txtConfirmPssword").value;
        if(Password==""){cogoToast.error("Please insert Password")}else{

            if(ConfirmPassword==""){cogoToast.error("Please insert ConfirmPassword")}else{
                if(Password!=ConfirmPassword){cogoToast.error("Password does not matched")}else{
                RestClient.PostRequest(AppUrl.updateData, {"sql":"UPDATE Account SET Password ='"+Password+"' WHERE user_name = '"+state.name+"'"}).then(result=>{
                    cogoToast.success("Password Changed successfully")
                    PlaySms();
                    AppUtility.sendSMS(state.name+" has changed his Credintial","01789307792")
                }).catch(error=>{});
            }
        }}

    }

    return(
        <Fragment>
            <div className="purchaseDiv text-center">
              <Row className="mt-2">
                  <Col lg={2} sm={12}><label>New Password</label></Col>
                  <Col lg={3} sm={12}><input id="txtPssword" type="password" className="w-100"/></Col>
              </Row>
                <Row className="mt-2">
                    <Col lg={2} sm={12}><label>Confirm Password</label></Col>
                    <Col lg={3} sm={12}><input onBlur={checkPassword} id="txtConfirmPssword" type="password" className="w-100"/></Col>
                </Row>
                <Row className="mt-2">

                    <Col lg={2} sm={12}></Col>
                    <Col lg={3} sm={12}><Button onClick={savePassword} className="w-100">Save</Button></Col>
                </Row>

            </div>
        </Fragment>
    )
}