import {Fragment, useEffect, useState} from "react";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Modal} from "react-bootstrap";

export default function UnitProfileMamla(props){
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseUpdate = () => setShowUpdate(false);
    const handleShow = () => setShow(true);
    const handleShowUpdate = () => setShowUpdate(true);
    const [DataUpdate, setDataUpdate]=useState();
    const [DataDetailes, setDataDetailes]=useState();
    const [Mamla_NO, setMamla_No]=useState();
    const [DataMamla, setDataMamla]=useState([]);
    const [ColumnMamla, setColumnMamla]=useState([{}]);
    useEffect((props)=>{
        setDataMamla([])
    },[props.bin])

    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{

            setMamla_No(row['Mamla_No'])
            RestClient.PostRequest(AppUrl.getData,{"sql":"Select Detailes from Mamla_Detailes where Mamla_No='"+row['Mamla_No']+"' "}).then(result=>{
                if(result==""){cogoToast.error("No Data found")}else{

                    setDataDetailes(result[0]["Detailes"])
                    handleShow()}

            }).catch(error=>{ cogoToast.error(error)});


        }
    }

const getMamlaUpdate=()=>{

    RestClient.PostRequest(AppUrl.getData,{"sql":"Select Mamla_Update from Mamla_Update where Mamla_No='"+Mamla_NO+"' "}).then(result=>{
        if(result==""){cogoToast.error("No Data found")}else{

            setDataUpdate(result[0]["Mamla_Update"])
            handleShowUpdate()}

    }).catch(error=>{ cogoToast.error(error)});
}


const getMamla=()=>{
    if(props.bin=="All BIN"){cogoToast.error("Please select BIN")}else{
        let jsonObjectPrevious={"sql":"Select *  from Mamla where Name='"+props.bin+"' "}
        RestClient.PostRequest(AppUrl.getData,jsonObjectPrevious).then(async result=>{
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumnMamla(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataMamla(result);

            }
        }).catch(error=>{});
    }
}
    return(
        <Fragment>
            <Button variant="secondary" className="float-start circular-btn" onClick={getMamla}><i className="fa fa-plus"/></Button>
            <div className="purchaseMediumDiv animated zoomIn overflow-scroll">
                <BootstrapTable   keyField='Mamla_No' data={DataMamla} columns={ColumnMamla}   selectRow={selectRow}> </BootstrapTable>
            </div>
            <Modal animation={false} className="animated zoomIn" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{}</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label>{DataDetailes}</label>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={()=>{
                        handleClose();getMamlaUpdate();
                    }
                    } >
                        See last update
                    </Button>


                </Modal.Footer>

            </Modal>

            <Modal animation={false} className="animated zoomIn" show={showUpdate} onHide={handleCloseUpdate}>
                <Modal.Header closeButton>
                    <Modal.Title>{}</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label>{DataUpdate}</label>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseUpdate}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>


    );
}