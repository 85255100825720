import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CigaretteeEntry from "../components/Cigarettee/CigaretteeEntry";
import DashBoard from "../components/Law/DashBoard";

import CigaretteeReport from "../components/Cigarettee/CigaretteeReport";
import CaseDetailesEntry from "../components/Law/CaseDetailesEntry";
import CigaretteeDashboard from "../components/Cigarettee/CigaretteeDashboard";
import CigaretteeProduction from "../components/Cigarettee/CigeratteProduction";

export default function CigaretteePage(){



    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center">

                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Dashboard"><CigaretteeDashboard/></Tab>
                    <Tab eventKey="CaseEntry" title="Entry"><CigaretteeEntry/></Tab>
                    <Tab eventKey="Detail" title="Update Mamla"><CaseDetailesEntry/></Tab>
                    <Tab eventKey="Report" title="Report"><CigaretteeReport/></Tab>
                    <Tab eventKey="CP" title="Production"><CigaretteeProduction/></Tab>

                </Tabs>
            </div>
        </Fragment>
    )
}