import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Profile from "../components/UserProfile/Profile";

export default function UserProfilePage(){

    return(
        <Fragment>
            <TopNavigation />
            <Profile/>


        </Fragment>
    )
}