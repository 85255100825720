import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import {useSelector} from "react-redux";
export default function RevenueEntry(){
    const [BtnSearch,setBtnSearch]=useState("success")
    let state=useSelector((state)=> state);
    const ref=useRef();
    const value1Ref=useRef();
    const value2Ref=useRef();
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);

    const [CompValue1,setCompValue1]=useState(false)
    const [CompValue2,setCompValue2]=useState(false)
    const [ID, setID]=useState();
    const [Name, setName]=useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setID(row['ID'])
            setName(row['Name_OF_The_Industries'])



            handleShow()

        }
    }

    const entryRevenue=()=>{

        const Month=document.getElementById("cmbMonthTarget");
        const MonthValue=Month.options[Month.selectedIndex].text
        const Year=document.getElementById("cmbYear");
        const YearValue=Year.options[Year.selectedIndex].text
        const Amount=document.getElementById("txtAmount").value


        let jsonObject={"sql":"Update "+YearValue+" set "+MonthValue+"="+Amount+" where Id='"+ID+"'"}

        if(Year.selectedIndex==0){cogoToast.error("Please select Year")}else{
            if(Month.selectedIndex==0){cogoToast.error("Please select Month")}else{
                if(Amount==""){cogoToast.error("Please insert Amount")}else{
                    RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{
                        if(result==1){cogoToast.success("Entry Successfully"); }else{cogoToast.error("Data not saved ")}
                    }).catch(error=>{

                    });
                }
            }
        }





    }
    const getData=()=>{
        setBtnSearch("secondary")

        const RevenueTable=document.getElementById("cmbRevenueTable");
        const RevenueTableValue=RevenueTable.options[RevenueTable.selectedIndex].text

        const Month=document.getElementById("cmbMonth");
        const MonthValue=Month.options[Month.selectedIndex].text

        const CircleIndexRevEntry=document.getElementById("cmbCircleRevEntry");
        const CircleIndexRevEntryValue=CircleIndexRevEntry.options[CircleIndexRevEntry.selectedIndex].text;


        const CompareIndex=document.getElementById("cmbCompare").selectedIndex;

        const CompareValue1=document.getElementById("txtCompareValue1").value;
        const CompareValue2=document.getElementById("txtCompareValue2").value;


        let CircleRevEntry="";
        let Compare="";
        if(state.designation=="DC" || state.designation=="AC" || state.designation=="RO" || state.designation=="ARO"){CircleRevEntry="and BIN.circle='"+state.posting+"'";}else{
            if(state.designation=="JC" || state.designation=="ADC" || state.designation=="Commissioner" || state.designation=="Member"){
                if(CircleIndexRevEntry.selectedIndex!=0){CircleRevEntry="and BIN.circle='"+CircleIndexRevEntryValue+"'";}

            }
        }



        if(CompareIndex==1){Compare="and "+RevenueTableValue+"."+MonthValue+" <="+CompareValue1+" ";}
        if(CompareIndex==2){Compare="and "+RevenueTableValue+"."+MonthValue+" >="+CompareValue1+" ";}
        if(CompareIndex==3){Compare="and "+RevenueTableValue+"."+MonthValue+" Between "+CompareValue1+" and "+CompareValue2+" ";}


        let jsonObject={"sql":"Select  BIN.ID,BIN.Circle As Circle_Name,BIN.Name As Name_OF_The_Industries,July,August,September,October,November,December,January,February,March,April,May,June  from BIN inner join "+RevenueTableValue+" on BIN.bin="+RevenueTableValue+".bin  Where 1=1 "+CircleRevEntry+" "+Compare+" "}
                setData([])

        if(CompareIndex==0){

            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                setColumn(
                    Object.keys(result[0]).map((k)=>{
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setData(result)
            }).catch(error=>{

            });
        }else{

            if(CompareIndex==3){

                if(CompareValue1==""){value1Ref.current.focus();cogoToast.error("Please insert Value1");}else{
                    if(CompareValue2==""){cogoToast.error("Please insert Value2");value2Ref.current.focus();}else{
                        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                            setColumn(
                                Object.keys(result[0]).map((k)=>{
                                    return {dataField: k, text: k, sort: true}
                                })
                            )
                            setData(result)
                        }).catch(error=>{

                        });
                    }}
            }else{


                if(CompareValue1==""){cogoToast.error("Please insert Value-1");value1Ref.current.focus()}else{
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                        setColumn(
                            Object.keys(result[0]).map((k)=>{
                                return {dataField: k, text: k, sort: true}
                            })
                        )
                        setData(result)
                    }).catch(error=>{

                    });
                }
            }



        }





    }

    return(
        <Fragment>
            <Container className="purchaseDiv">
                <Row>
                    <Col lg={3}>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Year</label></Col>
                            <Col lg={9}>
                                <select className="float-start  m-1 w-100 customfontsize" id="cmbRevenueTable"  >
                                    <option>Revenue22_23</option>
                                    <option>Revenue21_22</option>
                                    <option>Revenue20_21</option>
                                    <option>Target</option>


                                </select>
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Amount</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbCompare" onChange={(event)=>{

                                    if( event.target.options.selectedIndex==0){ setCompValue1(true) ; setCompValue2(true);value1Ref.current.value="";value2Ref.current.value=""
                                    }else{ setCompValue1(false);  setCompValue2(true)}

                                    if( event.target.options.selectedIndex==3){ setCompValue1(false) ; setCompValue2(false);}

                                }
                                }  >
                                    <option value="0">No Amount</option>
                                    <option value="0">Less than</option>
                                    <option value="0">Greater than</option>
                                    <option value="0">Between</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Value-1</label></Col>
                            <Col lg={9}>
                                <input type="text" id="txtCompareValue1" ref={value1Ref} disabled={CompValue1}/>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Value-2</label></Col>
                            <Col lg={9}>
                                <input type="text" id="txtCompareValue2" ref={value2Ref} disabled={CompValue2}/>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Month</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbMonth"  >
                                    <option value="0">July</option>
                                    <option value="0">August</option>
                                    <option value="0">September</option>
                                    <option value="0">October</option>
                                    <option value="0">November</option>
                                    <option value="0">December</option>
                                    <option value="0">January</option>
                                    <option value="0">February</option>
                                    <option value="0">March</option>
                                    <option value="0">April</option>
                                    <option value="0">May</option>
                                    <option value="0">June</option>
                                    <option value="0">Total</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Circle</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbCircleRevEntry"  >
                                    <option value="0">All Circle</option>
                                    <option value="1">Circle-1</option>
                                    <option value="2">Circle-2</option>
                                    <option value="3">Circle-3</option>
                                    <option value="4">Circle-4</option>
                                    <option value="5">Circle-5</option>
                                    <option value="6">Circle-6</option>

                                </select>
                            </Col>
                        </Row>
                        <Row> <Button onClick={getData} className=" m-2 w-100"> Search</Button></Row>
                        <Row className="m-2">
                            <Col lg={8}>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="tblSearch"
                                    filename="Order Report"
                                    sheet="tablexls"
                                    buttonText="Download as XLS"/>
                            </Col>
                            <Col lg={4}>
                                <ReactToPrint   trigger={()=><Button className="w-100 " variant="danger">Print</Button>} content={()=>ref.current}  />
                            </Col>

                        </Row>

                    </Col>
                    <Col lg={9}>
                        <div className="tableDiv tableDivBorder">
                            <BootstrapTable ref={ref} id="tblSearch" keyField='ID' data={Data} columns={Column} selectRow={selectRow} > </BootstrapTable>
                        </div>
                    </Col>

                </Row>


            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <select className="float-start  m-1 w-50 customfontsize" id="cmbYear"  >
                        <option>Select</option>
                        <option>Revenue20_21</option>
                        <option>Revenue21_22</option>
                        <option>Revenue22_23</option>
                        <option>Target</option>
                    </select>
                    <select className="float-start w-40 customfontsize m-1" id="cmbMonthTarget"  >
                        <option>Select</option>
                        <option>July</option>
                        <option>August</option>
                        <option>September</option>
                        <option>October</option>
                        <option>November</option>
                        <option>December</option>
                        <option>January</option>
                        <option>February</option>
                        <option>March</option>
                        <option>April</option>
                        <option>May</option>
                        <option>June</option>

                    </select>

                    <h3 className="mt-3 text-danger">Amount</h3>
                    <input id="txtAmount" type="text" className=" w-50"/>
                    <label>Taka(crore)</label>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={entryRevenue} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}